import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues, change } from 'redux-form';
import { withTranslation } from 'react-i18next';
import StaffCatalogPanel from './StaffCatalogPanel';
import { handleGetOrgItems } from '../../Organization/reducer';
import {
  getTransformedEmployees,
  DEFAULT_OFFSET,
  STAFF_CATALOG_FILTERS_FORM_NAME,
} from './helpers';

import {
  getCatalogEmployees,
  getSearchedEmployees,
  toggleFilters,
} from './reducer';
import { formatOrgItems } from '../../Organization/OrganizationItem/helpers';
import StaffCatalogSearchFilters from './reduxForm';

class StaffCatalogPanelContainer extends Component {
  async componentDidMount() {
    await this.props.handleGetOrgItems();
    await this.props.getCatalogEmployees();
  }

  state = {
    offset: DEFAULT_OFFSET,
  };

  handleSetOffset = (offset = DEFAULT_OFFSET, callBack = () => {}) => {
    this.setState(
      (prevState) => ({
          ...prevState,
          offset,
        }),
      () => callBack(this.state.offset),
    );
  };

  handleToggleFilters = () => {
    this.props.toggleFilters(this.props.isFiltersBlockOpen);
  };

  handleResetFormField = ({ name }) => {
    this.props.change(STAFF_CATALOG_FILTERS_FORM_NAME, name, '');
  };

  render() {
    const {
      t,
      formValues,
      orgItems,
      employees,
      employeesAreFetched,
      employeesCount,
      isFetched,
    } = this.props;

    const divisionId = formValues.businessDivisionId || '';

    return (
      <>
        <StaffCatalogSearchFilters
          t={t}
          onToggleFilters={this.handleToggleFilters}
          isFiltersBlockOpen={this.props.isFiltersBlockOpen}
          divisionId={divisionId}
          organizationItems={formatOrgItems(orgItems)}
          getSearchedEmployees={this.props.getSearchedEmployees}
          getCatalogEmployees={this.props.getCatalogEmployees}
          setOffset={this.handleSetOffset}
          onClearFormField={this.handleResetFormField}
        />
        <StaffCatalogPanel
          t={t}
          employees={getTransformedEmployees(employees, orgItems)}
          loading={employeesAreFetched || isFetched}
          employeesCount={employeesCount}
          getCatalogEmployees={this.props.getCatalogEmployees}
          formValues={this.props.formValues}
          getSearchedEmployees={this.props.getSearchedEmployees}
          offset={this.state.offset}
          setOffset={this.handleSetOffset}
        />
      </>
    );
  }
}

StaffCatalogPanelContainer.propTypes = {
  t: PropTypes.func.isRequired,
  isFiltersBlockOpen: PropTypes.bool.isRequired,
  formValues: PropTypes.object.isRequired,
  toggleFilters: PropTypes.func.isRequired,
  orgItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  employees: PropTypes.arrayOf(PropTypes.object).isRequired,
  getCatalogEmployees: PropTypes.func.isRequired,
  employeesAreFetched: PropTypes.bool.isRequired,
  employeesCount: PropTypes.number.isRequired,
  handleGetOrgItems: PropTypes.func.isRequired,
  isFetched: PropTypes.bool.isRequired,
  getSearchedEmployees: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    employees,
    isFetched: employeesAreFetched,
    employeesCount,
    isFiltersBlockOpen,
  } = state.staffCatalog;
  const { items, isFetched } = state.organization;
  const formValues = getFormValues('staff_catalog_filters')(state) || {};

  return {
    isFiltersBlockOpen,
    formValues,
    employees,
    employeesAreFetched,
    employeesCount,
    orgItems: items || [],
    isFetched,
  };
};

export default connect(
  mapStateToProps,

  {
    getCatalogEmployees,
    handleGetOrgItems,
    getSearchedEmployees,
    toggleFilters,
    change,
  },
)(withTranslation()(StaffCatalogPanelContainer));
