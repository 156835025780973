import moment from 'moment';
import { chunk, range } from 'lodash/fp';
import {
  DATE_FORMAT,
  TOTAL_GRID_ITEMS,
  DATE_ACTUALITY,
  YEARS_GRID_SIZE,
  CELL_WIDTH,
  TODAY,
} from './constants';
import { getTextWidth } from './DOMhelpers';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/sv';
import 'moment/locale/da';

class Cell {
  constructor({
    shortDate, fullDate, isChosenDate, dateActuality,
  }) {
    this.shortDate = shortDate;
    this.fullDate = fullDate;
    this.isChosenDate = isChosenDate;
    this.dateActuality = dateActuality;
  }
}

export const getDateActuality = ({ date, calendarDay }) => {
  if (
    moment(moment().format(DATE_FORMAT)).isSame(calendarDay.format(DATE_FORMAT))
  ) {
    return DATE_ACTUALITY.CURRENT;
  }
  if (
    moment(calendarDay.format(DATE_FORMAT)).isAfter(
      moment(date).endOf('month').format(DATE_FORMAT),
    )
  ) {
    return DATE_ACTUALITY.NEXT;
  }
  return '-';
};

export const getDates = ({
  numberOfDaysInMonth,
  numberOfWeekDay,
  startOfMonth,
  totalGridItems,
  dateFormat,
  date,
}) => {
  const totalCells = totalGridItems - (numberOfDaysInMonth + numberOfWeekDay) > 7
    ? totalGridItems - 7
    : totalGridItems;

  const calendar = [];
  for (let i = 0; i < totalCells; i += 1) {
    if (i < numberOfWeekDay) {
      const previousDay = moment(startOfMonth).subtract(
        numberOfWeekDay - i,
        'day',
      );
      calendar.push(
        new Cell({
          shortDate: previousDay.format('D'),
          fullDate: previousDay.format(dateFormat),
          isChosenDate: moment(date).isSame(previousDay.format(dateFormat)),
          dateActuality: DATE_ACTUALITY.PREVIOUS,
        }),
      );
    } else {
      const currentOrNextDay = moment(startOfMonth).add(
        calendar.length - numberOfWeekDay,
        'day',
      );

      calendar.push(
        new Cell({
          shortDate: currentOrNextDay.format('D'),
          fullDate: currentOrNextDay.format(dateFormat),
          isChosenDate: moment(date).isSame(
            currentOrNextDay.format(dateFormat),
          ),
          dateActuality: getDateActuality({
            date,
            calendarDay: currentOrNextDay,
          }),
        }),
      );
    }
  }

  return calendar;
};

export const getCalendar = (date, locale) => {
  const isDateValid = moment(date, DATE_FORMAT, true).isValid();
  const validatedDate = isDateValid ? date : TODAY;

  const localizedMoment = moment(validatedDate).clone().locale(locale);

  return {
    date,
    year: localizedMoment.format('YYYY'),
    monthName: localizedMoment.format('MMMM'),
    monthsNames: localizedMoment.localeData().months(),
    weekDayNames: localizedMoment.localeData().weekdays(true),
    dates: getDates({
      numberOfDaysInMonth: localizedMoment.daysInMonth(),
      numberOfWeekDay: localizedMoment.startOf('month').weekday(),
      startOfMonth: localizedMoment.startOf('month').format(DATE_FORMAT),
      totalGridItems: TOTAL_GRID_ITEMS,
      dateFormat: DATE_FORMAT,
      date: validatedDate,
    }),
  };
};

export const getYearsRange = ({ START_YEAR, END_YEAR }, step, year) => {
  const rangeArr = range(START_YEAR, END_YEAR, 1);
  const chunkedRange = chunk(step, rangeArr);
  return chunkedRange.find((arrWithYears) => arrWithYears.includes(year));
};

export const getPreviousRange = (arrWithYears, startYear) => {
  if (!startYear) {
    throw new Error('start year should be provided');
  }
  if (arrWithYears.includes(startYear)) {
    return arrWithYears.filter((year) => year >= startYear);
  }

  const firstYear = arrWithYears[0];

  const previousRange = range(firstYear - YEARS_GRID_SIZE, firstYear);

  return previousRange;
};

export const getNextRange = (arrWithYears, endYear) => {
  if (!endYear) {
    throw new Error('end year should be provided');
  }
  if (arrWithYears.includes(endYear)) {
    return arrWithYears.filter((year) => year <= endYear);
  }

  const lastYear = arrWithYears[arrWithYears.length - 1];

  const nextRange = range(lastYear + 1, lastYear + YEARS_GRID_SIZE + 1);

  return nextRange;
};

export const isInjectableMonthName = (cells = [], monthName = '') => {
  const numberOfEmptyCells = cells.filter(
    (cell) => cell.dateActuality === DATE_ACTUALITY.PREVIOUS,
  );
  const textWidth = getTextWidth(monthName, 'normal 16px Verdana');

  return textWidth <= numberOfEmptyCells.length * CELL_WIDTH || false;
};
