import { includes, isEqualWith } from 'lodash/fp';
import { Roles, REGION_COUNTRIES } from './constants';
import { mapCountryToRegion, checkManagerForOrgItemInTree } from './helpers';

const hasAdId = (authUser) => !!authUser.adId;

const hasId = (authUser) => !!authUser.id;

export const isUserLoggedInSystem = (authUser) => () => {
  if (hasAdId(authUser) && hasId(authUser)) {
    return true;
  }
  return false;
};

export const isManager = (authUser) => () => {
  if (!hasAdId(authUser)) {
    return false;
  }
  return authUser.isManager === true;
};

export const hasServiceDeskRole = (authUser) => () =>
  authUser.role.includes(Roles.ServiceDesk);

export const hasStaffAdminRole = (authUser) => () => {
  if (!hasAdId(authUser)) {
    return false;
  }
  return authUser.role.includes(Roles.StaffAdmin);
};

export const hasStaffAdminCountryRole = (authUser) => () => {
  if (!hasAdId(authUser)) {
    return false;
  }
  return authUser.role.includes(Roles.StaffAdminCountry);
};

export const hasStaffAdminRegionRole = (authUser) => () => {
  if (!hasAdId(authUser)) {
    return false;
  }
  return authUser.role.includes(Roles.StaffAdminRegion);
};

export const hasStaffOrganizationManagerRole = (authUser) => () => {
  if (!hasAdId(authUser)) {
    return false;
  }
  return authUser.role.includes(Roles.StaffOrganizationManager);
};

export const isDirectManagerForEmployee = (authUser) => (employee) =>
  authUser.isManager && authUser.id === employee.managerId;

export const hasEmployeeResourceOwnership = (authUser) => (employee) =>
  authUser.id === employee.id;

export const hasNotEmployeeResourceOwnership = (authUser) => (employee) =>
  authUser.id !== employee.id;

export const isManagerForOrganizationItem =
  (authUser) =>
  ({ organizationId, organizationTree }) => {
    if (!authUser.id) {
      return false;
    }

    const matcher = isEqualWith(
      (owner, organization) => owner.id === organization.managerId,
    );

    return checkManagerForOrgItemInTree(
      authUser,
      organizationId,
      organizationTree,
      matcher,
    );
  };

export const isStaffAdminCountryAndMatchesEmployeeCountry =
  (authUser) => (employee) => {
    if (
      authUser.role.includes(Roles.StaffAdminCountry) &&
      authUser.country &&
      authUser.country === employee?.location?.countryId
    ) {
      return true;
    }
    return false;
  };

export const isStaffAdminRegionAndMatchesEmployeeRegion =
  (authUser) => (employee) => {
    if (
      authUser.role.includes(Roles.StaffAdminRegion) &&
      authUser.country &&
      mapCountryToRegion(authUser.country) ===
        mapCountryToRegion(employee?.location?.countryId)
    ) {
      return true;
    }
    return false;
  };

export const isStaffAdminAbleToManageOrganizationItem =
  (authUser) =>
  ({ organizationId, organizationTree }) => {
    if (!authUser.id) {
      return false;
    }

    if (authUser.role.includes(Roles.StaffAdmin)) {
      return true;
    }

    if (authUser.role === Roles.StaffAdminRegion) {
      const [userRegion] = Object.keys(REGION_COUNTRIES).filter((region) =>
        includes(authUser.country)(REGION_COUNTRIES[region]),
      );
      const matcher = isEqualWith(
        (_, organization) => userRegion === organization.region,
      );

      return checkManagerForOrgItemInTree(
        authUser,
        organizationId,
        organizationTree,
        matcher,
      );
    }

    if (authUser.role === Roles.StaffAdminCountry) {
      const { country: userCountry } = authUser;
      const matcher = isEqualWith(
        (_, organization) => userCountry === organization.country,
      );

      return checkManagerForOrgItemInTree(
        authUser,
        organizationId,
        organizationTree,
        matcher,
      );
    }

    return false;
  };

export const hasAppAdminRole = (authUser) => () => {
  if (!hasAdId(authUser)) {
    return false;
  }
  return authUser.role.includes(Roles.AppAdmin);
};
