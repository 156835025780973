import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import './TreeToggleButtons.scss';
import Link from '@mui/material/Link';

export const TreeToggleButtons = withTranslation()(({ t, updateAllNodes }) => {
  const handleTree = (data) => () => {
    updateAllNodes(data);
  };
  return (
    <div className="tree-toggle-buttons">
      <Link
        component="button"
        onClick={handleTree({
          isOpen: false,
        })}
        classes={{
          root: 'tree-toggle-buttons__link',
        }}
      >
        {t('collapse')}
      </Link>
      <Link
        component="button"
        onClick={handleTree({
          isOpen: true,
        })}
        classes={{
          root: 'tree-toggle-buttons__link',
        }}
      >
        {t('expand')}
      </Link>
    </div>
  );
});

TreeToggleButtons.propTypes = {
  updateAllNodes: PropTypes.func.isRequired,
};
