class OrganizationItemFactory {
  constructor(
    { id, parentId, name, departmentName },
    isItemSelected,
    isItemOpened,
  ) {
    this.id = id;
    this.parentId = parentId;
    let trimmedName = name;
    if (departmentName) {
      trimmedName += ` - (${departmentName})`;
    }
    this.name = `${trimmedName}`;
    this.isSelected = isItemSelected;
    this.isOpen = isItemOpened;
    this.children = [];
  }

  static createTreeItem(item, isItemSelected, isItemOpened) {
    return new OrganizationItemFactory(item, isItemSelected, isItemOpened);
  }
}

export default OrganizationItemFactory;
