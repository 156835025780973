const {
  REACT_APP_STAFF_APP_AUTH_AUDIENCE,
  REACT_APP_STAFF_APP_AUTH_CLIENT_ID,
  REACT_APP_STAFF_APP_AUTH_CONNECTION,
  REACT_APP_STAFF_APP_AUTH_DOMAIN,
  REACT_APP_STAFF_APP_AUTH_REDIRECT_URI,
  REACT_APP_STAFF_APP_BACKEND,
  REACT_APP_STAFF_APP_INSTRUMENTATION_KEY,
  REACT_APP_NODE_CONFIG,
  NODE_ENV,
} = process.env;

const config = {
  REACT_APP_STAFF_APP_AUTH_AUDIENCE,
  REACT_APP_STAFF_APP_AUTH_CLIENT_ID,
  REACT_APP_STAFF_APP_AUTH_CONNECTION,
  REACT_APP_STAFF_APP_AUTH_DOMAIN,
  REACT_APP_STAFF_APP_AUTH_REDIRECT_URI,
  REACT_APP_STAFF_APP_BACKEND,
  REACT_APP_STAFF_APP_INSTRUMENTATION_KEY,
  REACT_APP_NODE_CONFIG,
  NODE_ENV,
};

export default config;
