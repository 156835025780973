import { UNASSIGNED } from '../../../shared/helpers/select';
import { GEO_FIELDS, REGION_COUNTRIES } from './constants';
import { getOrgItemChildren } from '../helpers';
import { t } from '../../../i18n';

export const getGeoFieldName = (parentItem, organizationItemTypeMap = {}) => {
  switch (parentItem.typeId) {
    case organizationItemTypeMap.operations:
    case organizationItemTypeMap.supportFunction:
      return GEO_FIELDS.country;
    case organizationItemTypeMap.root:
      return GEO_FIELDS.region;
    default:
      return null;
  }
};

export const getRegions = () => Object.keys(REGION_COUNTRIES);

export const getCountriesFromRegion = (regionsCountries, regionKey) =>
  regionsCountries[regionKey] || [];

export const getTransformedOptions = (options) =>
  options.map((option) => ({
    text: t(option),
    value: option,
  }));

export const getTransformedOptionsWithUnassigned = (options) => [
  { value: UNASSIGNED, text: UNASSIGNED },
  ...getTransformedOptions(options),
];

export const getLocationOptions = (
  typeId,
  locations,
  organizationItemTypeMap,
) => {
  if (typeof typeId !== 'string' || !locations.length || locations === {}) {
    return [];
  }

  return typeId === organizationItemTypeMap.supportFunction ||
    typeId === organizationItemTypeMap.team
    ? getTransformedOptionsWithUnassigned(locations)
    : getTransformedOptions(locations);
};

export const getOptionalLocationText = (typeId, organizationItemTypeMap) =>
  typeId === organizationItemTypeMap.supportFunction ||
  typeId === organizationItemTypeMap.team
    ? `(${t('optional')})`
    : '';

export const getManager = (editableItem, managers) =>
  editableItem !== null
    ? managers.find((manager) => manager.value === editableItem.managerId) ||
      null
    : null;

export const hasChildrenWithCountry = (editableItem, allItems) =>
  !!getOrgItemChildren(editableItem, allItems).filter((i) => !!i.country)
    .length;

export const isRegion = (geoFieldName) => geoFieldName === GEO_FIELDS.region;

export const isNotUniqueName = (name, itemId, organizationItems) =>
  organizationItems.filter((item) => {
    if (item.id === itemId) {
      return false;
    }
    return item.name === name;
  }).length;

export const isTypeFieldDisabled = (orgItemTypes, hasChildren) =>
  orgItemTypes.length === 1 || hasChildren;

export const isTeamIdFieldVisible = (typeId, organizationItemTypeMap) =>
  typeId === organizationItemTypeMap.developmentTeam;

export const isLocationFieldVisible = (
  typeId,
  allItems,
  parentItem,
  editableItem,
  grandParentType,
  organizationItemTypeMap = {},
) => {
  const { country: parentCountry, typeId: parentType } = parentItem;

  if (
    typeId === organizationItemTypeMap.city ||
    typeId === organizationItemTypeMap.operations
  ) {
    return true;
  }

  if (
    parentCountry ||
    grandParentType === organizationItemTypeMap.city ||
    hasChildrenWithCountry(editableItem, allItems)
  ) {
    return false;
  }

  return (
    typeId === organizationItemTypeMap.supportFunction ||
    parentType === organizationItemTypeMap.supportFunction
  );
};

export const unassignManagerIfNeeded = (values) =>
  values.managerId === UNASSIGNED
    ? { ...values, managerId: null, manager: null }
    : values;

export const unassignCountryIfNeeded = (values) =>
  values.country === UNASSIGNED ? { ...values, country: null } : values;

export const transformTeamId = (values) => {
  let teamId = null;

  if (!values.teamId) {
    teamId = [];
  }

  if (Array.isArray(values.teamId)) {
    teamId = values.teamId;
  }

  if (typeof values.teamId === 'string') {
    teamId = values.teamId.split(/,| /).filter(Boolean);
  }

  return { ...values, teamId };
};
