import { createAction, handleActions } from 'redux-actions';

const SET_NEW_LANGUAGE = 'SET_NEW_LANGUAGE';
const setNewLanguageAction = createAction(SET_NEW_LANGUAGE);

export function handleSetNewLanguage(language) {
  return (dispatch) => {
    dispatch(setNewLanguageAction(language));
  };
}

export default handleActions(
  {
    [SET_NEW_LANGUAGE]: (_, action) => action.payload,
  },
  null,
);
