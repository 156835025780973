import { Region, Country } from '../../types';

export const Permissions = {
  CreateEmployee: 'createEmployee',
  EditOrganizationItem: 'editOrganizationItem',
  DeleteOrganizationItem: 'deleteOrganizationItem',
  CreateOrganizationItem: 'createOrganizationItem',
  EditDepartmentItem: 'editDepartmentItem',
  DeleteDepartmentItem: 'deleteDepartmentItem',
  CreateDepartmentItem: 'createDepartmentItem',
  ReadEmployeeEmergencyContacts: 'readEmployeeEmergencyContacts',
  ReadEmployeePersonalInformation: 'readEmployeePersonalInformation',
  ReadEmployeeContractInformation: 'readEmployeeContractInformation',
  ReadEmployeePerformanceInformation: 'readEmployeePerformanceInformation',
  ReadEmployeeItHardwareInformation: 'readEmployeeItHardwareInformation',
  UpdateEmployeeOrganizationInformation:
    'updateEmployeeOrganizationInformation',
  UpdateEmployeeContractInformation: 'updateEmployeeContractInformation',
  UpdateEmployeePersonalInformation: 'updateEmployeePersonalInformation',
  OrganizationalStructure: 'organizationalStructure',
  DepartmentalStructure: 'departmentalStructure',
  CreateLocation: 'createLocation',
  UpdateLocation: 'UpdateLocation',
  DeleteLocaton: 'DeleteLocaton',
  MyTeam: 'myTeam',
  UpdateEmployeeSpecialMembership: 'updateEmployeeSpecialMembership',
  UpdateEmployeeEmergencyContacts: 'updateEmployeeEmergencyContacts',
  UpdateEmployeePrivatePhone: 'updateEmployeePrivatePhone',
  UpdateEmployeeCostCenter: 'updateEmployeeCostCenter',
  UpdateEmployeeManagerStatus: 'updateEmployeeManagerStatus',
  UpdateEmployeeRole: 'updateEmployeeRole',
  UpdateEmployeeCompanyName: 'updateEmployeeCompanyName',
  UpdateEmployeeAccountExpiryDate: 'updateEmployeeAccountExpiryDate',
  UpdateEmployeeJobTitle: 'updateEmployeeJobTitle',
  ReadOwnProfile: 'readOwnProfile',
  UploadEmployeeImage: 'uploadEmployeeImage',
  UpdateEmployeePreferredLanguage: 'updateEmployeePreferredLanguage',
  UpdateEmployeeOnLeaveStatus: 'updateEmployeeOnLeaveStatus',
  UpdateUserCategory: 'updateUserCategory',
  UpdateEmployeeApprovalRights: 'updateEmployeeApprovalRights',
  UpdateEmployeeApprovalLimit: 'updateEmployeeApprovalLimit',

  CreateCompany: 'createCompany',
  UpdateCompany: 'updateCompany',
  DeleteCompany: 'deleteCompany',

  CreateRole: 'createRole',
  UpdateRole: 'updateRole',
  DeleteRole: 'deleteRole',
};

export const Roles = {
  ItOps: 'itOps',
  StaffAdmin: 'staffAdmin',
  StaffAdminRegion: 'staffAdminRegion',
  StaffAdminCountry: 'staffAdminCountry',
  AppAdmin: 'app-admin',
  ServiceDesk: 'serviceDesk',
  StaffOrganizationManager: 'staffOrganizationManager',
};

export const REGION_COUNTRIES = {
  [Region.Europe]: [Country.France, Country.UnitedKingdom, Country.Germany],
  [Region.Scandinavia]: [Country.Sweden, Country.Denmark],
  [Region.NorthAmerica]: [Country.UnitedStates, Country.Canada],
};
