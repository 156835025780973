import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import { DATE_ACTUALITY } from '../constants';
import { isInjectableMonthName } from '../helpers';
import Button from '../../Button';
import './DatePickerDates.scss';

export const DatePickerDates = ({
  isActive,
  calendarData,
  onCalendarDatesButtonClick,
}) => (
    isActive && (
      <div className="datepicker-dates">
        <ul className="datepicker-dates__week-days">
          {calendarData.weekDayNames.map((day) => (
            <li key={day} className="datepicker-dates__week-day">
              {day.substring(0, 2)}
            </li>
          ))}
        </ul>

        <div
          className={classnames('datepicker-dates__dates-month', {
            'datepicker-dates__dates-month--injected': isInjectableMonthName(
              calendarData.dates,
              calendarData.monthName,
            ),
          })}
        >
          {calendarData.monthName}
        </div>

        <ul className="datepicker-dates__dates">
          {calendarData.dates.map(
            ({ shortDate, fullDate, isChosenDate, dateActuality }) => (
                <li
                  key={fullDate}
                  title={fullDate}
                  className="datepicker-dates__date"
                >
                  <Button
                    onClick={onCalendarDatesButtonClick(fullDate)}
                    disabled={
                      dateActuality === DATE_ACTUALITY.PREVIOUS ||
                      dateActuality === DATE_ACTUALITY.NEXT
                    }
                    className={classnames('datepicker-dates__date-btn', {
                      'datepicker-dates__date-btn--chosen-date': isChosenDate,
                      'datepicker-dates__date-btn--prev-date':
                        dateActuality === DATE_ACTUALITY.PREVIOUS,
                      'datepicker-dates__date-btn--curr-date':
                        dateActuality === DATE_ACTUALITY.CURRENT,
                      'datepicker-dates__date-btn--next-date':
                        dateActuality === DATE_ACTUALITY.NEXT,
                    })}
                  >
                    {shortDate}
                  </Button>
                </li>
              ),
          )}
        </ul>
      </div>
    )
  );

DatePickerDates.propTypes = {
  isActive: propTypes.bool.isRequired,
  calendarData: propTypes.exact({
    date: propTypes.string.isRequired,
    year: propTypes.string.isRequired,
    monthName: propTypes.string.isRequired,
    monthsNames: propTypes.arrayOf(propTypes.string).isRequired,
    weekDayNames: propTypes.arrayOf(propTypes.string).isRequired,
    dates: propTypes.arrayOf(
      propTypes.exact({
        shortDate: propTypes.string.isRequired,
        fullDate: propTypes.string.isRequired,
        isChosenDate: propTypes.bool.isRequired,
        dateActuality: propTypes.string.isRequired,
      }),
    ).isRequired,
  }),
  onCalendarDatesButtonClick: propTypes.func.isRequired,
};
