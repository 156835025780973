import isRootItem from './isRootItem';

function getOpenedBusinessDivisions(organizationItems = [], activeItemId = '') {
  let result = [];
  const item = organizationItems.find((i) => i.id === activeItemId);
  if (!activeItemId || !item) {
    return result;
  }

  result.push(item.id);
  if (isRootItem(item)) {
    return result;
  }
  result = [
    ...result,
    ...getOpenedBusinessDivisions(organizationItems, item.parentId),
  ];

  return result;
}

export default getOpenedBusinessDivisions;
