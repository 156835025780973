import React, { Component } from 'react';
import propTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Modal from '../../../shared/components/Modal';
import SelectField from '../../../shared/components/Select';
import TextInputField from '../../../shared/components/TextInput';
import AutocompleteField from '../../../shared/components/Autocomplete';

import './AddNewItemModal.scss';

class AddNewItemModal extends Component {
  render() {
    const {
      t,
      modalTitle,
      handleSubmit,
      orgItemTypes,
      geoFieldName,
      managerOptions,
      locationOptions,
      selectedManager,
      callToActionText,
      isTypeFieldDisabled,
      optionalLocationText,
      isOpen,
      isModalLoading,
      isTeamIdFieldVisible,
      isLocationFieldVisible,
      onClose,
      onHandleTypeChange,
      onFormatManagerValue,
    } = this.props;
    return (
      <div className="add-new-item">
        <Modal
          title={t(modalTitle)}
          open={isOpen}
          callToActionText={t(callToActionText)}
          isModalLoading={isModalLoading}
          onClose={onClose}
          onSubmit={handleSubmit}
        >
          <TextInputField
            fullWidth
            name="name"
            label={`${t('name')} *`}
            autoFocus={true}
            placeholder={`${t('name')} *`}
          />
          <AutocompleteField
            name="managerId"
            label={t('manager')}
            format={onFormatManagerValue}
            options={managerOptions}
            classNames="add-new-item__autocomplete"
            defaultValue={selectedManager}
          />
          <SelectField
            fullWidth
            name="typeId"
            label={t('type')}
            options={orgItemTypes}
            disabled={isTypeFieldDisabled}
            placeholder={t('type')}
            onChange={onHandleTypeChange}
          />
          {isTeamIdFieldVisible ? (
            <TextInputField
              fullWidth
              name="teamId"
              label={t('teamId')}
              placeholder={t('teamId')}
              className="add-new-item__team-id"
            />
          ) : null}
          {isLocationFieldVisible ? (
            <SelectField
              fullWidth
              name={geoFieldName}
              options={locationOptions}
              label={`${t(geoFieldName)} ${optionalLocationText}`}
              placeholder={t(geoFieldName)}
            />
          ) : null}

          <p className="add-new-item__required-message">
            {t('requiredFieldsMessage')}
          </p>
        </Modal>
      </div>
    );
  }
}

AddNewItemModal.propTypes = {
  t: propTypes.func.isRequired,
  modalTitle: propTypes.string.isRequired,
  handleSubmit: propTypes.func.isRequired,
  orgItemTypes: propTypes.array,
  geoFieldName: propTypes.string,
  managerOptions: propTypes.array,
  selectedManager: propTypes.object,
  locationOptions: propTypes.array,
  callToActionText: propTypes.string.isRequired,
  optionalLocationText: propTypes.string,
  isOpen: propTypes.bool.isRequired,
  isModalLoading: propTypes.bool,
  isTypeFieldDisabled: propTypes.bool,
  isTeamIdFieldVisible: propTypes.bool,
  isLocationFieldVisible: propTypes.bool,
  onClose: propTypes.func.isRequired,
  onHandleTypeChange: propTypes.func.isRequired,
  onFormatManagerValue: propTypes.func.isRequired,
};

export default withTranslation()(AddNewItemModal);
