import React from 'react';

import {
  getTransformedOrganizationItems,
  getOpenedBusinessDivisions,
  getNodeFromTreeById,
  getUpdatedTreeWithOneUpdatedNode,
  getUpdatedTreeWithAllUpdatedNodes,
} from './helpers/index';

export const useSelectTreeHook = ({
  organizationItems = [],
  businessDivisionId = '',
}) => {
  const [treeData, setTreeData] = React.useState(
    getTransformedOrganizationItems({
      organizationItems,
      selectedBusinessDivisionId: businessDivisionId,
      openedOrganizationItems: getOpenedBusinessDivisions(
        organizationItems,
        businessDivisionId,
      ),
    }),
  );

  const handleToggle = (event, nodeIds) => {
    const node = getNodeFromTreeById(treeData, nodeIds);
    const updatedTree = getUpdatedTreeWithOneUpdatedNode(treeData, nodeIds, {
      isOpen: !node.isOpen,
    });
    setTreeData(updatedTree);
  };

  const handleSelect = (event, nodeIds) => {
    const unselectedTree = getUpdatedTreeWithAllUpdatedNodes(treeData, {
      isSelected: false,
    });
    const updatedTree = getUpdatedTreeWithOneUpdatedNode(
      unselectedTree,
      nodeIds,
      {
        isSelected: true,
      },
    );
    setTreeData(updatedTree);
  };
  const updateAllNodes = (data) => {
    const updatedTree = getUpdatedTreeWithAllUpdatedNodes(treeData, {
      ...data,
    });
    setTreeData(updatedTree);
  };

  return {
    handleToggle,
    handleSelect,
    updateAllNodes,
    treeData,
  };
};
