import { reduxForm, SubmissionError } from 'redux-form';
import submitValidation from './submitValidation';
import { FORM_NAME } from './constants';
import {
  transformTeamId,
  unassignCountryIfNeeded,
  unassignManagerIfNeeded,
} from './helpers';
import AddNewItemModal from './AddNewItemModal';

const submit = async (
  values,
  _,
  {
    modalTitle,
    editableItem,
    parentItemType,
    organizationItems,
    onModalSubmit,
    onModalEditSubmit,
    organizationItemTypeMap,
  },
) => {
  const editableItemId = editableItem === null ? null : editableItem.id;
  /* eslint no-underscore-dangle: 0 */
  const _etag = editableItem === null ? null : editableItem._etag;

  const errors = submitValidation(
    values,
    editableItemId,
    parentItemType,
    organizationItems,
    organizationItemTypeMap,
  );

  if (Object.keys(errors).length) {
    throw new SubmissionError({
      ...errors,
      _error: 'Add new Item Failed',
    });
  }

  values = unassignManagerIfNeeded(values);
  values = unassignCountryIfNeeded(values);
  values = transformTeamId(values);

  if (modalTitle === 'editItem') {
    onModalEditSubmit(values, editableItem.id, _etag);
  } else {
    onModalSubmit(values);
  }
};

export default reduxForm({
  form: FORM_NAME,
  onSubmit: submit,
})(AddNewItemModal);
