import AppInsightsService from './logErrorService';

export default class ApiError extends Error {
  constructor(params) {
    super();

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.status = params.status;
    this.message = params.message;
    AppInsightsService.instance.trackException(this);
  }
}
