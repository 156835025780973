import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  getAllRoles as getAllRoles_,
  createRole as createRole_,
  updateRole as updateRole_,
  deleteRole as deleteRole_,
} from './reducer';
import Role from './reduxForm';

const RoleContainer = ({
  t,
  allRoles,
  getAllRoles,
  createRole,
  updateRole,
  deleteRole,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeletionModalOpen, setDeletionModalOpen] = useState(false);
  const [deletionRowId, setDeletionRowId] = useState(false);

  const handleSubmit = async (values) => {
    setIsModalOpen(false);
    const { id, ...rest } = values;
    if (id) {
      await updateRole(values.id, rest);
    } else {
      await createRole(values);
    }
    await getAllRoles();
  };

  const handleDeletion = async () => {
    setDeletionModalOpen(false);
    if (deletionRowId) {
      await deleteRole(deletionRowId);
    }
    await getAllRoles();
  };

  useEffect(() => {
    getAllRoles();
  }, [getAllRoles]);

  return (
    <>
      <Role
        t={t}
        allRoles={allRoles}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        onFormSubmit={handleSubmit}
        onsubmitRemove={handleDeletion}
        setDeletionRowId={setDeletionRowId}
        setDeletionModalOpen={setDeletionModalOpen}
        isDeletionModalOpen={isDeletionModalOpen}
      />
    </>
  );
};

RoleContainer.propTypes = {
  t: PropTypes.func.isRequired,
  allRoles: PropTypes.array.isRequired,
  getAllRoles: PropTypes.func.isRequired,
  createRole: PropTypes.func.isRequired,
  updateRole: PropTypes.func.isRequired,
  deleteRole: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { role } = state;
  const { items } = role;
  return {
    allRoles: items,
  };
}

export default connect(mapStateToProps, {
  getAllRoles: getAllRoles_,
  createRole: createRole_,
  updateRole: updateRole_,
  deleteRole: deleteRole_,
})(withTranslation()(RoleContainer));
