import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../IconButton';
import Icon from '../Icon';

const DialogTitle = (props) => {
  const { children, onClose } = props;
  return (
    <div className="modal-header" >
      <h2 className="modal-header__title">{children}</h2>
      {onClose ? (
        <IconButton
          className="modal-header__close"
          aria-label="Close"
          onClick={onClose}
        >
          <Icon icon="ak-close" />
        </IconButton>
      ) : null}
    </div>
  );
};

DialogTitle.propTypes = {
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DialogTitle;
