function getNodeFromTreeById(tree = [], id) {
  let result;
  for (let i = 0; i < tree.length; i += 1) {
    if (tree[i].id === id) {
      result = tree[i];
      break;
    }
    if (tree[i].children.length > 0) {
      result = getNodeFromTreeById(tree[i].children, id);
      if (result) {
        break;
      }
    }
    result = null;
  }
  return result;
}

export default getNodeFromTreeById;
