import OrganizationItemFactory from './organizationItemFactory';

const getItemBranch = (
  itemsList,
  parentItem,
  selectedBusinessDivisionId,
  openedOrganizationItems,
) => {
  const transformedParent = OrganizationItemFactory.createTreeItem(
    parentItem,
    parentItem.id === selectedBusinessDivisionId,
    parentItem.id
      === openedOrganizationItems.find((id) => parentItem.id === id),
  );
  const children = itemsList.filter(
    (child) => child.parentId === parentItem.id,
  );
  if (!children.length) {
    return transformedParent;
  }

  transformedParent.children = children.map((child) => getItemBranch(
    itemsList,
    OrganizationItemFactory.createTreeItem(
      child,
      child.id === selectedBusinessDivisionId,
      child.id === openedOrganizationItems.find((id) => child.id === id),
    ),
    selectedBusinessDivisionId,
    openedOrganizationItems,
  ));

  return transformedParent;
};

export default getItemBranch;
