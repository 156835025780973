const MAX_LENGTH_100 = 100;
const MAX_LENGTH_200 = 200;
const MAX_LENGTH_1000 = 1000;

const maxLength = (max) => (value) => {
  const messageObject = {
    string: 'maxCharacters',
    interpolate: { number: max },
  };
  return value && value.length > max
    ? JSON.stringify(messageObject)
    : undefined;
};

export const maxLength100 = maxLength(MAX_LENGTH_100);
export const maxLength200 = maxLength(MAX_LENGTH_200);
export const maxLength1000 = maxLength(MAX_LENGTH_1000);

export const required = (value) => (value ? undefined : 'required');
