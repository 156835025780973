import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';

const Option = ({ children, isSelected, innerProps }) => (
  <MenuItem
    classes={{
      root: 'autocomplete-field__option',
      selected: 'autocomplete-field__option--selected',
    }}
    selected={isSelected}
    {...innerProps}
  >
    {children}
  </MenuItem>
);

Option.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isSelected: PropTypes.bool.isRequired,
  innerProps: PropTypes.object.isRequired,
};

export default Option;
