import { createActions, handleActions } from 'redux-actions';
import ClientService from '../../services/client';
import { handleNotification } from '../../shared/components/Notification/reducer';


const GET_LOCATIONS = 'GET_LOCATIONS'
const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS'
const GET_LOCATIONS_FAILURE = 'GET_LOCATIONS_FAILURE'
const NEW_LOCATION = 'NEW_LOCATION'
const NEW_LOCATION_SUCCESS = 'NEW_LOCATION_SUCCESS'
const NEW_LOCATION_FAILURE = 'NEW_LOCATION_FAILURE'
const EDIT_LOCATION = 'EDIT_LOCATION'
const EDIT_LOCATION_SUCCESS = 'EDIT_LOCATION_SUCCESS'
const EDIT_LOCATION_FAILURE = 'EDIT_LOCATION_FAILURE'
const REMOVE_LOCATION = 'REMOVE_LOCATION'
const REMOVE_LOCATION_SUCCESS = 'REMOVE_LOCATION_SUCCESS'
const REMOVE_LOCATION_FAILURE = 'REMOVE_LOCATION_FAILURE'
 
const GET_COUNTRIES = 'GET_COUNTRIES'
const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS'
const GET_COUNTRIES_FAILURE = 'GET_COUNTRIES_FAILURE'
const GET_CITIES = 'GET_CITIES'
const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS'
const GET_CITIES_FAILURE = 'GET_CITIES_FAILURE'
const GET_REGIONS = 'GET_REGIONS'
const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS'
const GET_REGIONS_FAILURE = 'GET_REGIONS_FAILURE'

 
const actionCreators = createActions(
    GET_LOCATIONS,
    GET_LOCATIONS_SUCCESS,
    GET_LOCATIONS_FAILURE,

 
    NEW_LOCATION,
    NEW_LOCATION_SUCCESS,
    NEW_LOCATION_FAILURE,

    EDIT_LOCATION,
    EDIT_LOCATION_SUCCESS,
    EDIT_LOCATION_FAILURE,

    REMOVE_LOCATION,
    REMOVE_LOCATION_SUCCESS,
    REMOVE_LOCATION_FAILURE,

    GET_COUNTRIES,
    GET_COUNTRIES_SUCCESS,
    GET_COUNTRIES_FAILURE,
    GET_CITIES,
    GET_CITIES_SUCCESS,
    GET_CITIES_FAILURE,
    GET_REGIONS,
    GET_REGIONS_SUCCESS,
    GET_REGIONS_FAILURE

);

const {
    getLocations,
    getLocationsSuccess,
    getLocationsFailure,

    
    newLocationSuccess,
    newLocationFailure,
    editLocation,
    editLocationSuccess,
    editLocationFailure,
    removeLocation,
    removeLocationSuccess,
    removeLocationFailure,


    getCountries,
    getCountriesSuccess,
    getCountriesFailure,
  

    getRegions,
    getRegionsSuccess,
    getRegionsFailure,
 
    getCities,
    getCitiesSuccess,
    getCitiesFailure,
 
 
} = actionCreators;



export function getAllLocations() {
    return (dispatch) => {
      dispatch(getLocations());
      return ClientService.instance
        .fetch('/locations')
        .then((response) => {
          dispatch(getLocationsSuccess(response));
        })
        .catch((error) => {
          dispatch(getLocationsFailure(error.message));
        });
    };
}


 


export function getAllCountries() {
  return (dispatch) => {
    dispatch(getCountries());
    return ClientService.instance
      .fetch('/locations/countries')
      .then((response) => {
        dispatch(getCountriesSuccess(response));
      })
      .catch((error) => {
        dispatch(getCountriesFailure(error.message));
      });
  };
}


 export function getAllRegions() {
    return (dispatch) => {
      dispatch(getRegions());
      return ClientService.instance
        .fetch('/locations/regions')
        .then((response) => {
          dispatch(getRegionsSuccess(response));
        })
        .catch((error) => {
          dispatch(getRegionsFailure(error.message));
        });
    };
 }

 export function getAllCities() {
    return (dispatch) => {
      dispatch(getCities());
      return ClientService.instance
        .fetch('/locations/cities')
        .then((response) => {
          dispatch(getCitiesSuccess(response));
        })
        .catch((error) => {
          dispatch(getCitiesFailure(error.message));
        });
    };
 }

   


export function createLocation(parameters) {
  return (dispatch) => {
    dispatch(getLocations());
    return  ClientService.instance.post(
      '/locations', {
        ...parameters
      })
      .then((response) => {
        dispatch(newLocationSuccess());
        dispatch(
          handleNotification({
            message: "new location added",
            type: 'success',
            isActive: true,
          }),
        );
        return response;
      })
      .catch((error) => {
        dispatch(newLocationFailure(error));
        dispatch(
          handleNotification({
            message: 'error creating location',
            type: 'error',
            isActive: true,
          }),
        );
      });
  };
}
 

export function updateLocation(locationId, params) {
    return async (dispatch) => {
      dispatch(editLocation());
      return ClientService.instance
        .patch(`/locations/${locationId}`, { ...params })
        .then(() => {
          dispatch(editLocationSuccess());
          dispatch(
            handleNotification({
              message: "location has been updated",
              type: 'success',
              isActive: true,
            }),
          );
        })
        .catch((error) => {
          dispatch(editLocationFailure(error.message));
          dispatch(
            handleNotification({
              message: 'error updating location',
              type: 'error',
              isActive: true,
            }),
          );
          throw error;
        });
    };
}

export function deleteLocation(locationId) {
    return async (dispatch) => {
      dispatch(removeLocation());
      return ClientService.instance
        .delete(`/locations/${locationId}`)
        .then(() => {
          dispatch(removeLocationSuccess());
          dispatch(
            handleNotification({
              message: "location has been removed",
              type: 'success',
              isActive: true,
            }),
          );
        })
        .catch((error) => {
          dispatch(removeLocationFailure(error.message));
          dispatch(
            handleNotification({
              message: 'error deleting location',
              type: 'error',
              isActive: true,
            }),
          );
          throw error;
        });
    };
}

 
 

const locationReducer = handleActions(
  {
     GET_LOCATIONS:(state) => ({
        ...state,
        isFetched: true,
      }),
      GET_LOCATIONS_SUCCESS: (state, action) => ({
        ...state,
        items: [...action.payload],
        isFetched: false,
      }),
      GET_LOCATIONS_FAILURE: (state, action) => ({
        ...state,
        errorMessage: action.payload,
        isError: true,
        isFetched: false,
      }),
      NEW_LOCATION: (state, action) => ({
        ...state,
        item: action.payload,
        isFetched: false,
      }),
      NEW_LOCATION_SUCCESS: (state, action) => ({
        ...state,
        item: action.payload,
        isFetched: false,
      }),
      NEW_LOCATION_FAILURE: (state, action) => ({
        ...state,
        errorMessage: action.payload,
        isError: true,
        isFetched: false,
      }),
      EDIT_LOCATION:(state, action) => ({
        ...state,
        item: action.payload,
        isFetched: false,
      }),
      EDIT_LOCATION_SUCCESS: (state, action) => ({
        ...state,
        item: action.payload,
        isFetched: false,
      }),
      EDIT_LOCATION_FAILURE: (state, action) => ({
        ...state,
        errorMessage: action.payload,
        isError: true,
        isFetched: false,
      }),
      REMOVE_LOCATION: (state, action) => ({
        ...state,
        item: action.payload,
        isFetched: false,
      }),
      REMOVE_LOCATION_SUCCESS: (state, action) => ({
        ...state,
        item: action.payload,
        isFetched: false,
      }),
      REMOVE_LOCATION_FAILURE: (state, action) => ({
        ...state,
        errorMessage: action.payload,
        isError: true,
        isFetched: false,
      }),




      GET_COUNTRIES:(state) => ({
        ...state,
        isFetched: true,
      }),
      GET_COUNTRIES_SUCCESS: (state, action) => ({
        ...state,
        countries: [...action.payload],
        isFetched: false,
      }),
      GET_COUNTRIES_FAILURE: (state, action) => ({
        ...state,
        errorMessage: action.payload,
        isError: true,
        isFetched: false,
      }),

      GET_CITIES:(state) => ({
        ...state,
        isFetched: true,
      }),
      GET_CITIES_SUCCESS: (state, action) => ({
        ...state,
        cities: [...action.payload],
        isFetched: false,
      }),
      GET_CITIES_FAILURE: (state, action) => ({
        ...state,
        errorMessage: action.payload,
        isError: true,
        isFetched: false,
      }),


      GET_REGIONS:(state) => ({
        ...state,
        isFetched: true,
      }),
      GET_REGIONS_SUCCESS: (state, action) => ({
        ...state,
        regions: [...action.payload],
        isFetched: false,
      }),
      GET_REGIONS_FAILURE: (state, action) => ({
        ...state,
        errorMessage: action.payload,
        isError: true,
        isFetched: false,
      }),

 
 
  },
  {
    locations: [],
    items: [],
    countries: [],
    cities: [],
    regions: [],
    organizationItems: [],
    errorMessage: null,
    editableItem: null,
    addNewItemModalTitle: '',
    addNewItemModalActionButtonText: '',
    isError: false,
    isFetched: false,
    isAddNewItemModalOpen: false,
    isAddNewItemModalFetching: false
  },
);

export default locationReducer;
