import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {getAllCompanies as getAllCompanies_,
   createCompany as createCompany_,
   updateCompany as updateCompany_,
   deleteCompany as deleteCompany_,
  } from './reducer'
 import Company from './reduxForm';


const CompanyContainer = ({
  t,
  allCompanies,
  getAllCompanies,
  createCompany,
  updateCompany,
  deleteCompany

}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeletionModalOpen, setDeletionModalOpen] = useState(false);
  const [deletionRowId, setDeletionRowId] = useState(false);

  const handleSubmit = async (values) => {
    setIsModalOpen(false);
     const {id, ...rest} = values
      if (id) {
        await updateCompany(values.id, rest)
      } else {
        await createCompany(values)
      }
     await getAllCompanies()
  };

  const handleDeletion = async () => {
    setDeletionModalOpen(false)
    if (deletionRowId) {   
    await deleteCompany(deletionRowId)
    }
    await getAllCompanies()
  }


useEffect(() => {
  getAllCompanies();
}, [getAllCompanies]);


  return (
    <>
      <Company
          t={t}
          allCompanies={allCompanies}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          onFormSubmit={handleSubmit}
          onsubmitRemove={handleDeletion}
          setDeletionRowId={setDeletionRowId}
          setDeletionModalOpen={setDeletionModalOpen}
          isDeletionModalOpen ={isDeletionModalOpen}
        />
    </>
  );
}


CompanyContainer.propTypes = {
  t: PropTypes.func.isRequired,
  allCompanies: PropTypes.array.isRequired,
  getAllCompanies: PropTypes.func.isRequired,
  createCompany: PropTypes.func.isRequired,
  updateCompany: PropTypes.func.isRequired,
  deleteCompany: PropTypes.func.isRequired,
};


function mapStateToProps(state) {
  const {company} = state;
  const {
    items,
  } = company;
  return {
    allCompanies: items,
  };
}

export default connect(mapStateToProps, {
  getAllCompanies: getAllCompanies_,
  createCompany: createCompany_,
  updateCompany: updateCompany_,
  deleteCompany: deleteCompany_
})(withTranslation()(CompanyContainer));

