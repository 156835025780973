import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {getAllLocations as getAllLocations_, getAllCountries as getAllCountries_, getAllCities as getAllCities_,
   getAllRegions as getAllRegions_,
   createLocation as createLocation_,
   updateLocation as updateLocation_,
   deleteLocation as deleteLocation_,
  } from './reducer'
 import Location from './reduxForm';
 

const LocationContainer = ({
  t,
  allLocations,
  allCountries,
  allCities,
  allRegions,
  getAllLocations,
  getAllCountries,
  getAllCities,
  getAllRegions,
  createLocation,
  updateLocation,
  deleteLocation,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeletionModalOpen, setDeletionModalOpen] = useState(false);
  const [deletionRowId, setDeletionRowId] = useState(false);

  const handleSubmit = async (values) => {
    setIsModalOpen(false);
     const {id, ...rest} = values
      if (id) {
        await updateLocation(values.id, rest)
      } else {
        await createLocation(values)
      }
     await getAllLocations()
  };

  const handleDeletion = async () => {
    setDeletionModalOpen(false)
    if (deletionRowId) {   
    await deleteLocation(deletionRowId)
    }
    await getAllLocations()
  }

 
useEffect(() => {
  getAllLocations();
  getAllCities();
  getAllRegions();
  getAllCountries();
}, [getAllLocations, getAllCities, getAllRegions, getAllCountries]);

 
  return (
    <>
      <Location
          t={t}
          allLocations={allLocations}
          allCities={allCities}
          allCountries={allCountries}
          allRegions={allRegions}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          onFormSubmit={handleSubmit}
          onsubmitRemove={handleDeletion}
          setDeletionRowId={setDeletionRowId}
          setDeletionModalOpen={setDeletionModalOpen}
          isDeletionModalOpen ={isDeletionModalOpen}
        />
    </>
  );
}
 

LocationContainer.propTypes = {
  t: PropTypes.func.isRequired,
  allLocations: PropTypes.array.isRequired,
  allCities: PropTypes.array.isRequired,
  allCountries: PropTypes.array.isRequired,
  allRegions: PropTypes.array.isRequired,
  getAllLocations: PropTypes.func.isRequired,
  getAllCities: PropTypes.func.isRequired,
  getAllCountries: PropTypes.func.isRequired,
  getAllRegions: PropTypes.func.isRequired,
  createLocation: PropTypes.func.isRequired,
  updateLocation: PropTypes.func.isRequired,
  deleteLocation: PropTypes.func.isRequired,
};


function mapStateToProps(state) {
  const {location} = state;
  const {
    items,
    cities,
    countries,
    regions,
  } = location;
  return {
    allLocations: items,
    allCities: cities,
    allCountries: countries,
    allRegions: regions
  };
}

export default connect(mapStateToProps, {
  getAllLocations: getAllLocations_,
  getAllCountries: getAllCountries_,
  getAllCities: getAllCities_,
  getAllRegions: getAllRegions_,
  createLocation: createLocation_,
  updateLocation: updateLocation_,
  deleteLocation: deleteLocation_
})(withTranslation()(LocationContainer));


 