import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Navigate } from "react-router-dom";

class Home extends React.Component {
  render() {
    const { isManager } = this.props;
    return isManager ? <Navigate to="/team" /> : <Navigate to={`/staff/me`} />;
  }
}
Home.propTypes = {
  isManager: PropTypes.bool.isRequired,
};

export default connect(
  ({ auth: { isManager } }) => ({ isManager }),
  null,
)(Home);
