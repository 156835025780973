import { find } from 'lodash/fp';

export const getOrgItemChildren = (parentItem, organizationItems) => (parentItem
  ? organizationItems.filter((item) => item.parentId === parentItem.id)
  : []);

export const buildOrgItems = (items, parentItemId) => items
  .filter((item) => item.parentId === parentItemId)
  .map((item) => ({
    ...item,
    hasChildren: !!find({
      parentId: item.id,
    })(items),
  }));