const staffProfileFieldDependencies = {
  accountExpiryDate: {
    enabled: [
      {
        userCategory: 'external',
      },
    ],
  },
  invoiceApprovalLimit: {
    enabled: [
      {
        invoiceApprovalRights: true,
      },
    ],
  },
};

export { staffProfileFieldDependencies };
