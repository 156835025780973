import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import PageHead from '../../shared/components/PageHead';
import StaffCatalogPanelContainer from './StaffCatalogPanel';
import './Staff.scss';

class StaffComponent extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <PageHead text={t('catalog')} />
        
        <StaffCatalogPanelContainer />
      </>
    );
  }
}

StaffComponent.propTypes = {
  t: PropTypes.func.isRequired,
};

const Staff = withTranslation()(StaffComponent);

export { Staff, StaffComponent };
