import React, { Component } from 'react';
import propTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash/fp';
import Access, { Permissions } from '../../../shared/components/Access';
import Card from '../../../shared/components/Card';
import IconButtonWithToolTip from '../../../shared/components/IconButtonWithToolTip';
import './OrganizationItem.scss';

class OrganizationItem extends Component {
  render() {
    const {
      t,
      item,
      item: { name, manager, hasChildren, employeeCount },
      organizationItems,
      onItemClick,
      onEditItemClick,
      onDeleteItemClick,
      onMoveItemClick,
      onMergeItemClick
    } = this.props;

    const managerFullName = `${get('firstName')(manager) || ''} ${
      get('lastName')(manager) || ''
    }`;

    return (
      <Card className="organization-item-card">
        <div className="organization-item-card__title">
          <span
            className="organization-item-card__title-text"
            title={name}
            onClick={onItemClick}
          >
            {name}
          </span>
        </div>
        <div
          className="organization-item-card__content"
          title={managerFullName}
        >
          {managerFullName}
        </div>
        <div className="organization-item-card__actions">
        <IconButtonWithToolTip
            title={t('mergeItem')}
            disabled={false}
            icon="ak-link"
            buttonClasses={'organization-item-card__action__btn'}
            onClick={onMergeItemClick}
          />
          <IconButtonWithToolTip
            title={t('moveItem')}
            disabled={false}
            icon="ak-tree"
            buttonClasses={'organization-item-card__action__btn'}
            onClick={onMoveItemClick}
          />
          <Access
            to={{
              organizationId: item.id,
              organizationTree: organizationItems,
            }}
            rule={Permissions.EditOrganizationItem}
          >
            {(granted) => (
              <IconButtonWithToolTip
                title={t('editItem')}
                disabled={!granted}
                icon="ak-edit"
                buttonClasses={
                  !granted
                    ? 'organization-item-card__action__btn organization-item-card__action__btn--disabled'
                    : 'organization-item-card__action__btn'
                }
                iconClasses={
                  !granted
                    ? 'organization-item-card__action--disabled'
                    : 'organization-item-card__action'
                }
                onClick={onEditItemClick}
              />
            )}
          </Access>

          <Access
            to={{
              organizationId: item.id,
              organizationTree: organizationItems,
            }}
            rule={Permissions.DeleteOrganizationItem}
          >
            {(granted) => (
              <IconButtonWithToolTip
                disabled={!!employeeCount || !granted || hasChildren}
                buttonClasses={
                  employeeCount || !granted || hasChildren
                    ? 'organization-item-card__action__btn organization-item-card__action__btn--disabled'
                    : 'organization-item-card__action__btn'
                }
                iconClasses={
                  employeeCount || !granted || hasChildren
                    ? 'organization-item-card__action--disabled'
                    : 'organization-item-card__action'
                }
                icon="ak-delete"
                onClick={onDeleteItemClick}
                title={t('deleteItem')}
              />
            )}
          </Access>
        </div>
      </Card>
    );
  }
}

OrganizationItem.propTypes = {
  t: propTypes.func.isRequired,
  item: propTypes.shape({
    id: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    manager: propTypes.shape({
      firstName: propTypes.string,
      lastName: propTypes.string,
    }),
    parentId: propTypes.any,
    hasChildren: propTypes.bool,
    employeeCount: propTypes.number.isRequired,
  }).isRequired,
  organizationItems: propTypes.array.isRequired,
  onItemClick: propTypes.func.isRequired,
  onEditItemClick: propTypes.func.isRequired,
  onDeleteItemClick: propTypes.func.isRequired,
  onMoveItemClick: propTypes.func.isRequired,
  onMergeItemClick: propTypes.func.isRequired
};

export default withTranslation()(OrganizationItem);
