import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { handleAuthentication } from './reducer';
import Spinner from '../Spinner';
import { useAuthentication } from './authenticationHook';

const AuthProvider = (props) => {
  const {
    isFetching: authenticationIsFetching,
    error: authenticationError,
    data: authenticationData,
  } = useAuthentication();

  if (authenticationIsFetching) {
    return <Spinner isLoading={true} isCoveringPage={true} />;
  }

  if (authenticationError) {
    return <h1>AUTHENTICATION ERROR !!</h1>;
  }

  if (authenticationData?.isAuthenticated) {
    props.handleAuthentication({
      role: authenticationData.role,
      id: authenticationData.id,
      adId: authenticationData.adId,
      email: authenticationData.email,
      lastName: authenticationData.lastName,
      firstName: authenticationData.firstName,
      isManager: authenticationData.isManager,
      country: authenticationData.companyCountry,
      isAuthenticated: authenticationData.isAuthenticated,
    });

    return props.children;
  }

  return <Spinner isLoading={true} isCoveringPage={true} />;
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  handleAuthentication: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  handleAuthentication,
};

export default connect(null, mapDispatchToProps)(AuthProvider);
