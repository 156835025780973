import { Grid } from '@mui/material';
import IconButtonWithToolTip from '../../shared/components/IconButtonWithToolTip';
import { t } from '../../i18n';
import Access, { Permissions } from '../../shared/components/Access';

export const companyTableColumns = () => [
  {
    value: 'name',
    label: 'name',
  }, 
 
  {
    value: 'action',
    label: 'action',
  },
];



 

export function mappedCompanies(companyRows, onEditCompany, onDeleteCompany) {
    return companyRows.map((row) => ({
      name: {
        value: row.name,
        render: row.name,
      },

      action: {
        value: null,
        render: 

          <Grid container spacing={0}> 

            <Access rule={Permissions.UpdateCompany}>
                  {(permitted) => 
                     <IconButtonWithToolTip
                     title='Edit Company'
                     disabled={!permitted}
                     icon="ak-edit"
                     buttonClasses={
                       'organization-item-card__action__btn'
                     }
                     iconClasses={
                       'organization-item-card__action'
                     }
                     onClick={ () => {
                       onEditCompany(row)
                     }}
                   /> 
                  }
            </Access>



            <Access rule={Permissions.DeleteCompany}>
                  {(permitted) => 
                    <IconButtonWithToolTip
                    disabled={!permitted}
                    buttonClasses={ 'organization-item-card__action__btn'
                    }
                    iconClasses={ 'organization-item-card__action'
                    }
                    icon="ak-delete"
                    onClick={ () => {
                        onDeleteCompany(row)
                    }}
                    title='Delete Company'
                  /> 
                  }
            </Access>




        </Grid> 
      }
    }));
}



export function submitValidation(values) {
  const { name} = values;
  const errors = {};

  if (!name) {
    errors.name = `name is ${t('required')}`;
  }
  return errors;
}