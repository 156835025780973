import { useState, useEffect } from 'react';
import ClientService from '../../../services/client';
import ApiError from '../../../services/apiError';

export const BackEndDataProviderHook = () => {
  const [organizationUnitTypes, setOrganizationUnitTypes] = useState(null);

  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    ClientService.instance
      .fetch('/organization/unit-types/')
      .then((orgUnitTypes) => {
        setOrganizationUnitTypes(orgUnitTypes);
      })
      .catch((err) => {
        setError(err);
        throw new ApiError({
          message: 'BackEnd data fetch has been failed',
        });
      })
      .finally(() => setIsFetching(false));
  }, []);

  return {
    isFetching,
    error,
    organizationUnitTypes,
  };
};

export const BackEndDataProviderCompaniesHook = () => {
  const [companies, setCompanies] = useState(null);

  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    ClientService.instance
      .fetch('/companies')
      .then((result) => {
        setCompanies(result);
      })
      .catch((err) => {
        setError(err);
        throw new ApiError({
          message: 'BackEnd data fetch has been failed',
        });
      })
      .finally(() => setIsFetching(false));
  }, []);

  return {
    isFetching,
    error,
    companies,
  };
};

export const BackEndDataProviderRolesHook = () => {
  const [roles, setRoles] = useState(null);

  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    ClientService.instance
      .fetch('/roles')
      .then((result) => {
        setRoles(result);
      })
      .catch((err) => {
        setError(err);
        throw new ApiError({
          message: 'BackEnd data fetch has been failed',
        });
      })
      .finally(() => setIsFetching(false));
  }, []);

  return {
    isFetching,
    error,
    roles,
  };
};
