import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PageHead from '../../shared/components/PageHead';
import AkeliusTable from '../../shared/components/AkeliusTable';
import {
  mappedLocations,
  mappedEntities,
  locationTableColumns,
} from './helper';
import Button from '../../shared/components/Button';
import Icon from '../../shared/components/Icon';
import Modal from '../../shared/components/Modal';
import TextInputField from '../../shared/components/TextInput';
import SelectField from '../../shared/components/Select';
import Grid from '../../shared/components/Grid';
import Access, { Permissions } from '../../shared/components/Access';

const Location = (props) => {
  const {
    t,
    allLocations,
    allCities,
    allCountries,
    allRegions,
    submit,
    isModalOpen,
    setIsModalOpen,
    isDeletionModalOpen,
    setDeletionModalOpen,
    onsubmitRemove,
    setDeletionRowId,
    initialize,
  } = props;

  const countriesOptions = mappedEntities(allCountries);
  const citiesOptions = mappedEntities(allCities);
  const regionsOptions = mappedEntities(allRegions);
  const [titleModal, setTitleModal] = useState('');
  const [bodyDeletionModal, setBodyDeletionModal] = useState(null);

  const closeDeletionModal = () => setDeletionModalOpen(false);
  const closeModal = () => setIsModalOpen(false);

  const openModal = (row) => {
    setIsModalOpen(true);
    if (row.id) {
      setTitleModal('edit location');
      initialize({
        title: 'edit location',
        id: row.id,
        countryId: row.countryId,
        regionId: row.regionId,
        cityId: row.cityId,
        street: row.street,
        postalCode: row.postalCode,
      });
    } else {
      setTitleModal('add new location');
      initialize({
        title: 'add new location',
        id: undefined,
        countryId: null,
        regionId: null,
        cityId: null,
        street: null,
        postalCode: null,
      });
    }
  };

  const openDeletionModal = (row) => {
    setDeletionModalOpen(true);
    setDeletionRowId(row.id);
    if (row.id) {
      setTitleModal(`delete location`);
      setBodyDeletionModal(
        `are you sure you want to delete location ${row.street} in ${row.city.name} ?`,
      );
    }
  };

  function onEditLocation(row) {
    openModal(row);
  }

  function onDeleteLocation(row) {
    openDeletionModal(row);
  }

  return (
    <>
      <PageHead text="locations" />
      <Access rule={Permissions.CreateLocation}>
        {(permitted) =>
          permitted ? (
            <div>
              <Button
                onClick={openModal}
                primary
                className="off-board-table__off-board-btn"
              >
                <Icon icon="ak-plus" className="off-board-table__add-icon" />
                new
              </Button>
            </div>
          ) : (
            <div> </div>
          )
        }
      </Access>
      <AkeliusTable
        id={'location-table'}
        className={'location-table-class'}
        rows={mappedLocations(allLocations, onEditLocation, onDeleteLocation)}
        columns={locationTableColumns()}
      />
      <Modal
        title={titleModal}
        open={isModalOpen}
        onClose={closeModal}
        onSubmit={submit}
        callToActionText={titleModal}
        classNames="off-board-form"
        t={t}
      >
        <Grid container spacing={0} rowSpacing={1} >
          <Grid item xs={12}>
            <SelectField
              name="countryId"
              label={'country'}
              options={countriesOptions}
              placeholder={'select the country'}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectField
              name="cityId"
              label={'city'}
              options={citiesOptions}
              placeholder={'select the city'}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectField
              name="regionId"
              label={'region'}
              options={regionsOptions}
              placeholder={'select the region'}
            />
          </Grid>
          <br />
          <Grid item xs={12}>
            <TextInputField
              name="street"
              rows={3}
              label="street"
              placeholder="enter the street name"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              name="postalCode"
              rows={3}
              label="zip code"
              placeholder="enter the zip code"
              fullWidth
            />
          </Grid>
        </Grid>
      </Modal>
      <Modal
        title={titleModal}
        open={isDeletionModalOpen}
        onClose={closeDeletionModal}
        onSubmit={onsubmitRemove}
        callToActionText={titleModal}
        classNames="off-board-form"
        t={t}
      >
        <Grid container spacing={0}>
          {bodyDeletionModal}
        </Grid>
      </Modal>
    </>
  );
};

Location.propTypes = {
  allLocations: PropTypes.array.isRequired,
  allCountries: PropTypes.array.isRequired,
  allCities: PropTypes.array.isRequired,
  allRegions: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired,
  onsubmitRemove: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setDeletionModalOpen: PropTypes.func.isRequired,
  setDeletionRowId: PropTypes.func.isRequired,
  isDeletionModalOpen: PropTypes.bool.isRequired,
  initialize: PropTypes.func.isRequired,
};

export { Location };
