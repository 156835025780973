import React, { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { buildOrgItems } from './helpers';
import { buildOrgItemTypes } from './organizationTypesSchema';
import { fetchManagers } from '../../shared/state/managers';
import {
  handleSetParentItem,
  handleSetBreadCrumbs,
  handleAddNewItemModalOpen,
  handleGetOrgItemsBelonging,
  handleMoveItemModalOpen,
  handleMoveItemModalClose,
  handleMergeItemModalOpen,
  handleMergeItemModalClose,
} from './reducer';
import {
  handleGetTeamMembersForOrganization
} from '../Staff/StaffCatalogPanel/reducer';
import Organization from './Organization';
import AddNewItemModal from './AddNewItemModal';
import MoveItemModal from './MoveItemModal';
import MergeItemModal from './MergeItemModal';
import { ROOT_BREAD_CRUMB_ID } from './BreadCrumbs';
import { handleInformation } from '../../shared/components/InformationModal/reducer';

class OrganizationContainer extends Component {
  componentDidMount() {
    this.props.handleGetOrgItemsBelonging();
    this.props.fetchManagers();
  }

  handleBackClick = (event) => {
    this.handleReduceBreadCrumbs(event.currentTarget.id);
    this.props.handleGetTeamMembersForOrganization(event.currentTarget.id);
  };

  handleOrgItemClick = (id) => {
    this.handleAddBreadCrumb(id);
    this.props.handleSetParentItem(id);
    this.props.handleGetTeamMembersForOrganization(id);
  };

  handleAddBreadCrumb = (id) => {
    const { breadCrumbs: list, allItems } = this.props;
    const item = allItems.find((org) => org.id === id);
    const newStack = [...list, item];
    this.props.handleSetBreadCrumbs(newStack);
  };

  handleReduceBreadCrumbs = (id) => {
    const { breadCrumbs: list } = this.props;
    const lastItem = list[list.length - 1];
    if (id === ROOT_BREAD_CRUMB_ID) {
      this.props.handleSetParentItem(null);
      this.props.handleGetTeamMembersForOrganization(null);
      this.props.handleSetBreadCrumbs([]);
      return;
    }
    if (list && list.length > 0) {
      if (list.length > 1 && id !== lastItem) {
        const index = list.map((item) => item.id).indexOf(id);
        const newStack = list.slice(0, index + 1);
        this.props.handleSetBreadCrumbs(newStack);
        this.props.handleSetParentItem(id);
        this.props.handleGetTeamMembersForOrganization(id);
      }
    }
  };

  handleAddNewItemModalOpen = () => {
    this.props.handleAddNewItemModalOpen({
      addNewItemModalTitle: 'newItem',
      addNewItemModalActionButtonText: 'saveItem',
    });
  };

  handleMoveItemModalOpen = (item) => {
    this.props.handleMoveItemModalOpen(item);
  };

  handleMergeItemModalOpen = (item) => {
    this.props.handleMergeItemModalOpen(item);
  };

  onHandleInformation =
    ({ title, message, isOpen }) =>
    () => {
      this.props.handleInformation({ title, message, isOpen });
    };

  render() {
    const {
      allItems,
      breadCrumbs,
      parentItemId,
      orgItemTypes,
      managersLoading,
      itemsAreLoading,
      organizationItems,
      isError,
      isModalOpen,
      isMoveModalOpen,
      isMergeModalOpen,
      teamMembersOfOrganization
    } = this.props;

    if (isError) {
      return null;
    }

    return (
      <>
        <Organization
          allItems={allItems}
          breadCrumbs={breadCrumbs}
          parentItemId={parentItemId}
          orgItemTypes={orgItemTypes}
          itemsAreLoading={itemsAreLoading}
          managersLoading={managersLoading}
          organizationItems={organizationItems}
          onItemClick={this.handleOrgItemClick}
          onBackClick={this.handleBackClick}
          onAddNewItemModalOpen={this.handleAddNewItemModalOpen}
          onHandleInformation={this.onHandleInformation}
          onMoveItemModalOpen={this.handleMoveItemModalOpen}
          onMergeItemModalOpen={this.handleMergeItemModalOpen}
          teamMembersOfOrganization={teamMembersOfOrganization}
        />
        {isModalOpen && <AddNewItemModal isModalOpen={isModalOpen} />}
        {isMoveModalOpen && <MoveItemModal isModalOpen={isMoveModalOpen} />}
        {isMergeModalOpen && <MergeItemModal isModalOpen={isMergeModalOpen} />}
      </>
    );
  }
}

OrganizationContainer.propTypes = {
  allItems: propTypes.array.isRequired,
  breadCrumbs: propTypes.arrayOf(propTypes.object),
  parentItemId: propTypes.any,
  orgItemTypes: propTypes.array.isRequired,
  fetchManagers: propTypes.func.isRequired,
  itemsAreLoading: propTypes.bool.isRequired,
  managersLoading: propTypes.bool,
  organizationItems: propTypes.array.isRequired,
  isError: propTypes.bool,
  isModalOpen: propTypes.bool.isRequired,
  handleSetParentItem: propTypes.func.isRequired,
  handleSetBreadCrumbs: propTypes.func.isRequired,
  handleAddNewItemModalOpen: propTypes.func.isRequired,
  handleGetOrgItemsBelonging: propTypes.func.isRequired,
  handleGetTeamMembersForOrganization: propTypes.func.isRequired,
  handleInformation: propTypes.func.isRequired,
  isMoveModalOpen: propTypes.bool.isRequired,
  handleMoveItemModalOpen: propTypes.func.isRequired,
  isMergeModalOpen: propTypes.bool.isRequired,
  handleMergeItemModalOpen: propTypes.func.isRequired,
  teamMembersOfOrganization: propTypes.arrayOf(propTypes.object).isRequired
};

function mapStateToProps(state) {
  const { loading, organization, staffCatalog } = state;
  const {
    items: allItems,
    breadCrumbs,
    parentItemId,
    isError,
    isAddNewItemModalOpen: isModalOpen,
    isMoveModalOpen,
    isMergeModalOpen
  } = organization;

  const { teamMembersOfOrganization, isTeamMembersLoading } = staffCatalog;

  const managersLoading = isTeamMembersLoading;
  const organizationItems = buildOrgItems(allItems, parentItemId);

  const orgItemTypes = buildOrgItemTypes(
    parentItemId,
    allItems,
    state.backEndData.organizationUnitTypes.reduce((acc, unitType) => {
      acc[unitType.id] = unitType.id;
      return acc;
    }, {}),
  );

  return {
    allItems,
    breadCrumbs,
    parentItemId,
    orgItemTypes,
    managersLoading,
    itemsAreLoading: loading,
    organizationItems,
    isError,
    isModalOpen,
    isMoveModalOpen,
    isMergeModalOpen,
    teamMembersOfOrganization
  };
}

export default connect(mapStateToProps, {
  fetchManagers,
  handleSetParentItem,
  handleSetBreadCrumbs,
  handleAddNewItemModalOpen,
  handleGetOrgItemsBelonging,
  handleGetTeamMembersForOrganization,
  handleInformation,
  handleMoveItemModalOpen,
  handleMoveItemModalClose,
  handleMergeItemModalOpen,
  handleMergeItemModalClose
})(OrganizationContainer);
