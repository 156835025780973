import { createAction, handleActions } from 'redux-actions';

const INFORMATION = 'INFORMATION';
const informationAction = createAction(INFORMATION);

export const handleInformation = ({ title, message, isOpen = true }) => (
  dispatch,
) => dispatch(informationAction({ title, message, isOpen }));

export const initialState = {
  title: '',
  message: '',
  isOpen: false,
};

export default handleActions(
  {
    [INFORMATION]: (state, action) => ({
      ...state,
      title: action.payload.title,
      message: action.payload.message,
      isOpen: action.payload.isOpen,
    }),
  },
  {
    ...initialState,
  },
);
