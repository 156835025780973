import { keys, values, memoize } from 'lodash/fp';
import { Country, City } from '../types';
import { t } from '../../i18n';

export const NONE = 'none';
export const UNASSIGNED = 'Unassigned';
export const NOT_APPLICABLE = 'not applicable';

export const WORKING_DAYS = {
  mondays: 'mondays',
  tuesdays: 'tuesdays',
  wednesdays: 'wednesdays',
  thursdays: 'thursdays',
  fridays: 'fridays',
  saturdays: 'saturdays',
  sundays: 'sundays',
};

export const OFFICE_LOCATION = {
  [Country.Canada]: {
    [City.Toronto]: {
      'CA - Toronto Office': '533 College Street',
    },
    [City.Montreal]: {
      'CA - Montreal Office': '511 Place D’Arme',
    },
  },
  [Country.Denmark]: {
    [City.Copenhagen]: {
      'DK - Copenhagen Office': 'Nørre Voldgade 80',
    },
  },
  [Country.UnitedKingdom]: {
    [City.London]: {
      'UK - London Office': '25 Hosier Lane',
    },
  },
  [Country.France]: {
    [City.Paris]: {
      'FR - Paris Office': '37-41 rue du Rocher',
    },
  },
  [Country.Germany]: {
    [City.Berlin]: {
      'DE - Berlin Erkelenzdamm office': 'Erkelenzdamm 11-13',
      'DE - Berlin Franklinstraße office': 'Franklinstraße 26A',
      'DE - Berlin Pohlstraße office': 'Pohlstraße 20',
    },
    [City.Hamburg]: {
      'DE - Hamburg Office': 'Beim Strohhause 29',
    },
  },
  [Country.UnitedStates]: {
    [City.Boston]: {
      'US - Boston Office': '300 A Street, Suite 5',
    },

    [City.NewYork]: {
      'US - New York Office': '110 William street, unit 1810',
    },
    [City.Washington]: {
      'US - Washington Office': '1620 Eye Street NW, Suite 703',
    },
  },
  [Country.Sweden]: {
    [City.Malmo]: {
      'SE - Malmö Office': 'Föreningsgatan 15',
    },
    [City.Gothenburg]: {
      'SE - Gothenburg Office': 'Stora Åvägen 21',
    },
    [City.Stockholm]: {
      'SE - Stockholm Office': 'Rosenlundsgatan 50',
    },
  },
  [Country.Cyprus]: {
    [City.Nicosa]: {
      'CY - Nicosa': 'Flat 202, 91 Limassol Avenue',
    },
  },
};

export const COUNTRY_KEYBOARDS = {
  [City.Berlin]: 'German',
  [City.Boston]: 'English (US)',
  [City.Copenhagen]: 'Danish',
  [City.Hamburg]: 'German',
  [City.London]: 'English (UK)',
  [City.Malmo]: 'Swedish',
  [City.Montreal]: null,
  [City.NewYork]: 'English (US)',
  [City.Nicosa]: 'English (UK)',
  [City.Paris]: 'French',
  [City.Stockholm]: 'Swedish',
  [City.Toronto]: 'English (CA)',
  [City.Washington]: 'English (US)',
};

export const PREFERRED_LANGUAGES = {
  [City.Berlin]: 'DE - German',
  [City.Boston]: 'US - English',
  [City.Copenhagen]: 'DK - Danish',
  [City.Hamburg]: 'DE - German',
  [City.London]: 'UK - English',
  [City.Malmo]: 'SE - Swedish',
  [City.Montreal]: null,
  [City.NewYork]: 'US - English',
  [City.Nicosa]: 'UK - English',
  [City.Paris]: 'FR - French',
  [City.Stockholm]: 'SE - Swedish',
  [City.Toronto]: 'CA - English',
  [City.Washington]: 'US - English',
};

export const COUNTRY_POWER_PLUGS = {
  [City.Berlin]: 'Germany',
  [City.Boston]: 'United states',
  [City.Copenhagen]: 'Denmark',
  [City.Hamburg]: 'Germany',
  [City.London]: 'United kingdom',
  [City.Malmo]: 'Sweden',
  [City.Montreal]: 'Canada',
  [City.NewYork]: 'United states',
  [City.Nicosa]: 'Cyprus',
  [City.Paris]: 'France',
  [City.Stockholm]: 'Sweden',
  [City.Toronto]: 'Canada',
  [City.Washington]: 'United states',
};

export const DELIVERY_ADDRESSES = {
  [City.Berlin]: 'GER - Berlin Office',
  [City.Boston]: 'US - Boston Office',
  [City.Copenhagen]: 'DK - Copenhagen Office',
  [City.Hamburg]: 'GER - Hamburg Office',
  [City.London]: 'UK - London Office',
  [City.Malmo]: 'SE - Malmö Office',
  [City.Montreal]: 'CA - Montreal Office',
  [City.NewYork]: 'US - New York Office',
  [City.Nicosa]: 'CY - Nicosia Office',
  [City.Paris]: 'FR - Paris Office',
  [City.Stockholm]: 'SE - Stockholm Office',
  [City.Toronto]: 'CA - Toronto Office',
  [City.Washington]: 'US - Washington Office',
};

export const BOOLEAN_VALUE = {
  yes: 'yes',
  no: 'no',
};

export const LANGUAGE = {
  German: 'German',
  French: 'French',
  Danish: 'Danish',
  Swedish: 'Swedish',
  'English (CA)': 'English (CA)',
  'English (UK)': 'English (UK)',
  'English (US)': 'English (US)',
};

export const DISPLAYED_NUMBER_OUTBOUND_CALL = {
  'Direct number': 'Direct number',
  'Service Center number': 'Service Center number',
};

export const APPLICATION_ACCESS = {
  none: NONE,
  acm: 'ACM',
  confluence: 'Confluence',
  d3: 'D3',
  dynamicWorkplace: 'Dynamic Workplace',
  hogia: 'Hogia',
};

const mapConstantToSelectOption = (item) => ({
  text: item,
  value: item,
});

export const getApplicationAccessOptions = memoize(() =>
  values(APPLICATION_ACCESS).map(mapConstantToSelectOption),
);

export const getCountryOptions = () =>
  keys(OFFICE_LOCATION).map((item) => ({ text: t(item), value: item }));

export const getCountrySettingOptions = memoize(() =>
  [NOT_APPLICABLE, ...keys(OFFICE_LOCATION)].map(mapConstantToSelectOption),
);

export const getCityOptions = (country) =>
  keys(OFFICE_LOCATION[country]).map((item) => ({
    text: t(item),
    value: item,
  }));

export const getOfficeLocationOptions = (country, city) =>
  keys(OFFICE_LOCATION[country][city]).map((key) => ({
    text: OFFICE_LOCATION[country][city][key],
    value: key,
  }));

export const getDisplayedNumberOutboudCallOptions = memoize(() =>
  values(DISPLAYED_NUMBER_OUTBOUND_CALL).map(mapConstantToSelectOption),
);

export const getManagerOptions = (items) => {
  const data = items.map((manager) => ({
    text: `${manager.firstName} ${manager.lastName}`,
    value: manager.id,
  }));

  return [{ value: UNASSIGNED, text: UNASSIGNED }, ...data];
};

export const getOrganizationOptions = (items) => {
  const data = items.map((org) => ({
    text: `${org.name}`,
    value: org.id,
  }));

  return [{ value: UNASSIGNED, text: UNASSIGNED }, ...data];
};

export const getContractOptions = (items) =>
  items.map((contract) => ({
    text: `${contract.firstName} ${contract.lastName}`,
    value: contract.id,
  }));

export const getEmployeeCountryOptions = (items) =>
  Object.keys(items).map((code) => ({ text: items[code], value: code }));

export const OFF_BOARD_REASONS = {
  RESIGNATION: 'voluntaryResignation',
  CANCELLED_PROBATION: 'voluntaryCancelledProbation',
  REDUNDANCY: 'involuntaryRedundancy',
  LAYOFF: 'involuntaryLayoff',
  DISMISSAL: 'involuntaryDismissal',
  CANCELLEDPROBATION: 'involuntaryCancelledProbation',
  TEMPORARYCONTRACTEXPIRED: 'temporaryContractExpired',
  RETIREMENT: 'reasonRetirement',
  FOLLOWINGPROPERTYSALES: 'followingPropertySales',
  PASSEDAWAY: 'passedAway',
};
