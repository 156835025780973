import { memoize } from 'lodash/fp';
import countries from 'i18n-iso-countries';
import { LanguageKeys, fallbackLanguageKey } from '../../i18n';

const languageConfig = {
  defaultLanguage: fallbackLanguageKey,
  allowedLanguages: LanguageKeys,
};

const getCountriesTranslations = memoize((lang) => countries.getNames(lang));

function getCountries() {
  return getCountriesTranslations(
    localStorage.getItem('i18nextLng').split('-')[0],
  );
}

const getParsedErrorMessage = (errMessage) => (translationMethod) => {
  try {
    const { string, interpolate } = JSON.parse(errMessage);
    return translationMethod(string, {
      ...interpolate,
    });
  } catch (e) {
    return errMessage ? translationMethod(errMessage) : null;
  }
};

export { getCountries, languageConfig, getParsedErrorMessage };
