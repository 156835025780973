import React from 'react';
import PropTypes from 'prop-types';
import StaffCatalogEmployeeCard from '../Staff/StaffCatalogEmployeeCard/StaffCatalogEmployeeCard';
import { employeePropTypes } from './propTypes';
import { getComposedSubordinatesData } from './helpers';
import Grid from '../../shared/components/Grid';
import './Team.scss';

class Team extends React.Component {
  static propTypes = {
    subordinates: PropTypes.arrayOf(employeePropTypes),
    teamManager: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      businessDivision: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      companyStreet: PropTypes.string,
      companyCity: PropTypes.string,
      email: PropTypes.string,
       businessDivisionId: PropTypes.string,
    }),
    orgItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  renderMyTeam = (subordinates) => {
    if (subordinates.length > 0) {
      const composedSubordinatesData = getComposedSubordinatesData(
        subordinates,
        this.props.orgItems,
        this.props.teamManager?.id,
      );

      return composedSubordinatesData.reduce((acc, orgItemWithMembers) => {
        const parsedMembers = orgItemWithMembers.members.map((member) => (
          <Grid item lg={6} md={6} sm={12} xs={12} key={member.id}>
            <StaffCatalogEmployeeCard employee={member} isOutlined={true} />
          </Grid>
        ));
        acc.push(
          <div key={orgItemWithMembers.orgItemName}>
            <h2 className="team__org-item-name">
              {orgItemWithMembers.orgItemName}
            </h2>
            <Grid container key={orgItemWithMembers.orgItemName}>
              {parsedMembers}
            </Grid>
          </div>,
        );
        return acc;
      }, []);
    }

    return null;
  };

  render() {
    const { subordinates, teamManager } = this.props;
    return (
      <div className="team">
        {teamManager && (
          <Grid container>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <StaffCatalogEmployeeCard
                employee={teamManager}
                isOutlined={true}
              />
            </Grid>
          </Grid>
        )}

        {this.renderMyTeam(subordinates)}
        </div>
    );
  }
}

export default Team;
