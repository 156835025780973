import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import Icon from '../../Icon';
import Button from '../../Button';
import { MODES, DIRECTIONS } from '../constants';
import './DatePickerControls.scss';

export const DatePickerControls = ({
  onDateDisplayButtonClick,
  mode,
  calendarData,
  onCalendarControlsButtonClick,
  yearsRangeValues,
}) => (
    <div className="datepicker-controls">
      <Button
        className="datepicker-controls__btn datepicker-controls__btn--date-display"
        onClick={onDateDisplayButtonClick}
      >
        {mode === MODES.DATES_MODE &&
          `${calendarData.monthName} ${calendarData.year}`}
        {mode === MODES.YEARS_MODE &&
          yearsRangeValues &&
          `${yearsRangeValues[0]} - ${
            yearsRangeValues[yearsRangeValues.length - 1]
          }`}
        {mode === MODES.MONTHS_MODE && `${calendarData.year}`}
        <Icon
          icon="ak-arrow-drop-down"
          className={classnames('datepicker-controls__expansion-icon')}
          rotate={
            mode === MODES.MONTHS_MODE || mode === MODES.YEARS_MODE
              ? 'bottom'
              : 'top'
          }
        />
      </Button>
      <Button
        className="datepicker-controls__btn"
        onClick={onCalendarControlsButtonClick}
        direction={DIRECTIONS.LEFT}
      >
        <Icon icon="ak-chevron-left" className="datepicker-controls__icon" />
      </Button>
      <Button
        className="datepicker-controls__btn"
        onClick={onCalendarControlsButtonClick}
        direction={DIRECTIONS.RIGHT}
      >
        <Icon icon="ak-chevron-right" className="datepicker-controls__icon" />
      </Button>
    </div>
  );

DatePickerControls.propTypes = {
  onDateDisplayButtonClick: propTypes.func.isRequired,
  mode: propTypes.string.isRequired,
  calendarData: propTypes.exact({
    date: propTypes.string.isRequired,
    year: propTypes.string.isRequired,
    monthName: propTypes.string.isRequired,
    monthsNames: propTypes.arrayOf(propTypes.string).isRequired,
    weekDayNames: propTypes.arrayOf(propTypes.string).isRequired,
    dates: propTypes.arrayOf(
      propTypes.exact({
        shortDate: propTypes.string.isRequired,
        fullDate: propTypes.string.isRequired,
        isChosenDate: propTypes.bool.isRequired,
        dateActuality: propTypes.string.isRequired,
      }),
    ).isRequired,
  }),
  onCalendarControlsButtonClick: propTypes.func.isRequired,
  yearsRangeValues: propTypes.arrayOf(propTypes.number).isRequired,
};
