import React from 'react';
import PropTypes from 'prop-types';
import './Avatar.scss';

import Icon from '../../../../shared/components/Icon';

const Avatar = ({ imageUrl }) => {
  if (!imageUrl || typeof imageUrl !== 'string') {
    return <Icon icon="ak-person" className="avatar__preview-icon" />;
  }

  return (
    <div className="avatar">
      <div
        className="avatar__image"
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      />
    </div>
  );
};

Avatar.propTypes = {
  imageUrl: PropTypes.string,
};
Avatar.defaultProps = {
  imageUrl: null,
};

export default Avatar;
