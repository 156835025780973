import thunk from 'redux-thunk';
import { applyMiddleware, createStore, compose } from 'redux';
import config from '../config';
import reducer from './rootReducer';

const { REACT_APP_NODE_CONFIG } = config;
const getMiddleware = () => applyMiddleware(thunk);
const isStagingEnvironment = REACT_APP_NODE_CONFIG === 'stage';
const isProductionEnvironment = REACT_APP_NODE_CONFIG === 'prod';
const reduxDevtoolsExtensionCompose =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || null; // eslint-disable-line

const composeEnhancers =
  (!isStagingEnvironment || !isProductionEnvironment) &&
  reduxDevtoolsExtensionCompose
    ? reduxDevtoolsExtensionCompose
    : compose;

const store = createStore(reducer, composeEnhancers(getMiddleware()));

export default store;
