import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles';

 

export const AkGrid = ({ container, ...rest }) => {
  const theme = createTheme();
  const xs = useMediaQuery(theme.breakpoints.down(576));
  const sm = useMediaQuery(theme.breakpoints.up(576));
  const md = useMediaQuery(theme.breakpoints.up(768));
  const lg = useMediaQuery(theme.breakpoints.up(1024));
  const xl = useMediaQuery(theme.breakpoints.up(1440));
  const xxl = useMediaQuery(theme.breakpoints.up(1920));

  const spacings = {
    xs: 2,
    sm: 2,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3,
  };

  let spacing = 0;

  if (xs === true) {
    spacing = spacings.xs;
  }
  if (sm === true) {
    spacing = spacings.sm;
  }
  if (md === true) {
    spacing = spacings.md;
  }
  if (lg === true) {
    spacing = spacings.lg;
  }
  if (xl === true) {
    spacing = spacings.xl;
  }
  if (xxl === true) {
    spacing = spacings.xxl;
  }

  if (!container) {
    return <Grid {...rest} />;
  }

  return <Grid container spacing={spacing} {...rest} />;
};

AkGrid.propTypes = {
  container: PropTypes.bool,
};
