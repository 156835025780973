import { createActions, handleActions } from 'redux-actions';
import ClientService from '../../../../services/client';
import { handleNotification } from '../../../../shared/components/Notification/reducer';

const delay = (duration) => new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });

const GET_EMPLOYEE_IMAGE_STARTED = 'GET_EMPLOYEE_IMAGE_STARTED';
const GET_EMPLOYEE_IMAGE_SUCCESS = 'GET_EMPLOYEE_IMAGE_SUCCESS';
const GET_EMPLOYEE_IMAGE_FAILURE = 'GET_EMPLOYEE_IMAGE_FAILURE';


const GET_EMPLOYEE_FILENAME_SUCCESS = 'GET_EMPLOYEE_FILENAME_SUCCESS';

const CREATE_EMPLOYEE_IMAGE_STARTED = 'CREATE_EMPLOYEE_IMAGE_STARTED';
const CREATE_EMPLOYEE_IMAGE_SUCCESS = 'CREATE_EMPLOYEE_IMAGE_SUCCESS';
const CREATE_EMPLOYEE_IMAGE_FAILURE = 'CREATE_EMPLOYEE_IMAGE_FAILURE';

const DELETE_EMPLOYEE_IMAGE_STARTED = 'DELETE_EMPLOYEE_IMAGE_STARTED';
const DELETE_EMPLOYEE_IMAGE_SUCCESS = 'DELETE_EMPLOYEE_IMAGE_SUCCESS';
const DELETE_EMPLOYEE_IMAGE_FAILURE = 'DELETE_EMPLOYEE_IMAGE_FAILURE';

const {
  getEmployeeImageStarted,
  getEmployeeImageSuccess,
  getEmployeeImageFailure,

  getEmployeeFilenameSuccess,

  createEmployeeImageStarted,
  createEmployeeImageSuccess,
  createEmployeeImageFailure,

  deleteEmployeeImageStarted,
  deleteEmployeeImageSuccess,
  deleteEmployeeImageFailure,
} = createActions(
  GET_EMPLOYEE_IMAGE_STARTED,
  GET_EMPLOYEE_IMAGE_SUCCESS,
  GET_EMPLOYEE_IMAGE_FAILURE,

  GET_EMPLOYEE_FILENAME_SUCCESS,

  CREATE_EMPLOYEE_IMAGE_STARTED,
  CREATE_EMPLOYEE_IMAGE_SUCCESS,
  CREATE_EMPLOYEE_IMAGE_FAILURE,

  DELETE_EMPLOYEE_IMAGE_STARTED,
  DELETE_EMPLOYEE_IMAGE_SUCCESS,
  DELETE_EMPLOYEE_IMAGE_FAILURE,
);

export function getEmployeeImage(email) {
  return async (dispatch) => {
    dispatch(getEmployeeImageStarted());
    try {
      if (email) {
        dispatch(getEmployeeImageSuccess(`${ClientService.instance.backendUrl}/media/${email}?rnd=${Date.now()}`));
        const { fileName: filename }  = await ClientService.instance.fetch(`/media/${email}?rnd=${Date.now()}&filename=true`);
        dispatch(getEmployeeFilenameSuccess(filename));
      } else {
        dispatch(getEmployeeImageSuccess(null));
      }
    } catch (err) {
      if (err.status === 404) { // 404 means no image found
        dispatch(getEmployeeImageSuccess(null));
        dispatch(getEmployeeFilenameSuccess(null));
      } else {
        dispatch(getEmployeeImageFailure(err));
        dispatch(
          handleNotification({
            message: `Error: employee image can not be fetched ${err}`,
            type: 'error',
            isActive: true,
          }),
        );
      }
    }
  };
}

export function createEmployeeImage(email, image) {
  const formData = new FormData();
  formData.append('file', image[0]);

  return async (dispatch) => {
    dispatch(createEmployeeImageStarted());
    try {
      const response = await ClientService.instance.multipartPost(
        `/media/${email}`,
        formData,
      );

      if (
        response.status === 201
        && response.data.result.size
        && response.data.result.hash
      ) {
        await delay(2000);  // adding delay because document service takes some time to reflect the image in their database
        dispatch(createEmployeeImageSuccess(`${ClientService.instance.backendUrl}/media/${email}?rnd=${Date.now()}`));
        const { fileName: filename } = await ClientService.instance.fetch(`/media/${email}?rnd=${Date.now()}&filename=true`);
        dispatch(getEmployeeFilenameSuccess(filename));
      }
    } catch (err) {
      if (err.status === 404) { // 404 means no image found
        dispatch(getEmployeeImageSuccess(null));
      } else {
        dispatch(createEmployeeImageFailure(err));
        dispatch(
          handleNotification({
            message: `Error: employee image can not be created ${err}`,
            type: 'error',
            isActive: true,
          }),
        );
      }
    }
  };
}

export function deleteEmployeeImage(email) {
  return async (dispatch) => {
    dispatch(deleteEmployeeImageStarted());
    try {
      const response = await ClientService.instance.multipartDelete(
        `/media/${email}`,
      );
      if (response.status === 204) {
        dispatch(deleteEmployeeImageSuccess());
      }
    } catch (err) {
      if (err.status !== 204) {
        dispatch(deleteEmployeeImageFailure(err));
        dispatch(
          handleNotification({
            message: `Error: employee image can not be deleted ${err}`,
            type: 'error',
            isActive: true,
          }),
        );
      }
    }
  };
}

export default handleActions(
  {
    GET_EMPLOYEE_IMAGE_STARTED: (state) => ({
      ...state,
      employeeImageUrlIsFetching: true,
    }),
    GET_EMPLOYEE_IMAGE_SUCCESS: (state, action) => ({
      ...state,
      employeeImageUrlIsFetching: false,
      employeeImageUrl: action.payload,
    }),
    GET_EMPLOYEE_IMAGE_FAILURE: (state, action) => ({
      ...state,
      employeeImageUrlIsFetching: false,
      errorMessage: action.payload,
    }),
    GET_EMPLOYEE_FILENAME_SUCCESS: (state, action) => ({
      ...state,
      employeeImageFilename: action.payload,
    }),
    GET_EMPLOYEE_FILENAME_FAILURE: (state) => ({
      ...state,
      employeeImageFilename: null,
    }),
    CREATE_EMPLOYEE_IMAGE_STARTED: (state) => ({
      ...state,
      employeeImageUrlIsCreating: true,
      employeeImageUrl: null,
    }),
    CREATE_EMPLOYEE_IMAGE_SUCCESS: (state, action) => ({
      ...state,
      employeeImageUrlIsCreating: false,
      employeeImageUrl: action.payload,
    }),
    CREATE_EMPLOYEE_IMAGE_FAILURE: (state, action) => ({
      ...state,
      employeeImageUrlIsCreating: false,
      errorMessage: action.payload,
    }),
    DELETE_EMPLOYEE_IMAGE_STARTED: (state) => ({
      ...state,
      employeeImageUrlIsDeleting: true,
    }),
    DELETE_EMPLOYEE_IMAGE_SUCCESS: (state) => ({
      ...state,
      employeeImageUrlIsDeleting: false,
      employeeImageUrl: null,
      employeeImageFilename: null,
    }),
    DELETE_EMPLOYEE_IMAGE_FAILURE: (state, action) => ({
      ...state,
      employeeImageUrlIsDeleting: false,
      errorMessage: action.payload,
    }),
  },
  {
    employeeImageUrlIsFetching: false,
    employeeImageUrlIsDeleting: false,
    employeeImageUrlIsCreating: false,
    employeeImageUrl: null,
    employeeImageFilename: null,
    errorMessage: null,
  },
);
