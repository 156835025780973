import { useState, useEffect } from 'react';
import ApiError from '../../../services/apiError';
import { getUserRoleFromToken } from './helpers';
import ClientService from '../../../services/client';
import history from '../../state/history';
import { allowedHeaders } from '../AppConfigProvider';

export const useAuthentication = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const authenticate = async () => {
    try {
      const { auth0Client } = await ClientService.instance
        .initializedAuth0Client;

      const { search, pathname } = window.location;

      if (search.includes('code=') && search.includes('state=')) {
        const { appState } = await auth0Client.handleRedirectCallback();
        history.push(appState?.targetUrl ? appState.targetUrl : pathname);
      }

      const isAuthenticated = await auth0Client.isAuthenticated();

      if (isAuthenticated) {
        const auth0User = await auth0Client.getUser();

        try {
          const appUser = await ClientService.instance.fetch('/employees/me');

          setData({
            role: getUserRoleFromToken(
              auth0User['https://akelius.com/claims/roles'] ||
                auth0User['https://staffapp.akelius.com/roles'],
            ),
            id: appUser.id,
            adId: appUser.adId,
            email: appUser.email,
            lastName: appUser.lastName,
            firstName: appUser.firstName,
            isManager: appUser.isManager,
            companyCountry: appUser.companyCountry,

            isAuthenticated,
          });
        } catch (err) {
          if (err.status !== 404) {
            throw new ApiError({
              message: 'user not found',
            });
          }
        }
      } else {
        await auth0Client.loginWithRedirect({
          redirect_uri:
            ClientService.instance.headersConfig[
              allowedHeaders.STAFF_APP_AUTH_REDIRECT_URI
            ],
          appState: {
            targetUrl: pathname + search,
          },
        });
      }
    } catch (err) {
      setError(err);
      throw new ApiError({
        message: `Authentication failed`,
      });
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    authenticate();
  }, []);

  return { isFetching, error, data };
};
