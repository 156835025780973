import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button as MUIButton } from '@mui/material';
import { Link } from 'react-router-dom';
import './Button.scss';

const Button = ({
  children,
  className,
  primary,
  secondary,
  success,
  warning,
  danger,
  disabled,
  onClick,
  id,
  to,
  ...rest
}) => {
  const button = (
    <MUIButton
      className={classnames(`button ${className}`, {
        'button--primary': primary,
        'button--secondary': secondary,
        'button--success': success,
        'button--warning': warning,
        'button--danger': danger,
        'button--disabled': disabled,
      })}
      disabled={disabled}
      onClick={onClick}
      id={id}
      {...rest}
    >
      {children}
    </MUIButton>
  );
  return to ? (
    <Link to={to} className="button--link">
      {button}
    </Link>
  ) : (
    button
  );
};

Button.defaultProps = {
  to: null,
  className: '',
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  success: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string,
  to: PropTypes.string,
};

export default Button;
