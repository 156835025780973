import React from 'react';
import PropTypes from 'prop-types';
import './PageHead.scss';

const PageHead = ({ text }) => <h1 className="page-head">{text}</h1>;

PageHead.propTypes = {
  text: PropTypes.string.isRequired,
};

export default PageHead;
