import React, { Component } from 'react';
import propTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash/fp';
import Access, { Permissions } from '../../../shared/components/Access';
import Card from '../../../shared/components/Card';
import IconButtonWithToolTip from '../../../shared/components/IconButtonWithToolTip';
import './DepartmentItem.scss';

class DepartmentItem extends Component {
  render() {
    const {
      t,
      item: { id, name, rootId, organization },
      departmentItems,
      onItemClick,
      onEditItemClick,
      onDeleteItemClick,
    } = this.props;
    
     const organizationName = `${get('name')(organization) || ''}`;

    return (
      <Card className="department-item-card">
        <div className="department-item-card__title">
          <span
            className="department-item-card__title-text"
            title={name}
            onClick={onItemClick}
          >
            {name}
          </span>
        </div>
        <div
          className="department-item-card__content"
          title={`${organizationName} (${rootId})`}
        >
          {organizationName} 
        </div>
        <div className="department-item-card__actions">
          <Access
            to={{
              departmentId: id,
              departmentTree: departmentItems,
            }}
            rule={Permissions.EditDepartmentItem}
          >
            {(granted) => (
              <IconButtonWithToolTip
                title={t('editItem')}
                disabled={!granted}
                icon="ak-edit"
                buttonClasses={
                  !granted
                    ? 'department-item-card__action__btn department-item-card__action__btn--disabled'
                    : 'department-item-card__action__btn'
                }
                iconClasses={
                  !granted
                    ? 'department-item-card__action--disabled'
                    : 'department-item-card__action'
                }
                onClick={onEditItemClick}
              />
            )}
          </Access>

          <Access
            to={{
              departmentId: id,
              departmentTree: departmentItems,
            }}
            rule={Permissions.DeleteDepartmentItem}
          >
            {(granted) => (
              <IconButtonWithToolTip
                disabled={!granted}
                buttonClasses={
                  !granted
                    ? 'department-item-card__action__btn department-item-card__action__btn--disabled'
                    : 'department-item-card__action__btn'
                }
                iconClasses={
                  !granted
                    ? 'department-item-card__action--disabled'
                    : 'department-item-card__action'
                }
                icon="ak-delete"
                onClick={onDeleteItemClick}
                title={t('deleteItem')}
              />
            )}
          </Access>
        </div>
      </Card>
    );
  }
}

DepartmentItem.propTypes = {
  t: propTypes.func.isRequired,
  item: propTypes.shape({
    id: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    rootId: propTypes.string.isRequired,
    organization: propTypes.object.isRequired
  }).isRequired,
  departmentItems: propTypes.array.isRequired,
  onItemClick: propTypes.func.isRequired,
  onEditItemClick: propTypes.func.isRequired,
  onDeleteItemClick: propTypes.func.isRequired
};

export default withTranslation()(DepartmentItem);
