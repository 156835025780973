import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Link from '@mui/material/Link';
import './BreadCrumbs.scss';
import { ROOT_BREAD_CRUMB_ID, EXPAND_BREAD_CRUMB_ID, MODES } from './constants';
import { useWindowSize } from './useWindowSize';

export const BreadCrumbs = ({ items, onClick, rootCrumb }) => {
  const { width } = useWindowSize();

  const [mode, setMode] = useState(MODES.UNFOLDED);

  const measuredRef = useCallback(
    (node) => {
      if (
        node !== null &&
        node?.getBoundingClientRect()?.height > 30 &&
        items.length > 1
      ) {
        setMode(MODES.FOLDED);
      }
    },
    // eslint-disable-next-line
    [items.length, width],
  );

  const getData = () => {
    if (mode === MODES.FOLDED) {
      return [
        { name: rootCrumb, id: ROOT_BREAD_CRUMB_ID },
        { name: '...', id: EXPAND_BREAD_CRUMB_ID },
        { ...items[items.length - 1] },
      ];
    }
    return [{ name: rootCrumb, id: ROOT_BREAD_CRUMB_ID }, ...items];
  };

  const handleClick = (evt) => {
    if (evt.target.id === EXPAND_BREAD_CRUMB_ID) {
      setMode(MODES.UNFOLDED);
    } else {
      onClick(evt);
    }
  };

  if (!items.length) {
    return null;
  }

  return (
    <div className="bread-crumbs" ref={measuredRef}>
      {getData().map((item) => (
        <span key={item.name} className="bread-crumbs__item" id={item.id}>
          <Link
            underline="none"
            component="button"
            id={item.id}
            onClick={handleClick}
            className="bread-crumbs__link"
          >
            {item.name}
          </Link>

          <KeyboardArrowRightIcon className="bread-crumbs__arrow" />
        </span>
      ))}
    </div>
  );
};

BreadCrumbs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func.isRequired,
  rootCrumb: PropTypes.string.isRequired,
};
