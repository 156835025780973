import React, { useState, useRef, useEffect } from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import './DatePickerPanel.scss';

export const DatePickerPanel = ({ isOpen, children }) => {
  const node = useRef(null);
  const [calendarPanelPosition, setCalendarPanelPosition] = useState(null);

  const alignCalendar = () => {
    const viewportHeight =
      window.innerHeight || document.documentElement.offsetHeight;

    const rect = node?.current?.getBoundingClientRect();

    if (rect?.bottom >= viewportHeight) {
      setCalendarPanelPosition('top');
    } else {
      setCalendarPanelPosition('bottom');
    }
  };

  useEffect(() => {
    alignCalendar();
  }, [isOpen]);

  return (
    isOpen && (
      <div
        className={classnames('datepicker-panel', {
          'datepicker-panel--positioned-top': calendarPanelPosition === 'top',
          'datepicker-panel--positioned-bottom':
            calendarPanelPosition === 'bottom',
        })}
        ref={node}
      >
        {children}
      </div>
    )
  );
};

DatePickerPanel.propTypes = {
  isOpen: propTypes.bool.isRequired,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
};
