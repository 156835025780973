import { createActions, handleActions } from 'redux-actions';
import ClientService from '../../services/client';
import { handleNotification } from '../../shared/components/Notification/reducer';

const GET_ROLES = 'GET_ROLES';
const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE';
const NEW_ROLE = 'NEW_ROLE';
const NEW_ROLE_SUCCESS = 'NEW_ROLE_SUCCESS';
const NEW_ROLE_FAILURE = 'NEW_ROLE_FAILURE';
const EDIT_ROLE = 'EDIT_ROLE';
const EDIT_ROLE_SUCCESS = 'EDIT_ROLE_SUCCESS';
const EDIT_ROLE_FAILURE = 'EDIT_ROLE_FAILURE';
const REMOVE_ROLE = 'REMOVE_ROLE';
const REMOVE_ROLE_SUCCESS = 'REMOVE_ROLE_SUCCESS';
const REMOVE_ROLE_FAILURE = 'REMOVE_ROLE_FAILURE';

const actionCreators = createActions(
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,

  NEW_ROLE,
  NEW_ROLE_SUCCESS,
  NEW_ROLE_FAILURE,

  EDIT_ROLE,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_FAILURE,

  REMOVE_ROLE,
  REMOVE_ROLE_SUCCESS,
  REMOVE_ROLE_FAILURE,
);

const {
  getRoles,
  getRolesSuccess,
  getRolesFailure,
  newRoleSuccess,
  newRoleFailure,
  editRole,
  editRoleSuccess,
  editRoleFailure,
  removeRole,
  removeRoleSuccess,
  removeRoleFailure,
} = actionCreators;

export function getAllRoles() {
  return (dispatch) => {
    dispatch(getRoles());
    return ClientService.instance
      .fetch('/roles')
      .then((response) => {
        dispatch(getRolesSuccess(response));
      })
      .catch((error) => {
        dispatch(getRolesFailure(error.message));
      });
  };
}

export function createRole(parameters) {
  return (dispatch) => {
    dispatch(getRoles());
    return ClientService.instance
      .post('/roles', {
        ...parameters,
      })
      .then((response) => {
        dispatch(newRoleSuccess());
        dispatch(
          handleNotification({
            message: 'new role added',
            type: 'success',
            isActive: true,
          }),
        );
        return response;
      })
      .catch((error) => {
        dispatch(newRoleFailure(error));
        dispatch(
          handleNotification({
            message: 'error creating role',
            type: 'error',
            isActive: true,
          }),
        );
      });
  };
}

export function updateRole(roleId, params) {
  return async (dispatch) => {
    dispatch(editRole());
    return ClientService.instance
      .patch(`/roles/${roleId}`, { ...params })
      .then(() => {
        dispatch(editRoleSuccess());
        dispatch(
          handleNotification({
            message: 'role has been updated',
            type: 'success',
            isActive: true,
          }),
        );
      })
      .catch((error) => {
        dispatch(editRoleFailure(error.message));
        dispatch(
          handleNotification({
            message: 'error updating role',
            type: 'error',
            isActive: true,
          }),
        );
        throw error;
      });
  };
}

export function deleteRole(roleId) {
  return async (dispatch) => {
    dispatch(removeRole());
    return ClientService.instance
      .delete(`/roles/${roleId}`)
      .then(() => {
        dispatch(removeRoleSuccess());
        dispatch(
          handleNotification({
            message: 'role has been removed',
            type: 'success',
            isActive: true,
          }),
        );
      })
      .catch((error) => {
        dispatch(removeRoleFailure(error.message));
        dispatch(
          handleNotification({
            message: 'error deleting role',
            type: 'error',
            isActive: true,
          }),
        );
        throw error;
      });
  };
}

const roleReducer = handleActions(
  {
    GET_ROLES: (state) => ({
      ...state,
      isFetched: true,
    }),
    GET_ROLES_SUCCESS: (state, action) => ({
      ...state,
      items: [...action.payload],
      isFetched: false,
    }),
    GET_ROLES_FAILURE: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      isError: true,
      isFetched: false,
    }),
    NEW_ROLE: (state, action) => ({
      ...state,
      item: action.payload,
      isFetched: false,
    }),
    NEW_ROLE_SUCCESS: (state, action) => ({
      ...state,
      item: action.payload,
      isFetched: false,
    }),
    NEW_ROLE_FAILURE: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      isError: true,
      isFetched: false,
    }),
    EDIT_ROLE: (state, action) => ({
      ...state,
      item: action.payload,
      isFetched: false,
    }),
    EDIT_ROLE_SUCCESS: (state, action) => ({
      ...state,
      item: action.payload,
      isFetched: false,
    }),
    EDIT_ROLE_FAILURE: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      isError: true,
      isFetched: false,
    }),
    REMOVE_ROLE: (state, action) => ({
      ...state,
      item: action.payload,
      isFetched: false,
    }),
    REMOVE_ROLE_SUCCESS: (state, action) => ({
      ...state,
      item: action.payload,
      isFetched: false,
    }),
    REMOVE_ROLE_FAILURE: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      isError: true,
      isFetched: false,
    }),
  },
  {
    items: [],
    errorMessage: null,
    editableItem: null,
    addNewItemModalTitle: '',
    addNewItemModalActionButtonText: '',
    isError: false,
    isFetched: false,
    isAddNewItemModalOpen: false,
    isAddNewItemModalFetching: false,
  },
);

export default roleReducer;
