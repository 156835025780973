import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { FormHelperText } from '@mui/material';
import classnames from 'classnames';
import Option from './Option';
import Control from './Control';
import ValueContainer from './Value';
import DropdownIndicator from './DropdownIndicator';
import './AutocompleteStyle.scss';

const autocompleteComponents = {
  Option,
  Control,
  Placeholder: () => null,
  ValueContainer,
  DropdownIndicator,
  IndicatorSeparator: () => null,
};

class Autocomplete extends PureComponent {
  handleChange = (data) => {
    const { value } = data;
    const { onChange } = this.props;

    onChange(value);
  };

  getOptionLabel = (option) => option.text;

  getOptionValue = (option) => option.value;

  render() {
    const {
      value,
      label,
      error,
      options,
      classNames,
      defaultValue,
      containerClassName,
      t,
      isDisabled,
    } = this.props;

    const containerClassNames = classnames(containerClassName, {
      'autocomplete-field--disabled': isDisabled,
    });

    return (
      <div className={containerClassNames}>
        <Select
          classNamePrefix="autocomplete-field"
          isDisabled={isDisabled}
          value={value}
          label={label}
          error={error}
          options={options}
          onChange={this.handleChange}
          components={autocompleteComponents}
          className={classNames}
          defaultValue={defaultValue}
          getOptionLabel={this.getOptionLabel}
          getOptionValue={this.getOptionValue}
        />
        <FormHelperText
          error={true}
          classes={{
            root: 'autocomplete-field__helper',
            error: 'autocomplete-field__helper--error',
          }}
        >
          {t(error)}
        </FormHelperText>
      </div>
    );
  }
}

Autocomplete.propTypes = {
  value: PropTypes.object,
  label: PropTypes.string,
  error: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  classNames: PropTypes.string,
  defaultValue: PropTypes.object,
  containerClassName: PropTypes.string,
  isDisabled: PropTypes.bool,
  t: PropTypes.func,
};

export default withTranslation()(Autocomplete);
