import React, { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { find } from 'lodash/fp';
import { initialize, change, getFormValues } from 'redux-form';
import { handleNotification } from '../../../shared/components/Notification/reducer';
import { getParentItem } from '../../../shared/helpers/common';
import { getManagerOptions } from '../../../shared/helpers/select';
import { buildOrgItemTypes } from '../organizationTypesSchema';
import { buildOrgItems, getOrgItemChildren } from '../helpers';
import {
  getManager,
  getRegions,
  getGeoFieldName,
  getLocationOptions,
  getCountriesFromRegion,
  getOptionalLocationText,
  isRegion,
  isTypeFieldDisabled,
  isTeamIdFieldVisible,
  isLocationFieldVisible,
} from './helpers';
import {
  handleAddNewItemModalOpen,
  handleAddNewItemModalClose,
  handleGetOrgItemsBelonging,
  handleIsAddNewItemModalFetching,
} from '../reducer';

import ClientService from '../../../services/client';

import StaffAppLink from '../../../shared/components/StaffAppLink';
import { FORM_NAME, REGION_COUNTRIES, DEFAULT_ITEM_PROPS } from './constants';
import AddNewItemModal from './reduxForm';

export class AddNewItemModalContainer extends Component {
  componentDidMount() {
    const { editableItem, orgItemTypes } = this.props;

    if (editableItem !== null) {
      this.props.initialize(FORM_NAME, {
        name: editableItem.name,
        typeId: editableItem.typeId,
        teamId: editableItem.teamId || null,
        country: editableItem.country || null,
        region: editableItem.region || null,
        managerId: editableItem.managerId,
      });
    } else {
      this.props.initialize(FORM_NAME, {
        typeId: orgItemTypes[0].value,
      });
    }
  }

  handleModalSubmit = async (dataToSend) => {
    const { parentItemId: parentId } = this.props;

    this.props.handleIsAddNewItemModalFetching(true);

    try {
      const data = {
        ...DEFAULT_ITEM_PROPS,
        ...dataToSend,
        parentId,
      };

      const { name } = await ClientService.instance.post('/organization', data);

      this.props.handleNotification({
        message: 'successAddNewOrganizationItem',
        interpolate: {
          name,
        },
        type: 'success',
        isActive: true,
      });

      await this.props.handleGetOrgItemsBelonging();
      this.props.handleIsAddNewItemModalFetching(false);
      this.props.handleAddNewItemModalClose();
    } catch (error) {
      this.props.handleIsAddNewItemModalFetching(false);
      this.props.handleNotification({
        message: 'errorGeneralApi',
        addon: <StaffAppLink />,
        type: 'error',
        isActive: true,
      });
    }
  };

  handleModalEditSubmit = async (dataToSend, itemId, _etag) => {
    const { parentItemId: parentId, allItems } = this.props;

    const { typeId, teamId, country, region } = dataToSend;
    const originalItem = allItems.find((org) => org.id === itemId);

    const { developmentTeam, city, supportFunction, operations, team } =
      this.props.organizationItemTypeMap;

    const processedData = {
      ...DEFAULT_ITEM_PROPS,
      ...dataToSend,
      teamId: typeId === developmentTeam ? teamId : [],
      country:
        typeId === city || typeId === team || typeId === supportFunction
          ? country
          : null,
      region: typeId === operations ? region : null,
      parentId,
      _etag,
    };

    this.props.handleIsAddNewItemModalFetching(true);

    try {
      const { name } = await ClientService.instance.put(
        `/organization/${itemId}`,
        {
          ...processedData,
        },
      );

      this.props.handleNotification({
        message: 'successEditOrganizationItem',
        interpolate: {
          name,
        },
        type: 'success',
        isActive: true,
      });

      await this.props.handleGetOrgItemsBelonging();
      this.props.handleIsAddNewItemModalFetching(false);
      this.props.handleAddNewItemModalClose();
    } catch (error) {
      this.props.handleIsAddNewItemModalFetching(false);

      if (error.status === 412) {
        const { name } = originalItem;

        this.props.handleNotification({
          message: 'errorEditOrganizationItemConcurrent',
          interpolate: {
            name,
          },
          addon: <StaffAppLink />,
          type: 'error',
          isActive: true,
        });
      } else {
        this.props.handleNotification({
          message: 'errorEditOrganizationItem',
          addon: <StaffAppLink />,
          type: 'error',
          isActive: true,
        });
      }
    }
  };

  handleModalClose = () => this.props.handleAddNewItemModalClose();

  handleTypeChange = () => {
    const geoFieldName = getGeoFieldName(
      this.props.parentItem,
      this.props.organizationItemTypeMap,
    );
    this.props.change(FORM_NAME, geoFieldName, null);
  };

  handleFormatManagerValue = (value) =>
    !value ? {} : find({ value })(this.props.managerOptions);

  render() {
    const {
      typeId,
      region,
      allItems,
      modalTitle,
      parentItem,
      parentType,
      hasChildren,
      editableItem,
      orgItemTypes,
      geoFieldName,
      managerOptions,
      grandParentType,
      callToActionText,
      organizationItems,
      isModalOpen,
      isModalLoading,
      organizationItemTypeMap,
    } = this.props;

    const selectedManager = getManager(editableItem, managerOptions);
    const optionalLocationText = getOptionalLocationText(
      typeId,
      organizationItemTypeMap,
    );

    const isTypeDisabled = isTypeFieldDisabled(orgItemTypes, hasChildren);
    const isTeamIdVisible = isTeamIdFieldVisible(
      typeId,
      organizationItemTypeMap,
    );
    const isLocationVisible = isLocationFieldVisible(
      typeId,
      allItems,
      parentItem,
      editableItem,
      grandParentType,
      organizationItemTypeMap,
    );

    const locations = isRegion(geoFieldName)
      ? getRegions()
      : getCountriesFromRegion(REGION_COUNTRIES, region);

    const locationOptions =
      getLocationOptions(typeId, locations, organizationItemTypeMap) || [];

    return (
      <AddNewItemModal
        type={typeId}
        modalTitle={modalTitle}
        editableItem={editableItem}
        orgItemTypes={orgItemTypes}
        geoFieldName={geoFieldName}
        parentItemType={parentType}
        managerOptions={managerOptions}
        locationOptions={locationOptions}
        selectedManager={selectedManager}
        organizationItems={organizationItems}
        optionalLocationText={optionalLocationText}
        callToActionText={callToActionText}
        isOpen={isModalOpen}
        isModalLoading={isModalLoading}
        isTypeFieldDisabled={isTypeDisabled}
        isTeamIdFieldVisible={isTeamIdVisible}
        isLocationFieldVisible={isLocationVisible}
        onClose={this.handleModalClose}
        onModalSubmit={this.handleModalSubmit}
        onModalEditSubmit={this.handleModalEditSubmit}
        onHandleTypeChange={this.handleTypeChange}
        onFormatManagerValue={this.handleFormatManagerValue}
        organizationItemTypeMap={organizationItemTypeMap}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const {
    items: allItems,
    editableItem,
    parentItemId,
    addNewItemModalTitle,
    addNewItemModalActionButtonText,
    isAddNewItemModalFetching,
  } = state.organization;

  const { organizationUnitTypes } = state.backEndData;

  const organizationItemTypeMap = organizationUnitTypes.reduce(
    (acc, unitType) => {
      acc[unitType.id] = unitType.id;
      return acc;
    },
    {},
  );

  const formValues = getFormValues(FORM_NAME)(state);
  const typeId = formValues && formValues.typeId;
  const itemRegion = formValues && formValues.region;

  const parentItem = getParentItem(parentItemId, allItems);
  const grandParentItem = getParentItem(parentItem.parentId, allItems);

  const hasChildren = !!getOrgItemChildren(editableItem, allItems).length;
  const geoFieldName = getGeoFieldName(parentItem, organizationItemTypeMap);
  const region = itemRegion || parentItem.region || grandParentItem.region;

  const orgItemTypes = buildOrgItemTypes(
    parentItemId,
    allItems,
    organizationItemTypeMap,
  ).map(({ text, value }) => ({
    value,
    text: organizationUnitTypes.find(({ id }) => id === value)?.name || text,
  }));

  const organizationItems = buildOrgItems(allItems, parentItemId);

  const managersData = state.managers.items || [];
  const managerOptions = getManagerOptions(managersData) || [];

  return {
    typeId,
    region,
    allItems,
    modalTitle: addNewItemModalTitle,
    parentItem,
    parentType: parentItem.typeId,
    hasChildren,
    orgItemTypes,
    parentItemId,
    editableItem,
    geoFieldName,
    managerOptions,
    callToActionText: addNewItemModalActionButtonText,
    grandParentType: grandParentItem.typeId,
    organizationItems,
    isModalLoading: isAddNewItemModalFetching,
    organizationItemTypeMap,
  };
};

export default connect(mapStateToProps, {
  change,
  initialize,
  getFormValues,
  handleNotification,
  handleAddNewItemModalOpen,
  handleAddNewItemModalClose,
  handleGetOrgItemsBelonging,
  handleIsAddNewItemModalFetching,
})(AddNewItemModalContainer);

AddNewItemModalContainer.propTypes = {
  type: propTypes.string,
  change: propTypes.func.isRequired,
  region: propTypes.string,
  allItems: propTypes.array,
  locations: propTypes.array,
  initialize: propTypes.func.isRequired,
  formValues: propTypes.any,
  parentType: propTypes.string,
  parentItem: propTypes.object.isRequired,
  modalTitle: propTypes.string.isRequired,
  hasChildren: propTypes.bool.isRequired,
  parentItemId: propTypes.any,
  geoFieldName: propTypes.string,
  orgItemTypes: propTypes.array.isRequired,
  editableItem: propTypes.object,
  managerOptions: propTypes.array,
  grandParentType: propTypes.string,
  callToActionText: propTypes.string.isRequired,
  organizationItems: propTypes.array.isRequired,
  typeFieldDisabled: propTypes.bool,
  isModalOpen: propTypes.bool.isRequired,
  isModalLoading: propTypes.bool.isRequired,
  handleAddNewItemModalOpen: propTypes.func.isRequired,
  handleAddNewItemModalClose: propTypes.func.isRequired,
  handleNotification: propTypes.func.isRequired,
  handleGetOrgItemsBelonging: propTypes.func.isRequired,
  handleIsAddNewItemModalFetching: propTypes.func.isRequired,
  organizationItemTypeMap: propTypes.object.isRequired,
  typeId: propTypes.string,
};
