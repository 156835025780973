import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import AkeliusBusinessDivisionTree from '../AkeliusBusinessDivisionTree';

const AkeliusBusinessDivisionField = ({ name, ...rest }) => (
    <Field name={name} component={AkeliusBusinessDivisionTree} {...rest} />
  );

AkeliusBusinessDivisionField.propTypes = {
  name: PropTypes.string,
};

export default AkeliusBusinessDivisionField;
