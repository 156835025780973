import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import Button from '../../Button';
import './DatePickerMonths.scss';

export const DatePickerMonths = ({
  isActive,
  calendarData,
  onCalendarMonthsButtonClick,
}) => (
    isActive && (
      <div className="datepicker-months">
        <div className="datepicker-months__year">{calendarData.year}</div>
        <ul className="datepicker-months__month-list">
          {calendarData.monthsNames.map((month, idx) => (
            <li className="datepicker-months__month" key={month}>
              <Button
                className={classnames('datepicker-months__month-btn', {
                  'datepicker-months__month-btn--selected-month':
                    calendarData.monthName === month,
                  'datepicker-months__month-btn--curr-month':
                    parseInt(moment().month(), 10) === idx,
                })}
                onClick={onCalendarMonthsButtonClick}
                month={idx}
              >
                {month.substr(0, 3)}
              </Button>
            </li>
          ))}
        </ul>
      </div>
    )
  );

DatePickerMonths.propTypes = {
  isActive: propTypes.bool.isRequired,
  calendarData: propTypes.exact({
    date: propTypes.string.isRequired,
    year: propTypes.string.isRequired,
    monthName: propTypes.string.isRequired,
    monthsNames: propTypes.arrayOf(propTypes.string).isRequired,
    weekDayNames: propTypes.arrayOf(propTypes.string).isRequired,
    dates: propTypes.arrayOf(
      propTypes.exact({
        shortDate: propTypes.string.isRequired,
        fullDate: propTypes.string.isRequired,
        isChosenDate: propTypes.bool.isRequired,
        dateActuality: propTypes.string.isRequired,
      }),
    ).isRequired,
  }),
  onCalendarMonthsButtonClick: propTypes.func.isRequired,
};
