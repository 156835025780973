import MenuItem from '@mui/material/MenuItem';
import propTypes from 'prop-types';
import React from 'react';
import './DropDownMenuItem.scss';

const DropDownMenuItem = React.forwardRef((props, ref) => {
  const { onClick, isSelected, children, ...otherProps } = props;

  return (
    <MenuItem
      {...otherProps}
      ref={ref}
      onClick={onClick}
      selected={isSelected}
      classes={{
        root: 'drop-down-menu-item',
        selected: 'drop-down-menu-item--selected',
      }}
    >
      {children}
    </MenuItem>
  );
});

DropDownMenuItem.propTypes = {
  isSelected: propTypes.bool,
  onClick: propTypes.func.isRequired,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
};

export default DropDownMenuItem;
