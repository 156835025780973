import { createActions, handleActions } from 'redux-actions';
import ClientService from '../../services/client';
import { handleNotification } from '../../shared/components/Notification/reducer';
import StaffAppLink from '../../shared/components/StaffAppLink';

const GET_DEPARTMENT_ITEMS_STARTED = 'GET_DEPARTMENT_ITEMS_STARTED';
const GET_DEPARTMENT_ITEMS_SUCCESS = 'GET_DEPARTMENT_ITEMS_SUCCESS';
const GET_DEPARTMENT_ITEMS_FAILURE = 'GET_DEPARTMENT_ITEMS_FAILURE';

const GET_ORGANIZATION_ITEMS_STARTED = 'GET_ORGANIZATION_ITEMS_STARTED';
const GET_ORGANIZATION_ITEMS_SUCCESS = 'GET_ORGANIZATION_ITEMS_SUCCESS';
const GET_ORGANIZATION_ITEMS_FAILURE = 'GET_ORGANIZATION_ITEMS_FAILURE';

const SET_PARENT_ITEM = 'SET_PARENT_ITEM';
const SET_BREAD_CRUMBS = 'SET_BREAD_CRUMBS';

const GET_DEPARTMENT_ITEM_STARTED = 'GET_DEPARTMENT_ITEM_STARTED';
const GET_DEPARTMENT_ITEM_SUCCESS = 'GET_DEPARTMENT_ITEM_SUCCESS';
const GET_DEPARTMENT_ITEM_FAILURE = 'GET_DEPARTMENT_ITEM_FAILURE';

const SHOW_ADD_NEW_ITEM_MODAL = 'SHOW_ADD_NEW_ITEM_MODAL';
const HIDE_ADD_NEW_ITEM_MODAL = 'HIDE_ADD_NEW_ITEM_MODAL';
const IS_ADD_NEW_ITEM_MODAL_FETCHING = 'IS_ADD_NEW_ITEM_MODAL_FETCHING';

const DELETE_DEPARTMENT_STARTED = 'DELETE_DEPARTMENT_STARTED';
const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS';
const DELETE_DEPARTMENT_FAILURE = 'DELETE_DEPARTMENT_FAILURE';

const TOGGLE_DELETE_ITEM_MODAL = 'TOGGLE_DELETE_ITEM_MODAL';
const TOGGLE_LIST_VIEW = 'TOGGLE_LIST_VIEW';


const actionCreators = createActions(
  SET_PARENT_ITEM,
  SET_BREAD_CRUMBS,
  GET_DEPARTMENT_ITEMS_STARTED,
  GET_DEPARTMENT_ITEMS_SUCCESS,
  GET_DEPARTMENT_ITEMS_FAILURE,
  GET_ORGANIZATION_ITEMS_STARTED,
  GET_ORGANIZATION_ITEMS_SUCCESS,
  GET_ORGANIZATION_ITEMS_FAILURE,
  GET_DEPARTMENT_ITEM_STARTED,
  GET_DEPARTMENT_ITEM_SUCCESS,
  GET_DEPARTMENT_ITEM_FAILURE,
  SHOW_ADD_NEW_ITEM_MODAL,
  HIDE_ADD_NEW_ITEM_MODAL,
  IS_ADD_NEW_ITEM_MODAL_FETCHING,
  DELETE_DEPARTMENT_STARTED,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_FAILURE,
  TOGGLE_DELETE_ITEM_MODAL,
  TOGGLE_LIST_VIEW
);

const {
  setParentItem,
  getDepartmentItemsStarted,
  getDepartmentItemsSuccess,
  getDepartmentItemsFailure,
  getDepartmentItemSuccess,
  getDepartmentItemFailure,
  getOrganizationItemsStarted,
  getOrganizationItemsSuccess,
  getOrganizationItemsFailure,
  showAddNewItemModal,
  hideAddNewItemModal,
  isAddNewItemModalFetching,
  deleteDepartmentStarted,
  deleteDepartmentSuccess,
  deleteDepartmentFailure,
  toggleDeleteItemModal,
  toggleListView
} = actionCreators;

export function handleToggleView() {
  return (dispatch) => {
    dispatch(toggleListView());
  };
}

export function handleToggleDeleteItemModal(item) {
  return (dispatch) => {
    dispatch(toggleDeleteItemModal(item));
  };
}

export function handleSetParentItem(id) {
  return (dispatch) => {
    dispatch(setParentItem(id));
  };
}

export function handleGetOrgItems() {
  return (dispatch) => {
    dispatch(getDepartmentItemsStarted());
    return ClientService.instance
      .fetch('/organization')
      .then((response) => {
        dispatch(getDepartmentItemsSuccess(response));
      })
      .catch((error) => {
        dispatch(getDepartmentItemsFailure(error.message));
      });
  };
}

export function handleGetDepartmentItems() {
  return (dispatch) => {
    dispatch(getDepartmentItemsStarted());
    return ClientService.instance
      .fetch('/department')
      .then((response) => {
        dispatch(getDepartmentItemsSuccess(response));
      })
      .catch((error) => {
        dispatch(getDepartmentItemsFailure(error.message));
      });
  };
}

export function handleGetOrgItemsBelonging() {
  return (dispatch) => {
    dispatch(getOrganizationItemsStarted());
    return ClientService.instance
      .fetch('/organization?belonging=true')
      .then((response) => {
        dispatch(getOrganizationItemsSuccess(response));
      })
      .catch((error) => {
        dispatch(getOrganizationItemsFailure(error.message));
      });
  };
}

export function handleAddNewItemModalOpen(config) {
  if (config.addNewItemModalTitle === 'editItem') {
    return (dispatch) => {
      const item = config.departmentItems.find(i => i.id === config.editItemId);
      if (item) {
        dispatch(getDepartmentItemSuccess(item));
        dispatch(showAddNewItemModal(config));
      } else {
        dispatch(getDepartmentItemFailure(`No item found for ${config.editItemId}`));
      }
    };
  }
  return (dispatch) => {
    dispatch(showAddNewItemModal(config));
  };
}
export function handleAddNewItemModalClose() {
  return (dispatch) => {
    dispatch(hideAddNewItemModal());
  };
}

export function handleIsAddNewItemModalFetching(status) {
  return (dispatch) => {
    dispatch(isAddNewItemModalFetching(status));
  };
}

export function handleDeleteDepartmentItem(item) {
  return (dispatch) => {
    dispatch(deleteDepartmentStarted());
    return ClientService.instance
      .delete(`/department/${item.id}`)
      .then((response) => {
        dispatch(deleteDepartmentSuccess(response));
        dispatch(handleNotification({
          message: 'departmentItemDeleteSuccessMessage',
          interpolate: {
            name: item.name,
          },
          type: 'success',
          isActive: true,
        }));
        // dispatch(handleToggleDeleteItemModal({}));
        dispatch(deleteDepartmentSuccess({id: item.id}));
        dispatch(handleGetDepartmentItems());
        dispatch(handleGetOrgItemsBelonging());
      })
      .catch((error) => {
        dispatch(deleteDepartmentFailure(error.message));
        let errorMessage;
        if (error.status === 410) {
          errorMessage = 'departmentItemNotFoundErrorMessage';
        } else if (error.status === 422) {
          errorMessage = 'departmentItemDeleteErrorMessage';
        } else {
          errorMessage = 'departmentErrorMessage';
        }

        // dispatch(handleToggleDeleteItemModal({}));
        dispatch(handleNotification({
          message: errorMessage,
          addon: <StaffAppLink />,
          type: 'error',
          isActive: true,
        }));
      });
  };
}

const departmentReducer = handleActions(
  {
    SET_PARENT_ITEM: (state, action) => ({
      ...state,
      items: state.items,
      parentItemId: action.payload,
    }),
    GET_DEPARTMENT_ITEMS_STARTED: (state) => ({
      ...state,
      isFetched: true,
    }),
    GET_DEPARTMENT_ITEMS_SUCCESS: (state, action) => ({
      ...state,
      items: [...action.payload],
      isFetched: false,
    }),
    GET_DEPARTMENT_ITEMS_FAILURE: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      isError: true,
      isFetched: false,
    }),
    GET_ORGANIZATION_ITEMS_STARTED: (state) => ({
      ...state,
      isFetched: true,
    }),
    GET_ORGANIZATION_ITEMS_SUCCESS: (state, action) => ({
      ...state,
      organizationItems: [...action.payload],
      isFetched: false,
    }),
    GET_ORGANIZATION_ITEMS_FAILURE: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      isError: true,
      isFetched: false,
    }),
    GET_DEPARTMENT_ITEM_STARTED: (state) => ({
      ...state,
    }),
    GET_DEPARTMENT_ITEM_SUCCESS: (state, action) => ({
      ...state,
      editableItem: action.payload,
    }),
    GET_DEPARTMENT_ITEM_FAILURE: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      isError: true,
    }),
    SHOW_ADD_NEW_ITEM_MODAL: (state, action) => ({
      ...state,
      isAddNewItemModalOpen: true,
      addNewItemModalTitle: action.payload.addNewItemModalTitle,
      addNewItemModalActionButtonText:
        action.payload.addNewItemModalActionButtonText,
    }),
    HIDE_ADD_NEW_ITEM_MODAL: (state) => ({
      ...state,
      isAddNewItemModalOpen: false,
      addNewItemModalTitle: '',
      addNewItemModalActionButtonText: '',
      editableItem: null,
    }),
    IS_ADD_NEW_ITEM_MODAL_FETCHING: (state, action) => ({
      ...state,
      isAddNewItemModalFetching: action.payload,
    }),
    DELETE_DEPARTMENT_STARTED: (state) => ({
      ...state,
      itemsAreLoading: true
    }),
    DELETE_DEPARTMENT_SUCCESS: (state, action) => ({
      ...state,
      items: state.items.filter(item => item.id !== action.payload.id),
      itemsAreLoading: false,
      isDeletionModalOpen: false
    }),
    DELETE_DEPARTMENT_FAILURE: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      isError: true,
      itemsAreLoading: false,
      isDeletionModalOpen: false
    }),
    TOGGLE_DELETE_ITEM_MODAL: (state, action) => ({
      ...state,
      isDeletionModalOpen: action.payload.isDeletionModalOpen,
      deleteItemModalTitle: action.payload.deleteItemModalTitle,
      deleteItemModalActionBodyText:
        action.payload.deleteItemModalActionBodyText,
      deleteItemModalItem: action.payload.deleteItemModalItem,
    }),
    TOGGLE_LIST_VIEW: (state) => ({
      ...state,
      listView: !state.listView,
    }),
  },
  {
    items: [],
    organizationItems: [],
    errorMessage: null,
    editableItem: null,
    addNewItemModalTitle: '',
    addNewItemModalActionButtonText: '',
    isError: false,
    isFetched: false,
    itemsAreLoading: false,
    isAddNewItemModalOpen: false,
    isAddNewItemModalFetching: false,
    deleteItemModalTitle: '',
    deleteItemModalActionBodyText: '',
    isDeletionModalOpen: false,
    deleteItemModalItem: null,
    listView: true
  },
);

export default departmentReducer;
