import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  change as changeConnect,
  destroy as destroyConnect,
  initialize,
  getFormValues,
} from 'redux-form';
import { get } from 'lodash/fp';
import {
  useParams
} from "react-router-dom";
import {
  handleStaffProfile as handleStaffProfileConnect,
  handleStaffProfileUpdate as handleStaffProfileUpdateConnect,
  flushEmployee as flushEmployeeConnect,
} from '../reducer';

import {
  getUserId,
  formatPayload,
  handlePanelEditModeToggle,
} from '../helpers';
import { handleGetOrgItems as handleGetOrgItemsConnect } from '../../../Organization/reducer';
import {
  getEmployeeCountryOptions,
  getCountryOptions,
} from '../../../../shared/helpers/select';
import { handleNotification as handleNotificationConnect } from '../../../../shared/components/Notification/reducer';
import StaffProfile, { FORM_NAME } from '../reduxForm';
import { getManager, getOrganization } from '../../../../shared/helpers/common';
import { formatOrgItems } from '../../../Organization/OrganizationItem/helpers';
import { getCountries } from '../../../../shared/utils/languageUtils';

const StaffProfileContainer = (props) => {
  const {
    t,
    employee,
    isLoading,
    isSubmitting,
    organizations,
    employeeCountryOptions,
    companyNameOptions,
    rolesOptions,
    formValues,
    authUserId,
    match,
    countryOptions,
    initialFormValues,
    permissionsAuth,
    handleStaffProfileUpdate,
    handleNotification,
    destroy,
    change,
    flushEmployee,
    handleGetOrgItems,
    handleStaffProfile,
    preferredLanguagesOptions,
  } = props;

  const [currentPanelName, setCurrentPanelName] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationModalFieldName, setConfirmationModalFieldName] =
    useState('');
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');

  const {id: userId} = useParams();

   const handlePanelName = (panelName) => {
    change(FORM_NAME, 'panelName', panelName);
  };

  const handleToggleCurrentPanelName = (panelName) => {
    setCurrentPanelName(panelName);
  };


  const getCurrentStaffMember = useCallback(async () => {
    const id = getUserId(userId, authUserId);
    await handleGetOrgItems();
    await handleStaffProfile(id);
  }, [
    handleGetOrgItems,
    handleStaffProfile,
    match,
    authUserId,
  ]);
  const handleFormSubmit = async (values) => {
    const data = formatPayload(values);
    let updatedProfileData = null;
    try {
      updatedProfileData = await handleStaffProfileUpdate(employee.id, data);
      if (updatedProfileData) {
        await getCurrentStaffMember();
        await handleNotification({
          message: 'updateEmployeeSuccess',
          type: 'success',
          isActive: true,
        });

        destroy(FORM_NAME);
      }
    } catch (e) {
      await handleNotification({
        message: 'errorGeneralApi',
        type: 'error',
        isActive: true,
      });
    }
    return updatedProfileData;
  };

  const handleConfirmationModalClose = () => {
    setIsConfirmationModalOpen(false);
    change(
      FORM_NAME,
      confirmationModalFieldName,
      employee[confirmationModalFieldName],
    );
  };

  const handleConfirmationModalSubmit = () => {
    if (confirmationModalFieldName === 'companyCountry') {
      setIsConfirmationModalOpen(false);
    }
  };

  const handleConfirmationModalOpen = ({ target }) => {
    setIsConfirmationModalOpen(true);
    setConfirmationModalFieldName(target.name);

    if (target.name === 'companyCountry') {
      setConfirmationModalMessage(
        t('warningCompanyCountryChangeText', {
          country: t(target.value),
        }),
      );
    }
  };

  useEffect(() => {
    getCurrentStaffMember();
    window.scrollTo(0, 0);
    return () => flushEmployee();
  }, [flushEmployee, getCurrentStaffMember]);

  return (
    <StaffProfile
      t={t}
      employee={employee}
      isSubmitting={isSubmitting}
      isLoading={isLoading}
      onFormSubmit={handleFormSubmit}
      organizations={organizations}
      onAddFieldModalUpdate={handleStaffProfileUpdate}
      onPanelEditModeToggle={handlePanelEditModeToggle(props)}
      employeeCountryOptions={employeeCountryOptions}
      companyNameOptions={companyNameOptions}
      rolesOptions={rolesOptions}
      formValues={formValues}
      confirmationModalMessage={confirmationModalMessage}
      isConfirmationModalOpen={isConfirmationModalOpen}
      onConfirmationModalOpen={handleConfirmationModalOpen}
      onConfirmationModalClose={handleConfirmationModalClose}
      countryOptions={countryOptions}
      onPanelSave={handlePanelName}
      currentPanelName={currentPanelName}
      onToggleCurrentPanelName={handleToggleCurrentPanelName}
      onConfirmationModalSubmit={handleConfirmationModalSubmit}
      getCurrentStaffMember={getCurrentStaffMember}
      initialFormValues={initialFormValues}
      permissionsAuth={permissionsAuth}
      preferredLanguagesOptions={preferredLanguagesOptions}
    />
  );
};

StaffProfileContainer.propTypes = {
  t: PropTypes.func.isRequired,
  authUserId: PropTypes.string.isRequired,
  match: PropTypes.any,
  destroy: PropTypes.func.isRequired,
  employee: PropTypes.any,
  initialize: PropTypes.func.isRequired,
  organizations: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  employeeCountryOptions: PropTypes.array.isRequired,
  companyNameOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  rolesOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleGetOrgItems: PropTypes.func.isRequired,
  handleStaffProfile: PropTypes.func.isRequired,
  handleNotification: PropTypes.func.isRequired,
  handleStaffProfileUpdate: PropTypes.func.isRequired,
  flushEmployee: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  countryOptions: PropTypes.array.isRequired,
  initialFormValues: PropTypes.object.isRequired,
  permissionsAuth: PropTypes.object.isRequired,
  preferredLanguagesOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function mapStateToProps(state) {
  const employee = get('staffProfile.employee')(state);
  const businessDivisionId = get('businessDivisionId')(employee);
  const isLoading = get('staffProfile.isLoading')(state);
  const isSubmitting = get(`form.${FORM_NAME}.submitting`)(state) || false;
  const organizationItems = get('organization.items')(state);
  const organization = getOrganization(businessDivisionId, organizationItems);

  const employeeCountryOptions = getEmployeeCountryOptions(getCountries());
  const companyNameOptions = state.backEndData?.companies;

  const rolesOptions = state.backEndData?.roles;

  const formValues = getFormValues(FORM_NAME)(state) || {};

  const updatedBusinessDivision = formValues.businessDivisionId;

  const orgType = get('type')(employee);
  const manager = getManager(organization, organizationItems, employee.id);

  return {
    authUserId: get('auth.id')(state),
    employee: {
      ...employee,
      manager: get('name')(manager) || '',
      managerId: get('managerId')(manager) || '',
      orgType: orgType && orgType !== 'root' ? orgType : '',
      businessDivisionName: get('name')(organization) || '',
      businessDivisionId: updatedBusinessDivision || businessDivisionId,
    },
    isLoading,
    isSubmitting,
    organizations: formatOrgItems(organizationItems),
    employeeCountryOptions,
    companyNameOptions,
    rolesOptions,
    formValues,
    countryOptions: getCountryOptions(),
    initialFormValues: get(`form.${FORM_NAME}.initial`)(state) || {},
    permissionsAuth: get('auth')(state),
    preferredLanguagesOptions: state.jiraEnumerations.preferredLanguages,
  };
}

export default connect(mapStateToProps, {
  change: changeConnect,
  destroy: destroyConnect,
  initialize,
  handleStaffProfile: handleStaffProfileConnect,
  handleGetOrgItems: handleGetOrgItemsConnect,
  handleNotification: handleNotificationConnect,
  handleStaffProfileUpdate: handleStaffProfileUpdateConnect,
  flushEmployee: flushEmployeeConnect,
})(withTranslation()(StaffProfileContainer));
