import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import './Icon.scss';

const Icon = (props) => {
  const { color, className, icon, width, height, rotate, ...rest } = props;

  const classNames = classnames('icon', className, {
    'icon--rotate-right': rotate === 'right',
    'icon--rotate-bottom': rotate === 'bottom',
    'icon--rotate-left': rotate === 'left',
  });

  return (
    <svg
      className={classNames}
      fill={color}
      width={width}
      height={height}
      {...rest}
    >
      <use xlinkHref={`${'/svg_sprite.svg'}#${icon}`} />
    </svg>
  );
};

Icon.defaultProps = {
  width: 24,
  height: 24,
  className: '',
  color: '#000000',
};

Icon.propTypes = {
  icon: propTypes.string.isRequired,
  width: propTypes.number,
  height: propTypes.number,
  color: propTypes.string,
  className: propTypes.string,
  rotate: propTypes.string,
};

export default Icon;
