import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { isEmpty } from 'lodash/fp';
import IconButton from '../IconButton';
import { handleNotification } from './reducer';
import './Notification.scss';
import Icon from '../Icon';

const TIME_TO_DISAPPEAR = 3000;

class Notification extends Component {
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.props.handleNotification({
      message: '',
      type: this.props.type,
      isActive: false,
    });
  };

  render() {
    const { message, type, isActive, interpolate, addon, t } = this.props;

    const translatedMessage = isEmpty(interpolate)
      ? t(message)
      : t(message, { ...interpolate });

    const contentClass = classnames(
      'snackbar__content',
      `snackbar__content--${type}`,
    );
    const iconClass = classnames('snackbar__icon', `snackbar__icon--${type}`);

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isActive}
        autoHideDuration={type === 'success' ? TIME_TO_DISAPPEAR : null}
        onClose={this.handleClose}
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          message={
            <span className="snackbar__message">
              {type === 'error' ? (
                <Icon icon="ak-error" className={iconClass} />
              ) : (
                <Icon icon="ak-success" className={iconClass} />
              )}
              <span>
                {translatedMessage}
                {addon}
              </span>
            </span>
          }
          className={contentClass}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className="snackbar__button"
              onClick={this.handleClose}
            >
              <Icon icon="ak-close" className="snackbar__close-icon" />
            </IconButton>,
          ]}
        />
      </Snackbar>
    );
  }
}

Notification.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  handleNotification: PropTypes.func,
  interpolate: PropTypes.object,
  addon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  handleNotification,
};

const mapStateToProps = (state) => ({
  type: state.notification.type,
  message: state.notification.message,
  isActive: state.notification.isActive,
  interpolate: state.notification.interpolate,
  addon: state.notification.addon,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Notification));
