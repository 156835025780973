import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Spinner.scss';

const Spinner = ({
  children = null,
  isLoading,
  size,
  isCoveringPage,
  isCentered,
}) => {
  if (!isLoading) {
    return children;
  }

  return (
    <div
      className={classnames('ak-spinner', {
        'ak-spinner--cover-page': isCoveringPage,
      })}
    >
      <CircularProgress
        classes={{
          root: classnames('ak-spinner__body', {
            'ak-spinner__body--cover-page': isCoveringPage,
            'ak-spinner__body--is-centered': isCentered,
          }),
          svg: 'ak-spinner__svg',
          circle: 'ak-spinner__circle',
        }}
        size={size}
      />
      {children}
    </div>
  );
};

Spinner.defaultProps = {
  size: 100,
  children: null,
};

Spinner.propTypes = {
  size: PropTypes.number,
  isLoading: PropTypes.bool,
  isCoveringPage: PropTypes.bool,
  isCentered: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Spinner;
