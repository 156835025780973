import axios from 'axios';
import ApiError from './apiError';
import Auth0Client from '../shared/helpers/Auth0Client';

export default class ClientService {
  static init({ headersConfig, backendUrl, auth0ClientConfig }) {
    return new ClientService({
      initializedAuth0Client: Auth0Client.create(auth0ClientConfig),
      headersConfig,
      backendUrl,
    });
  }

  static instance = null;

  constructor({ headersConfig, backendUrl, initializedAuth0Client }) {
    if (!ClientService.instance) {
      ClientService.instance = this;
      this.initializedAuth0Client = initializedAuth0Client;
      this.headersConfig = headersConfig;
      this.backendUrl = backendUrl;
    }

    return ClientService.instance;
  }

  post(path, data) {
    return this.request('POST', path, data);
  }

  put(path, data) {
    return this.request('PUT', path, data);
  }

  patch(path, data) {
    return this.request('PATCH', path, data);
  }

  fetch(path) {
    return this.request('GET', path);
  }

  delete(path) {
    return this.request('DELETE', path);
  }

  async multipartPost(path, formData) {
    const url = `${this.backendUrl}${path}`;
    const { auth0Client } = await this.initializedAuth0Client;
    const token = await auth0Client.getTokenSilently();
    const claims = await auth0Client.getIdTokenClaims();
    // eslint-disable-next-line no-underscore-dangle
    const idToken = claims?.__raw || '';

    return axios.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-akelius-id-token': idToken,
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async multipartDelete(path) {
    const url = `${this.backendUrl}${path}`;
    const { auth0Client } = await this.initializedAuth0Client;
    const token = await auth0Client.getTokenSilently();
    const claims = await auth0Client.getIdTokenClaims();
    // eslint-disable-next-line no-underscore-dangle
    const idToken = claims?.__raw || '';

    return axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-akelius-id-token': idToken,
        'Content-Type': 'application/json',
      },
    });
  }

  get headers() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Strict-Transport-Security': 'max-age=15724800;includeSubdomains',
    };
  }

  async request(method, path, data) {
    const { auth0Client } = await this.initializedAuth0Client;
    const token = await auth0Client.getTokenSilently();
    const url = `${this.backendUrl}${path}`;
    const options = {
      method,
      body: JSON.stringify(data),
      headers: {
        ...this.headers,
        Authorization: `Bearer ${token}`,
      },
    };

    return fetch(url, options).then((res) => {
      if (res.headers.get('content-type').includes('application/json')) {
        return res.json().then((result) => {
          if (res.status >= 400) {
            throw new ApiError({
              status: res.status,
              message: JSON.stringify(result.error),
            });
          }

          return result;
        });
      }

      throw new ApiError({
        message: `Got html response from resource ${url}`,
        status: res.status,
      });
    });
  }
}
