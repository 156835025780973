import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import TextInputField from '../../../shared/components/TextInput';
import IconButtonWithToolTip from '../../../shared/components/IconButtonWithToolTip';
import './StaffCatalogSearchFilters.scss';
import AkeliusBusinessDivision from '../../../shared/components/AkeliusBusinessDivision';
import { STAFF_CATALOG_FILTERS_FORM_NAME } from './helpers';
import Button from '../../../shared/components/Button';

class StaffCatalogSearchFilters extends Component {
  clearBusinessDivisionField = () => {
    this.props.onClearFormField({
      name: 'businessDivisionId',
    });
  };

  render() {
    const { t, isFiltersBlockOpen, onToggleFilters, divisionId } = this.props;

    return (
      <div className="staff-catalog-filters">
        <div className="staff-catalog-filters__search">
          <TextInputField
            name="name"
            label={t('name')}
            placeholder={t('name')}
            fullWidth
            searchField={true}
            autoSearch={true}
          />

          <IconButtonWithToolTip
            title={t('filterList')}
            icon="ak-filter-list"
            buttonClasses={classnames(
              'staff-catalog-filters__filter-control-btn',
              {
                'staff-catalog-filters__filter-control-btn--active': isFiltersBlockOpen,
              },
            )}
            iconClasses={classnames(
              'staff-catalog-filters__filter-control-icn',
              {
                'staff-catalog-filters__filter-control-icn--active': isFiltersBlockOpen,
              },
            )}
            onClick={onToggleFilters}
          />
        </div>
        {isFiltersBlockOpen && (
          <div className="staff-catalog-filters__filters-block">
            <div className="staff-catalog-filters__filter-criteria">
              <div className="staff-catalog-filters__business-division">
                <AkeliusBusinessDivision
                  name="businessDivisionId"
                  formName={STAFF_CATALOG_FILTERS_FORM_NAME}
                />
              </div>
            </div>
            {divisionId && (
              <div className="staff-catalog-filters__filters-clear">
                <Button secondary onClick={this.clearBusinessDivisionField}>
                  {t('clear')}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

StaffCatalogSearchFilters.propTypes = {
  t: PropTypes.func.isRequired,
  onToggleFilters: PropTypes.func.isRequired,
  isFiltersBlockOpen: PropTypes.bool.isRequired,
  organizationItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  divisionId: PropTypes.string.isRequired,
  onClearFormField: PropTypes.func.isRequired,
};

export default StaffCatalogSearchFilters;
