import isRootItem from './isRootItem';
import getItemBranch from './getItemBranch';

function getTransformedOrganizationItems({
  organizationItems = [],
  selectedBusinessDivisionId = '',
  openedOrganizationItems = [],
}) {
  if (!organizationItems.length) {
    return [];
  }
  const result = [];
  for (let i = 0; i < organizationItems.length; i += 1) {
    const item = organizationItems[i];
    if (isRootItem(item)) {
      result.push(
        getItemBranch(
          organizationItems,
          item,
          selectedBusinessDivisionId,
          openedOrganizationItems,
        ),
      );
    }
  }
  return result;
}

export default getTransformedOrganizationItems;
