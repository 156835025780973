import React from 'react';
import Proptypes from 'prop-types';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import { withTranslation } from 'react-i18next';
import DialogTitle from './DialogTitle';
import Spinner from '../Spinner';
import Button from '../Button';
import './Modal.scss';

class Modal extends React.Component {
  static propTypes = {
    onSubmit: Proptypes.func.isRequired,
    onClose: Proptypes.func.isRequired,
    children: Proptypes.any.isRequired,
    title: Proptypes.string.isRequired,
    open: Proptypes.bool.isRequired,
    classNames: Proptypes.string,
    callToActionColor: Proptypes.string,
    callToActionText: Proptypes.string.isRequired,
    callToActionCancel: Proptypes.string,
    isModalLoading: Proptypes.bool.isRequired,
    t: Proptypes.func.isRequired,
    tReady: Proptypes.bool,
    isDisabledCallToAction: Proptypes.bool,
  };

  render() {
    const {
      title,
      open,
      children,
      onClose,
      onSubmit,
      callToActionText,
      callToActionColor,
      callToActionCancel,
      isModalLoading,
      tReady,
      t,
      classNames,
      isDisabledCallToAction,
      ...props
    } = this.props;

    return (
      <Dialog
        tabIndex="-1"
        onClose={onClose}
        className={'modal-root'}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          classes: { root: `paper__width  ${classNames}` },
        }}
        {...props}
      >
        <Spinner isLoading={isModalLoading} isCentered={true}>
          <DialogTitle id="customized-dialog-title" onClose={onClose}>
            {title}
          </DialogTitle>
          <DialogContent className="customized-dialog-content">
            {children}
          </DialogContent>
          <DialogActions className={'modal-actions'}>
            <Button onClick={onClose} secondary>
              {t(callToActionCancel || 'cancel')}
            </Button>
            <Button
              onClick={onSubmit}
              primary={callToActionColor !== 'danger'}
              danger={callToActionColor === 'danger'}
              disabled={isDisabledCallToAction}
            >
              {callToActionText}
            </Button>
          </DialogActions>
        </Spinner>
      </Dialog>
    );
  }
}

Modal.defaultProps = {
  isModalLoading: false,
};

export default withTranslation()(Modal);
