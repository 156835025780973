import React, { Component } from 'react';
import propTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Modal from '../../../shared/components/Modal';
import TextInputField from '../../../shared/components/TextInput';
import AutocompleteField from '../../../shared/components/Autocomplete';

import './AddNewItemModal.scss';

class AddNewItemModal extends Component {
  render() {
    const {
      t,
      modalTitle,
      handleSubmit,
      callToActionText,
      isOpen,
      isModalLoading,
      onClose,
      onFormatOrganizationValue,
      organizationOptions,
      selectedOrganization
    } = this.props;
 
     return (
      <div className="add-new-item">
        <Modal
          title={t(modalTitle)}
          open={isOpen}
          callToActionText={t(callToActionText)}
          isModalLoading={isModalLoading}
          onClose={onClose}
          onSubmit={handleSubmit}
        >
          <TextInputField
            fullWidth
            name="name"
            label={`${t('name')} *`}
            autoFocus={true}
            placeholder={`${t('name')} *`}
          />
          <AutocompleteField
            name="rootId"
            label={t('organization')}
            format={onFormatOrganizationValue}
            options={organizationOptions}
            classNames="add-new-item__autocomplete"
            defaultValue={selectedOrganization?.value}
          />
          <p className="add-new-item__required-message">
            {t('requiredFieldsMessage')}
          </p>
        </Modal>
      </div>
    );
  }
}

AddNewItemModal.propTypes = {
  t: propTypes.func.isRequired,
  modalTitle: propTypes.string.isRequired,
  handleSubmit: propTypes.func.isRequired,
  callToActionText: propTypes.string.isRequired,
  isOpen: propTypes.bool.isRequired,
  isModalLoading: propTypes.bool,
  onClose: propTypes.func.isRequired,
  onFormatOrganizationValue: propTypes.func.isRequired,
  organizationOptions: propTypes.array,
  selectedOrganization: propTypes.object
};

export default withTranslation()(AddNewItemModal);
