import { ApplicationInsights } from '@microsoft/applicationinsights-web';

class AppInsightsService {
  static async init(instrumentationKey) {
    return new AppInsightsService(instrumentationKey);
  }

  constructor(instrumentationKey) {
    if (!AppInsightsService.instance) {
      AppInsightsService.instance = this;
    }
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
      },
    });
    this.appInsights.loadAppInsights();
    return AppInsightsService.instance;
  }

  trackException(errorObject) {
    this.appInsights.trackException({ error: errorObject });
  }

  trackTrace(errorMessage) {
    this.appInsights.trackTrace({ message: errorMessage });
  }
}

export default AppInsightsService;
