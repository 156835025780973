import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LngDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';

export const fallbackLanguageKey = 'en-GB';

export const LanguageMap = {
  'en-GB': 'english',
  'de-DE': 'german',
  'sv-SE': 'swedish',
  'fr-FR': 'french',
  'da-DK': 'danish',
};

export const LanguageKeys = Object.keys(LanguageMap);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LngDetector)
  .use(Backend)
  .init({
    lng: localStorage.getItem('i18nextLng') || fallbackLanguageKey,
    fallbackLng: fallbackLanguageKey,
    preload: LanguageKeys,
    debug: false,
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: `/translations.{{lng}}.json?version=${new Date().getTime()}`,

      jsonIndent: 2,
    },

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },

    wait: true,
  });

const t = i18n.t.bind(i18n);

// eslint-disable-next-line no-restricted-exports
export { i18n as default, t };
