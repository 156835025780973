import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PageHead from '../../shared/components/PageHead';
import AkeliusTable from '../../shared/components/AkeliusTable';
import { mappedCategories, mappedRoles, roleTableColumns } from './helper';
import Button from '../../shared/components/Button';
import Icon from '../../shared/components/Icon';
import Modal from '../../shared/components/Modal';
import TextInputField from '../../shared/components/TextInput';
import Grid from '../../shared/components/Grid';
import Access, { Permissions } from '../../shared/components/Access';
import { maxLength100 } from '../../shared/helpers/validation';
import SelectField from '../../shared/components/Select';

const Role = (props) => {
  const {
    t,
    allRoles,
    submit,
    isModalOpen,
    setIsModalOpen,
    isDeletionModalOpen,
    setDeletionModalOpen,
    onsubmitRemove,
    setDeletionRowId,
    initialize,
  } = props;

  const categoriesOptions = mappedCategories();

  const [titleModal, setTitleModal] = useState('');
  const [bodyDeletionModal, setBodyDeletionModal] = useState(null);

  const closeDeletionModal = () => setDeletionModalOpen(false);
  const closeModal = () => setIsModalOpen(false);

  const openModal = (row) => {
    setIsModalOpen(true);
    if (row.id) {
      setTitleModal('edit role');
      initialize({
        title: 'edit role',
        id: row.id,
        name: row.name,
        category: row.category,
      });
    } else {
      setTitleModal('add new role');
      initialize({
        title: 'add new role',
        id: undefined,
        name: null,
        category: null,
      });
    }
  };

  const openDeletionModal = (row) => {
    setDeletionModalOpen(true);
    setDeletionRowId(row.id);
    if (row.id) {
      setTitleModal(`delete role`);
      setBodyDeletionModal(
        `are you sure you want to delete role ${row.name}  ?`,
      );
    }
  };

  function onEditRole(row) {
    openModal(row);
  }

  function onDeleteRole(row) {
    openDeletionModal(row);
  }

  return (
    <>
      <PageHead text="roles" />
      <Access rule={Permissions.CreateRole}>
        {(permitted) =>
          permitted ? (
            <div>
              <Button
                onClick={openModal}
                primary
                className="off-board-table__off-board-btn"
              >
                <Icon icon="ak-plus" className="off-board-table__add-icon" />
                new
              </Button>
            </div>
          ) : (
            <div> </div>
          )
        }
      </Access>
      <AkeliusTable
        id={'role-table'}
        className={'role-table-class'}
        rows={mappedRoles(allRoles, onEditRole, onDeleteRole)}
        columns={roleTableColumns()}
      />

      <Modal
        title={titleModal}
        open={isModalOpen}
        onClose={closeModal}
        onSubmit={submit}
        callToActionText={titleModal}
        classNames="off-board-form"
        t={t}
      >
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <TextInputField
              name="name"
              label={`role name`}
              validate={[maxLength100]}
              placeholder={`enter the role name`}
              fullWidth
            />
            <SelectField
              name="category"
              label={'category'}
              options={categoriesOptions}
              placeholder={'select the category'}
            />
          </Grid>
        </Grid>
      </Modal>

      <Modal
        title={titleModal}
        open={isDeletionModalOpen}
        onClose={closeDeletionModal}
        onSubmit={onsubmitRemove}
        callToActionText={titleModal}
        classNames="off-board-form"
        t={t}
      >
        <Grid container spacing={0}>
          {bodyDeletionModal}
        </Grid>
      </Modal>
    </>
  );
};

Role.propTypes = {
  allRoles: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired,
  onsubmitRemove: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setDeletionModalOpen: PropTypes.func.isRequired,
  setDeletionRowId: PropTypes.func.isRequired,
  isDeletionModalOpen: PropTypes.bool.isRequired,
  initialize: PropTypes.func.isRequired,
};

export { Role };
