import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import moment from 'moment';
import Button from '../../Button';
import './DatePickerYears.scss';

export const DatePickerYears = ({
  isActive,
  onCalendarYearsButtonClick,
  yearsRangeValues,
  calendarData,
}) => (
    isActive && (
      <div className="datepicker-years">
        <ul className="datepicker-years__year-list">
          {yearsRangeValues.map((year) => (
            <li className="datepicker-years__year" key={year}>
              <Button
                className={classnames('datepicker-years__year-btn', {
                  'datepicker-years__year-btn--selected-year':
                    parseInt(calendarData.year, 10) === year,
                  'datepicker-years__year-btn--curr-year':
                    parseInt(moment().year(), 10) === year,
                })}
                onClick={onCalendarYearsButtonClick}
                year={year}
              >
                {year}
              </Button>
            </li>
          ))}
        </ul>
      </div>
    )
  );

DatePickerYears.propTypes = {
  isActive: propTypes.bool.isRequired,
  calendarData: propTypes.exact({
    date: propTypes.string.isRequired,
    year: propTypes.string.isRequired,
    monthName: propTypes.string.isRequired,
    monthsNames: propTypes.arrayOf(propTypes.string).isRequired,
    weekDayNames: propTypes.arrayOf(propTypes.string).isRequired,
    dates: propTypes.arrayOf(
      propTypes.exact({
        shortDate: propTypes.string.isRequired,
        fullDate: propTypes.string.isRequired,
        isChosenDate: propTypes.bool.isRequired,
        dateActuality: propTypes.string.isRequired,
      }),
    ).isRequired,
  }),
  onCalendarYearsButtonClick: propTypes.func.isRequired,
  yearsRangeValues: propTypes.arrayOf(propTypes.number).isRequired,
};
