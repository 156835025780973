import { createAction, handleActions } from 'redux-actions';
import { allowedHeaders } from './constants';

const APPLICATION_CONFIG = 'applicationConfig';
const headersHandler = createAction(APPLICATION_CONFIG);

export function handleHeaders(headers) {
  return (dispatch) => dispatch(headersHandler(headers));
}

const initialState = {
  staffAppAuthAudience: null,
  staffAppAuthClientId: null,
  staffAppAuthConnection: null,
  staffAppAuthDomain: null,
  staffAppAuthRedirectUri: null,
  staffAppBackend: null,
  staffAppInstrumentationKey: null,
};

export default handleActions(
  {
    [APPLICATION_CONFIG]: (state, action) => ({
      ...state,
      staffAppAuthAudience:
        action.payload[allowedHeaders.STAFF_APP_AUTH_AUDIENCE],
      staffAppAuthClientId:
        action.payload[allowedHeaders.STAFF_APP_AUTH_CLIENT_ID],
      staffAppAuthConnection:
        action.payload[allowedHeaders.STAFF_APP_AUTH_CONNECTION],
      staffAppAuthDomain: action.payload[allowedHeaders.STAFF_APP_AUTH_DOMAIN],
      staffAppAuthRedirectUri:
        action.payload[allowedHeaders.STAFF_APP_AUTH_REDIRECT_URI],
      staffAppBackend: action.payload[allowedHeaders.STAFF_APP_BACKEND],
      staffAppInstrumentationKey:
        action.payload[allowedHeaders.STAFF_APP_INSTRUMENTATION_KEY],
    }),
  },
  { ...initialState },
);
