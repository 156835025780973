import React from 'react';
import PropTypes from 'prop-types';
import { isBoolean } from 'lodash';

import RadioGroupField from '../RadioGroup';
import { t } from '../../../i18n';

export const getRadioOptions = (arrOfOptions = [true, false]) =>
arrOfOptions.map((option) => ({
   text: t(`${option}`),
  value: `${option}`,
}));

 const BooleanGroupField = ({ name, label, options, ...rest }) => {
  const getParsedValue = (value) => value === 'true';
  const getTransformedValue = (value) => isBoolean(value) && value.toString();
  const radioOptions = getRadioOptions(options);

  return (
    <RadioGroupField
      {...rest}
      name={name}
      label={label}
      group={radioOptions}
      parse={options ? null : getParsedValue}
      format={options ? null : getTransformedValue}
    />
  );
};

BooleanGroupField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
};
export default BooleanGroupField;
