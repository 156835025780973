import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { orderBy } from 'lodash/fp';
import Button from '../../shared/components/Button';
import PageHead from '../../shared/components/PageHead';
import Access, { Permissions } from '../../shared/components/Access';
import DepartmentItem from './DepartmentItem';
import Spinner from '../../shared/components/Spinner';
import Icon from '../../shared/components/Icon';
import IconButton from '../../shared/components/IconButton';
import Grid from '../../shared/components/Grid';
import './Department.scss';
import AkeliusTable from '../../shared/components/AkeliusTable';
 
class Department extends Component {

  mapToTableData(items, onEditItemModalOpen, onDeleteItemModalOpen) {
    const rows = items.map((item) => ({
      key: { value: item.id, render: item.id },
      name: { value: item.name, render: item.name },
      organization: { value: item.organization.name, render: item.organization.name },
      actions: {
        value: null,
        render: (
          <Grid container direction="row"   className="department__actions"  spacing={0}>
           <Access
              rule={Permissions.EditDepartmentItem}
            >
              {(granted) => {
                if (!granted) {
                  return null;
                }

                return (
                  <IconButton
                    onClick={() => onEditItemModalOpen(item)}
                  >
                    <Icon icon="ak-edit" />
                  </IconButton>
                );
              }
              }
            </Access>
            <Access
              rule={Permissions.DeleteDepartmentItem}
            >
              {(granted) => {
                if (!granted) {
                  return null;
                }

                return (
                  <IconButton
                    onClick={() => onDeleteItemModalOpen(item)}
                  >
                    <Icon icon="ak-delete" />
                  </IconButton>
                );
              }
              }
            </Access>
          </Grid>
         )
      },
    }));
    return rows;
  };

  render() {
    const {
      t,
      allItems,
      onHandleInformation,
      onAddNewItemModalOpen,
      onEditItemModalOpen,
      onDeleteItemModalOpen,
      listView,
      toggleView
    } = this.props;

    const loading = this.props.itemsAreLoading;
    return (
      <>
        <PageHead text={t('departmentalStructure')} />
        <div className="department">
          <Access
            to={{
              organizationId: allItems && allItems.length > 0 ? allItems[0].id : 0,
              organizationTree: allItems,
            }}
            rule={Permissions.CreateDepartmentItem}
          >
            {(granted) => {
              if (!granted) {
                return null;
              }

              return (
                <div className="department__org-actions">
                  <div className='department__org-actions-left'>
                    <Button
                      onClick={onAddNewItemModalOpen}
                      title={t('newItem')}
                      primary
                      className="department__add-new-btn"
                    >
                      <Icon icon="ak-plus" color="white" />
                      {t('newItem')}
                    </Button>
                    <IconButton
                      className="department__info-btn"
                      onClick={onHandleInformation({
                        title: t('newItem'),
                        message: t(`departmentStructureInfo`)
                          .split('\n')
                          .map((line) => <p key={line}>{line}</p>),
                      })}
                    >
                      <Icon icon="ak-help" />
                    </IconButton>
                  </div>
                  <div className='department__org-actions-right'>
                    <IconButton
                      className={listView ? 'department__active' : ''}
                      onClick={toggleView}
                    >
                      <Icon icon="ak-table" />
                    </IconButton>
                    <IconButton
                      className={!listView ? 'department__active' : ''}
                      onClick={toggleView}
                    >
                      <Icon icon="ak-grid" />
                    </IconButton></div>
                </div>
              );
            }}
          </Access>
          <Spinner isLoading={loading}>
            {listView ?
              <AkeliusTable
                id={'department-table'}
                className={'department-table-class'}
                columns={[
                  {
                    label: 'department id',
                    value: 'key',
                  },
                  {
                    label: 'name',
                    value: 'name',
                  },
                  {
                    label: 'organization',
                    value: 'organization',
                  },
                  {
                    label: 'actions',
                    value: 'actions',
                  }
                ]}
                rows={this.mapToTableData(allItems, onEditItemModalOpen, onDeleteItemModalOpen)}
              />
              : <Grid container>
                {orderBy(['name'], ['asc'], allItems).map(
                  (item, index) => (
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                      key={`${item.id}${index}`}
                    >
                      <DepartmentItem
                        item={item}
                        handleEditItemClick={onEditItemModalOpen}
                        handleDeleteItemClick={onDeleteItemModalOpen}
                      />
                    </Grid>
                  ),
                )}</Grid>
            }
          </Spinner>
        </div>
      </>
    );
  }
}

Department.propTypes = {
  t: PropTypes.func.isRequired,
  allItems: PropTypes.array.isRequired,
  itemsAreLoading: PropTypes.bool,
  onHandleInformation: PropTypes.func.isRequired,
  onAddNewItemModalOpen: PropTypes.func.isRequired,
  onEditItemModalOpen: PropTypes.func.isRequired,
  onDeleteItemModalOpen: PropTypes.func.isRequired,
  listView: PropTypes.bool.isRequired,
  toggleView: PropTypes.func.isRequired,
};

export default withTranslation()(Department);
