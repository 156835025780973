import { createActions, handleActions } from 'redux-actions';
import ClientService from '../../services/client';

const GET_ORGANIZATION_ITEMS_STARTED = 'GET_ORGANIZATION_ITEMS_STARTED';
const GET_ORGANIZATION_ITEMS_SUCCESS = 'GET_ORGANIZATION_ITEMS_SUCCESS';
const GET_ORGANIZATION_ITEMS_FAILURE = 'GET_ORGANIZATION_ITEMS_FAILURE';

const SET_PARENT_ITEM = 'SET_PARENT_ITEM';
const SET_BREAD_CRUMBS = 'SET_BREAD_CRUMBS';

const GET_ORGANIZATION_ITEM_STARTED = 'GET_ORGANIZATION_ITEM_STARTED';
const GET_ORGANIZATION_ITEM_SUCCESS = 'GET_ORGANIZATION_ITEM_SUCCESS';
const GET_ORGANIZATION_ITEM_FAILURE = 'GET_ORGANIZATION_ITEM_FAILURE';

const SHOW_ADD_NEW_ITEM_MODAL = 'SHOW_ADD_NEW_ITEM_MODAL';
const HIDE_ADD_NEW_ITEM_MODAL = 'HIDE_ADD_NEW_ITEM_MODAL';
const IS_ADD_NEW_ITEM_MODAL_FETCHING = 'IS_ADD_NEW_ITEM_MODAL_FETCHING';

const SHOW_MOVE_ITEM_MODAL = 'SHOW_MOVE_ITEM_MODAL';
const HIDE_MOVE_ITEM_MODAL = 'HIDE_MOVE_ITEM_MODAL';

const MOVE_ORGANIZATION_ITEM_STARTED = 'MOVE_ORGANIZATION_ITEM_STARTED';
const MOVE_ORGANIZATION_ITEM_SUCCESS = 'MOVE_ORGANIZATION_ITEM_SUCCESS';
const MOVE_ORGANIZATION_ITEM_FAILURE = 'MOVE_ORGANIZATION_ITEM_FAILURE';

const SHOW_MERGE_ITEM_MODAL = 'SHOW_MERGE_ITEM_MODAL';
const HIDE_MERGE_ITEM_MODAL = 'HIDE_MERGE_ITEM_MODAL';

const MERGE_ORGANIZATION_ITEM_STARTED = 'MERGE_ORGANIZATION_ITEM_STARTED';
const MERGE_ORGANIZATION_ITEM_SUCCESS = 'MERGE_ORGANIZATION_ITEM_SUCCESS';
const MERGE_ORGANIZATION_ITEM_FAILURE = 'MERGE_ORGANIZATION_ITEM_FAILURE';

const actionCreators = createActions(
  SET_PARENT_ITEM,
  SET_BREAD_CRUMBS,
  GET_ORGANIZATION_ITEMS_STARTED,
  GET_ORGANIZATION_ITEMS_SUCCESS,
  GET_ORGANIZATION_ITEMS_FAILURE,
  GET_ORGANIZATION_ITEM_STARTED,
  GET_ORGANIZATION_ITEM_SUCCESS,
  GET_ORGANIZATION_ITEM_FAILURE,
  SHOW_ADD_NEW_ITEM_MODAL,
  HIDE_ADD_NEW_ITEM_MODAL,
  IS_ADD_NEW_ITEM_MODAL_FETCHING,
  SHOW_MOVE_ITEM_MODAL,
  HIDE_MOVE_ITEM_MODAL,
  MOVE_ORGANIZATION_ITEM_STARTED,
  MOVE_ORGANIZATION_ITEM_SUCCESS,
  MOVE_ORGANIZATION_ITEM_FAILURE,
  SHOW_MERGE_ITEM_MODAL,
  HIDE_MERGE_ITEM_MODAL,
  MERGE_ORGANIZATION_ITEM_STARTED,
  MERGE_ORGANIZATION_ITEM_SUCCESS,
  MERGE_ORGANIZATION_ITEM_FAILURE,
);

const {
  setParentItem,
  setBreadCrumbs,
  getOrganizationItemsStarted,
  getOrganizationItemsSuccess,
  getOrganizationItemsFailure,
  getOrganizationItemSuccess,
  getOrganizationItemFailure,
  showAddNewItemModal,
  hideAddNewItemModal,
  isAddNewItemModalFetching,
  showMoveItemModal,
  hideMoveItemModal,
  moveOrganizationItemStarted,
  moveOrganizationItemSuccess,
  moveOrganizationItemFailure,
  showMergeItemModal,
  hideMergeItemModal,
  mergeOrganizationItemStarted,
  mergeOrganizationItemSuccess,
  mergeOrganizationItemFailure,
} = actionCreators;

export function handleMoveItemModalOpen(item) {
  return (dispatch) => {
    dispatch(showMoveItemModal(item));
  };
}

export function handleMergeItemModalOpen(item) {
  return (dispatch) => {
    dispatch(showMergeItemModal(item));
  };
}

export function handleMoveItemModalClose() {
  return (dispatch) => {
    dispatch(hideMoveItemModal());
  };
}

export function handleMergeItemModalClose() {
  return (dispatch) => {
    dispatch(hideMergeItemModal());
  };
}

export function handleMoveOrganizationItem(targetId, parentId) {
  return async (dispatch) => {
    dispatch(moveOrganizationItemStarted());
    return ClientService.instance
      .patch(`/organization/${targetId}`, { parentId })
      .then(() => {
        dispatch(moveOrganizationItemSuccess());
      })
      .catch((error) => {
        dispatch(moveOrganizationItemFailure(error.message));
        throw error;
      });
  };
}

export function handleMergeOrganizationItem(targetId, parentId) {
  return async (dispatch) => {
    dispatch(mergeOrganizationItemStarted());
    return ClientService.instance
      .patch(`/organization/merge/${targetId}`, { parentId })
      .then(() => {
        dispatch(mergeOrganizationItemSuccess());
      })
      .catch((error) => {
        dispatch(mergeOrganizationItemFailure(error.message));
        throw error;
      });
  };
}

export function handleSetParentItem(id) {
  return (dispatch) => {
    dispatch(setParentItem(id));
  };
}

export function handleSetBreadCrumbs(newItems) {
  return (dispatch) => {
    dispatch(setBreadCrumbs(newItems));
  };
}

export function handleGetOrgItems() {
  return (dispatch) => {
    dispatch(getOrganizationItemsStarted());
    return ClientService.instance
      .fetch('/organization')
      .then((response) => {
        dispatch(getOrganizationItemsSuccess(response));
      })
      .catch((error) => {
        dispatch(getOrganizationItemsFailure(error.message));
      });
  };
}

export function handleGetOrgItemsBelonging() {
  return (dispatch) => {
    dispatch(getOrganizationItemsStarted());
    return ClientService.instance
      .fetch('/organization?belonging=true')
      .then((response) => {
        dispatch(getOrganizationItemsSuccess(response));
      })
      .catch((error) => {
        dispatch(getOrganizationItemsFailure(error.message));
      });
  };
}

export function handleAddNewItemModalOpen(config) {
  if (config.addNewItemModalTitle === 'editItem') {
    return (dispatch) => {
      ClientService.instance
        .fetch(`/organization/${config.editItemId}`)
        .then((response) => {
          dispatch(getOrganizationItemSuccess(response));

          dispatch(showAddNewItemModal(config));
        })
        .catch((error) => {
          dispatch(getOrganizationItemFailure(error.message));
        });
    };
  }
  return (dispatch) => {
    dispatch(showAddNewItemModal(config));
  };
}
export function handleAddNewItemModalClose() {
  return (dispatch) => {
    dispatch(hideAddNewItemModal());
  };
}

export function handleIsAddNewItemModalFetching(status) {
  return (dispatch) => {
    dispatch(isAddNewItemModalFetching(status));
  };
}

const organizationReducer = handleActions(
  {
    SET_PARENT_ITEM: (state, action) => ({
      ...state,
      items: state.items,
      parentItemId: action.payload,
    }),
    SET_BREAD_CRUMBS: (state, action) => ({
      ...state,
      breadCrumbs: action.payload,
    }),
    GET_ORGANIZATION_ITEMS_STARTED: (state) => ({
      ...state,
      isFetched: true,
    }),
    GET_ORGANIZATION_ITEMS_SUCCESS: (state, action) => ({
      ...state,
      items: [...action.payload],
      isFetched: false,
    }),
    GET_ORGANIZATION_ITEMS_FAILURE: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      isError: true,
      isFetched: false,
    }),
    GET_ORGANIZATION_ITEM_STARTED: (state) => ({
      ...state,
    }),
    GET_ORGANIZATION_ITEM_SUCCESS: (state, action) => ({
      ...state,
      editableItem: action.payload,
    }),
    GET_ORGANIZATION_ITEM_FAILURE: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      isError: true,
    }),
    SHOW_ADD_NEW_ITEM_MODAL: (state, action) => ({
      ...state,
      isAddNewItemModalOpen: true,
      addNewItemModalTitle: action.payload.addNewItemModalTitle,
      addNewItemModalActionButtonText:
        action.payload.addNewItemModalActionButtonText,
    }),
    HIDE_ADD_NEW_ITEM_MODAL: (state) => ({
      ...state,
      isAddNewItemModalOpen: false,
      addNewItemModalTitle: '',
      addNewItemModalActionButtonText: '',
      editableItem: null,
    }),
    IS_ADD_NEW_ITEM_MODAL_FETCHING: (state, action) => ({
      ...state,
      isAddNewItemModalFetching: action.payload,
    }),
    SHOW_MOVE_ITEM_MODAL: (state, action) => ({
      ...state,
      isMoveModalOpen: true,
      targetMoveItem: action.payload,
    }),
    HIDE_MOVE_ITEM_MODAL: (state) => ({
      ...state,
      isMoveModalOpen: false,
      targetMoveItem: null,
    }),
    MOVE_ORGANIZATION_ITEM_STARTED: (state) => ({
      ...state,
      isMoveModalFetching: true,
    }),
    MOVE_ORGANIZATION_ITEM_SUCCESS: (state) => ({
      ...state,
      isMoveModalFetching: false,
      isMoveModalOpen: false,
    }),
    MOVE_ORGANIZATION_ITEM_FAILURE: (state) => ({
      ...state,
      isMoveModalFetching: false,
      isMoveModalOpen: false,
    }),
    SHOW_MERGE_ITEM_MODAL: (state, action) => ({
      ...state,
      isMergeModalOpen: true,
      targetMergeItem: action.payload,
    }),
    HIDE_MERGE_ITEM_MODAL: (state) => ({
      ...state,
      isMergeModalOpen: false,
      targetMergeItem: null,
    }),
    MERGE_ORGANIZATION_ITEM_STARTED: (state) => ({
      ...state,
      isMergeModalFetching: true,
    }),
    MERGE_ORGANIZATION_ITEM_SUCCESS: (state) => ({
      ...state,
      isMergeModalFetching: false,
      isMergeModalOpen: false,
    }),
    MERGE_ORGANIZATION_ITEM_FAILURE: (state) => ({
      ...state,
      isMergeModalFetching: false,
      isMergeModalOpen: false,
    }),
  },
  {
    items: [],
    teamMembers: [],
    breadCrumbs: [],
    parentItemId: null,
    errorMessage: null,
    editableItem: null,
    addNewItemModalTitle: '',
    addNewItemModalActionButtonText: '',
    isError: false,
    isFetched: false,
    isAddNewItemModalOpen: false,
    isAddNewItemModalFetching: false,
    isMoveModalOpen: false,
    isMoveModalFetching: false,
    targetMoveItem: null,
    isMergeModalOpen: false,
    isMergeModalFetching: false,
    targetMergeItem: null,
  },
);

export default organizationReducer;
