import { Region, Country } from '../../../shared/types';

export const FORM_NAME = 'add_item_form';

export const DEFAULT_ITEM_PROPS = {
  _etag: null,
  country: null,
  employeeIds: [],
  manager: null,
  managerId: null,
  parentId: null,
  region: null,
  teamId: null,
};

export const GEO_FIELDS = {
  country: 'country',
  region: 'region',
};

export const REGION_COUNTRIES = {
  [Region.Europe]: [Country.France, Country.UnitedKingdom, Country.Germany],
  [Region.Scandinavia]: [Country.Sweden, Country.Denmark],
  [Region.NorthAmerica]: [Country.UnitedStates, Country.Canada],
  [Region.SouthEurope]: [Country.Cyprus],
};
