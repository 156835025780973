import propTypes from 'prop-types';
import React from 'react';
import Menu from '@mui/material/Menu';
import './DropDownMenu.scss';

const DropDownMenu = (props) => {
  const { children, id, open, onClose, anchorEl } = props;
  return (
    <Menu
      id={id}
      open={open}
      onClose={onClose}
      variant="menu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      classes={{
        paper: 'drop-down-menu__paper',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      MenuListProps={{
        classes: {
          root: 'drop-down-menu',
        },
      }}
      elevation={4}
    >
      {children}
    </Menu>
  );
};

DropDownMenu.propTypes = {
  id: propTypes.string.isRequired,
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  anchorEl: propTypes.object,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
};

export default DropDownMenu;
