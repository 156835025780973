import React, { useState } from 'react';
import { getFormValues, getFormSubmitErrors } from 'redux-form';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Modal from '../../Modal';
import { formatOrgItems } from '../../../../App/Organization/OrganizationItem/helpers';
import AkeliusBusinessDivisionField from '../AkeliusBusinessDivisionField';
import './AkeliusBusinessDivisionContainer.scss';

import { akeliusBusinessDivisionContext } from '../context';

const AkeliusBusinessDivisionContainer = ({
  name,
  formValues,
  formErrors,
  organizationItems,
  isRequired,
  t,
}) => {
  const context = React.useContext(akeliusBusinessDivisionContext);

  const businessDivisionId = formValues?.businessDivisionId;
  const businessDivisionError = formErrors?.businessDivisionId;
  const businessDivisionName = organizationItems.find(
    (item) => item?.id === businessDivisionId,
  )?.name;

  const [
    isBusinessDivisionModalOpen,
    setIsBusinessDivisionModalOpen,
  ] = useState(false);

  const openBusinessDivisionModal = () => {
    setIsBusinessDivisionModalOpen(true);
  };
  const closeBusinessDivisionModal = () => {
    setIsBusinessDivisionModalOpen(false);
  };
  const submitBusinessDivisionModal = () => {
    if (context.submitHandler && context.selectedId) {
      context.submitHandler(context.selectedId);
      context.setSelectedId(null);
      context.setSubmitHandler(null);
    }
    closeBusinessDivisionModal();
  };

  return (
    <>
      <div className="business-division">
        <div
          className={classnames('business-division__label', {
            'business-division__label--error': Boolean(businessDivisionError),
          })}
        >
          {`${t('businessDivision')} ${isRequired ? '*' : ''}`}
        </div>
        <Button
          className={classnames('business-division__select-btn', {
            'business-division__select-btn--error': Boolean(
              businessDivisionError,
            ),
          })}
          onClick={openBusinessDivisionModal}
        >
          {businessDivisionName || t('select')}
        </Button>
        {businessDivisionError && (
          <div className="business-division__error-hint">
            {t(businessDivisionError)}
          </div>
        )}
        <Modal
          open={isBusinessDivisionModalOpen}
          title={t('businessDivision')}
          onClose={closeBusinessDivisionModal}
          onSubmit={submitBusinessDivisionModal}
          callToActionText={t('set')}
          classNames="business-division__modal"
        >
          <AkeliusBusinessDivisionField
            name={name}
            organizationItems={organizationItems}
            businessDivisionId={businessDivisionId}
          />
        </Modal>
      </div>
    </>
  );
};

AkeliusBusinessDivisionContainer.defaultProps = {
  isRequired: false,
};

AkeliusBusinessDivisionContainer.propTypes = {
  name: PropTypes.string,
  formValues: PropTypes.shape({
    businessDivisionId: PropTypes.string,
  }),
  formErrors: PropTypes.shape({
    businessDivisionId: PropTypes.string,
  }),
  organizationItems: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  isRequired: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    formValues: getFormValues(ownProps.formName)(state),
    formErrors: getFormSubmitErrors(ownProps.formName)(state),
    organizationItems: formatOrgItems(state.organization.items),
  });

export default connect(
  mapStateToProps,
  null,
)(withTranslation()(AkeliusBusinessDivisionContainer));
