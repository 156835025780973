import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import Icon from '../Icon';

class SidebarDropdown extends React.Component {
  constructor() {
    super();
    this.sidebarDropDownRef = React.createRef();
  }

  componentDidMount() {
    const hasChildren = this.sidebarDropDownRef.current.hasChildNodes();
    if (!hasChildren) {
      this.setState({
        isVisible: false,
      });
    }
  }

  state = {
    expanded: false,
    isVisible: true,
  };

  toggleDropdown = () => {
    this.setState((current) => ({
      expanded: !current.expanded,
    }));
  };

  render() {
    const { title, children, isActive } = this.props;
    const { expanded } = this.state;

    return this.state.isVisible ? (
      <li className="sidebar_dropdown">
        <Accordion
          classes={{
            root: 'sidebar_dropdown__root',
          }}
          expanded={isActive || expanded}
          onChange={this.toggleDropdown}
        >
          <AccordionSummary
            classes={{
              root: 'sidebar_dropdown__summary',
            }}
          >
            <Icon
              icon="ak-chevron-down"
              rotate={isActive || expanded ? 'bottom' : 'top'}
              className="sidebar_dropdown__icon"
            />
            {title}
          </AccordionSummary>
          <AccordionDetails classes={{ root: 'sidebar_dropdown__details' }}>
            <ul className="sidebar__child-list" ref={this.sidebarDropDownRef}>
              {children}
            </ul>
          </AccordionDetails>
        </Accordion>
      </li>
    ) : null;
  }
}

SidebarDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  isActive: PropTypes.bool.isRequired,
};

export default SidebarDropdown;
