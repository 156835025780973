import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  handleOfficeLocations as handleOfficeLocationsConnect,
  handleCountrySettings as handleCountrySettingsConnect,
  handleTabletModels as handleTabletModelsConnect,
  handleDockingStations as handleDockingStationsConnect,
  handleComputerModels as handleComputerModelsConnect,
  handleMonitors as handleMonitorsConnect,
  handlePreferredLanguages as handlePreferredLanguagesConnect,
  handleApplications as handleApplicationsConnect,
  handleCountryPowerPlugs as handleCountryPowerPlugsConnect,
  handleMouses as handleMouseConnect,
  handleCountryKeyboards as handleCountryKeyboardsConnect,
  handlePhoneModels as handlePhoneModelsConnect,
  handleKeyboards as handleKeyboardsConnect,
  handleOutboundCallNumbers as handleOutboundCallNumbersConnect,
  handleDeliveryAddresses as handleDeliveryAddressesConnect,
  handleCompanyList as handleCompanyListConnect,
} from './reducer';
import { JiraProviderHook } from './jiraProviderHook';
import Spinner from '../Spinner';

const JiraDataProvider = ({
  children,
  handleOfficeLocations,
  handleCountrySettings,
  handleTabletModels,
  handleDockingStations,
  handleComputerModels,
  handleMonitors,
  handlePreferredLanguages,
  handleApplications,
  handleCountryPowerPlugs,
  handleMouses,
  handleCountryKeyboards,
  handlePhoneModels,
  handleKeyboards,
  handleOutboundCallNumbers,
  handleDeliveryAddresses,
  handleCompanyList,
}) => {
  const {
    isFetching,
    error,
    officeLocations,
    tabletModels,
    countrySettings,
    dockingStations,
    computerModels,
    monitors,
    preferredLanguages,
    applications,
    countryPowerPlugs,
    mouses,
    countryKeyboards,
    phoneModels,
    keyboards,
    outboundCallNumbers,
    deliveryAddresses,
    companyList,
  } = JiraProviderHook();
  if (isFetching) {
    return <Spinner />;
  }
  if (error) {
    return <h1>Jira data can not be fetched</h1>;
  }

  handleOfficeLocations(officeLocations);
  handleCountrySettings(countrySettings);
  handleTabletModels(tabletModels);
  handleDockingStations(dockingStations);
  handleComputerModels(computerModels);
  handleMonitors(monitors);
  handlePreferredLanguages(preferredLanguages);
  handleApplications(applications);
  handleCountryPowerPlugs(countryPowerPlugs);
  handleMouses(mouses);
  handleCountryKeyboards(countryKeyboards);
  handlePhoneModels(phoneModels);
  handleKeyboards(keyboards);
  handleOutboundCallNumbers(outboundCallNumbers);
  handleDeliveryAddresses(deliveryAddresses);
  handleCompanyList(companyList);
  return children;
};

JiraDataProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  handleOfficeLocations: PropTypes.func.isRequired,
  handleCountrySettings: PropTypes.func.isRequired,
  handleTabletModels: PropTypes.func.isRequired,
  handleDockingStations: PropTypes.func.isRequired,
  handleComputerModels: PropTypes.func.isRequired,
  handleMonitors: PropTypes.func.isRequired,
  handleApplications: PropTypes.func.isRequired,
  handleCountryPowerPlugs: PropTypes.func.isRequired,
  handleMouses: PropTypes.func.isRequired,
  handlePhoneModels: PropTypes.func.isRequired,
  handleKeyboards: PropTypes.func.isRequired,
  handleOutboundCallNumbers: PropTypes.func.isRequired,
  handleDeliveryAddresses: PropTypes.func.isRequired,
  handleCompanyList: PropTypes.func.isRequired,
  handlePreferredLanguages: PropTypes.func.isRequired,
  handleCountryKeyboards: PropTypes.func.isRequired,
};

export default connect(null, {
  handleOfficeLocations: handleOfficeLocationsConnect,
  handleCountrySettings: handleCountrySettingsConnect,
  handleTabletModels: handleTabletModelsConnect,
  handleDockingStations: handleDockingStationsConnect,
  handleComputerModels: handleComputerModelsConnect,
  handleMonitors: handleMonitorsConnect,
  handlePreferredLanguages: handlePreferredLanguagesConnect,
  handleApplications: handleApplicationsConnect,
  handleCountryPowerPlugs: handleCountryPowerPlugsConnect,
  handleMouses: handleMouseConnect,
  handleCountryKeyboards: handleCountryKeyboardsConnect,
  handlePhoneModels: handlePhoneModelsConnect,
  handleKeyboards: handleKeyboardsConnect,
  handleOutboundCallNumbers: handleOutboundCallNumbersConnect,
  handleDeliveryAddresses: handleDeliveryAddressesConnect,
  handleCompanyList: handleCompanyListConnect,
})(JiraDataProvider);
