import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import classnames from 'classnames';
import { orderBy, pick, keys } from 'lodash/fp';
import { useEventListener } from './helpers';
import { SORT_MODE } from './constants';
import Icon from '../Icon';
import './AkeliusTable.scss';

export const AkeliusTable = (props) => {
  const { rows, columns, id: tableId, classNames } = props;
  const [sort, setSort] = useState({
    field: columns[0].value,
    order: SORT_MODE.ASC,
  });
  const [isScrollEnabled, setIsScrollEnabled] = useState(false);
  const tableWrapRef = useRef(null);

  const resizeHandler = useCallback(() => {
    setIsScrollEnabled(
      tableWrapRef.current.scrollWidth > tableWrapRef.current.clientWidth,
    );
  }, [setIsScrollEnabled]);

  const handleSortCellClick = (columnName) => () => {
    if (sort.field === columnName) {
      setSort({
        ...sort,
        order: sort.order === SORT_MODE.ASC ? SORT_MODE.DESC : SORT_MODE.ASC,
      });
    } else {
      setSort({
        order: SORT_MODE.ASC,
        field: columnName,
      });
    }
  };

  useEffect(() => {
    setIsScrollEnabled(
      tableWrapRef.current.scrollWidth > tableWrapRef.current.clientWidth,
    );
  }, []);

  useEventListener('resize', resizeHandler);

  return (
    <div
      ref={tableWrapRef}
      className={classnames('table-wrap', {
        'table-wrap--is-scrolled': isScrollEnabled,
      })}
    >
      <Table
        id={tableId}
        classes={{
          root: classnames('table', {
            [classNames]: !!classNames,
          }),
        }}
      >
        <TableHead>
          <TableRow classes={{ root: 'table__head-row' }}>
            {columns.map(({ label, value }, columnId) => (
              <TableCell
                key={label}
                classes={{
                  root: classnames('table__head-cell', {
                    [`table__head-cell--fixed`]:
                      isScrollEnabled && columnId === 0,
                    [`table__head-cell--${tableId}-${columnId}`]: !!tableId,
                  }),
                }}
              >
                {label && (
                  <div
                    className="table__head-cell-content"
                    onClick={handleSortCellClick(value)}
                  >
                    {label}
                    <Icon
                      icon="ak-sort-asc"
                      rotate={sort.order === SORT_MODE.DESC ? 'top' : 'bottom'}
                      className={classnames('table__sort-icon', {
                        'table__sort-icon--is-active': value === sort.field,
                      })}
                    />
                  </div>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {orderBy([`${sort.field}.value`], [sort.order], rows).map(
            (row, idx) => (
                <TableRow key={idx} classes={{ root: 'table__row' }}>
                  {keys(
                    pick(
                      columns.map((column) => column.value),
                      row,
                    ),
                  ).map((cell, cellId) => (
                    <TableCell
                      key={cell}
                      classes={{
                        root: classnames('table__row-cell', {
                          [`table__row-cell--fixed`]:
                            isScrollEnabled && cellId === 0,
                          [`table__row-cell--${tableId}-${idx}`]: !!tableId,
                        }),
                      }}
                    >
                      {row[cell].render || row[cell].value}
                    </TableCell>
                  ))}
                </TableRow>
              ),
          )}
        </TableBody>
      </Table>
    </div>
  );
};

AkeliusTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  classNames: PropTypes.string,
};

AkeliusTable.defaultProps = {};
