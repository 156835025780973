import { reduxForm } from 'redux-form';
import { FORM_NAME } from './constants';
import MergeItemModal from './MergeItemModal';

const submit = async () => {};

export default reduxForm({
  form: FORM_NAME,
  onSubmit: submit,
})(MergeItemModal);
