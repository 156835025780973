import React from 'react';

export const akeliusBusinessDivisionContext = React.createContext({
  selectedId: null,
  submitHandler: null,
  setSelectedId(id) {
    this.selectedId = id;
  },
  setSubmitHandler(handler) {
    this.submitHandler = handler;
  },
});
