import {
  get, find, compose, includes,
} from 'lodash/fp';

import { REGION_COUNTRIES } from '../../../App/Organization/AddNewItemModal/constants';

export function checkManagerForOrgItemInTree(
  authUser,
  organizationId,
  organizationTree,
  matcher,
) {
  const organizationItem = find({ id: organizationId })(organizationTree);

  if (!organizationItem) {
    return false;
  }

  if (!matcher(authUser, organizationItem) && organizationItem.parentId) {
    const parentOrganizationId = compose(
      get('id'),
      find({ id: organizationItem.parentId }),
    )(organizationTree);

    if (parentOrganizationId) {
      return checkManagerForOrgItemInTree(
        authUser,
        parentOrganizationId,
        organizationTree,
        matcher,
      );
    }

    return false;
  }

  if (matcher(authUser, organizationItem)) {
    return true;
  }

  return false;
}

export function mapCountryToRegion(country) {
  const [userRegion] = Object.keys(REGION_COUNTRIES).filter((region) => includes(country)(REGION_COUNTRIES[region]));

  return userRegion;
}
