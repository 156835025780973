import React, { Component } from 'react';
import { Field, fieldPropTypes } from 'redux-form';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';

import {
  Radio,
  FormLabel,
  RadioGroup,
  FormControl,
  FormHelperText,
  FormControlLabel,
} from '@mui/material';

import './RadioGroupStyle.scss';

class WrappedRadioGroup extends Component {
  static propTypes = {
    ...fieldPropTypes,
  };

  handleChange = (value) => {
    const { input, onChange } = this.props;
    input.onChange(value);
    onChange && onChange(value);
  };

  render() {
    const {
      t,
      meta,
      label,
      input,
      input: { name },
      tReady,
      disabled,
      labelClassNames,
      focused,
      ...props
    } = this.props;

    const isError = !!(meta.invalid && meta.error);

    return (
      <FormControl name={name} className="radio-group-field" error={isError}>
        <FormLabel
          classes={{
            root: 'radio-group-field__label',
            focused: 'radio-group-field__label--focused',
          }}
          className={classnames({
            'radio-group-field__label--error': isError,
            'radio-group-field__label--disabled': disabled,
            [labelClassNames]: !!labelClassNames,
          })}
          component="label"
        >
          {label}
        </FormLabel>
        <RadioGroup
          {...props}
          {...input}
          value={input.value || ''}
          name={label}
          aria-label={label}
          className="radio-group-field__radio-group"
          onChange={this.handleChange}
        >
          {this.props.group.map((item) => (
            <FormControlLabel
              key={item.text}
              value={item.value}
              className={classnames('radio-group-field__item-label', {
                'radio-group-field__item-label--disabled': disabled,
              })}
              control={
                <Radio
                  disabled={disabled}
                  className={classnames({
                    'radio-group-field__radio-btn--disabled': disabled,
                  })}
                  classes={{
                    root: 'radio-group-field__radio-btn',
                    checked: 'radio-group-field__radio-btn--checked',
                  }}
                />
              }
              label={item.text}
            />
          ))}
        </RadioGroup>
        {meta.error && (
          <FormHelperText
            classes={{
              root: 'radio-group-field__form-helper-text',
              error: 'radio-group-field__form-helper-text--error',
            }}
          >
            {t(meta.error)}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

const RadioGroupField = (props) => (
  <Field {...props} component={WrappedRadioGroup} />
);

export default withTranslation()(RadioGroupField);
