import { createActions, handleActions } from 'redux-actions';
import ClientService from '../../../services/client';
import { handleNotification } from '../../../shared/components/Notification/reducer';
import { DEFAULT_OFFSET, DEFAULT_LIMIT } from './helpers';

const GET_EMPLOYEES_STARTED = 'GET_EMPLOYEES_STARTED';
const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS';
const GET_EMPLOYEES_FAILURE = 'GET_EMPLOYEES_FAILURE';
const FLUSH_EMPLOYEES = 'FLUSH_EMPLOYEES';

const SHOW_FILTERS = 'SHOW_FILTERS';
const HIDE_FILTERS = 'HIDE_FILTERS';

const GET_TEAM_MEMBERS_STARTED = 'GET_TEAM_MEMBERS_STARTED';
const GET_TEAM_MEMBERS_SUCCESS = 'GET_TEAM_MEMBERS_SUCCESS';
const GET_TEAM_MEMBERS_FAILURE = 'GET_TEAM_MEMBERS_FAILURE';

const {
  getEmployeesStarted,
  getEmployeesSuccess,
  getEmployeesFailure,
  flushEmployees,
  showFilters,
  hideFilters,
  getTeamMembersStarted,
  getTeamMembersSuccess,
  getTeamMembersFailure,
} = createActions(
  GET_EMPLOYEES_STARTED,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAILURE,
  FLUSH_EMPLOYEES,
  SHOW_FILTERS,
  HIDE_FILTERS,
  GET_TEAM_MEMBERS_STARTED,
  GET_TEAM_MEMBERS_SUCCESS,
  GET_TEAM_MEMBERS_FAILURE,
);

export function toggleFilters(currentState) {
  return (dispatch) => {
    if (currentState) {
      dispatch(hideFilters());
    } else {
      dispatch(showFilters());
    }
  };
}

export function getCatalogEmployees(
  offset = DEFAULT_OFFSET,
  limit = DEFAULT_LIMIT,
) {
  return async (dispatch) => {
    dispatch(getEmployeesStarted());
    try {
      const employees = await ClientService.instance.fetch(
        `/employees?offset=${offset}&limit=${limit}`,
      );
      if (offset === DEFAULT_OFFSET) {
        dispatch(flushEmployees());
      }
      dispatch(getEmployeesSuccess(employees));
    } catch (err) {
      dispatch(getEmployeesFailure(err));
      dispatch(
        handleNotification({
          message: err.message,
          type: 'error',
          isActive: true,
        }),
      );
    }
  };
}

export function getSearchedEmployees(
  { search, businessDivisionId },
  offset = DEFAULT_OFFSET,
  limit = DEFAULT_LIMIT,
) {
  return async (dispatch) => {
    dispatch(getEmployeesStarted());
    try {
      const searchedEmployees = await ClientService.instance.post(
        '/employees/search',
        {
          search,
          businessDivisionId,
          offset,
          limit,
        },
      );
      if (offset === DEFAULT_OFFSET) {
        dispatch(flushEmployees());
      }

      dispatch(getEmployeesSuccess(searchedEmployees));
    } catch (err) {
      dispatch(getEmployeesFailure(err));
      dispatch(
        handleNotification({
          message: err.message,
          type: 'error',
          isActive: true,
        }),
      );
    }
  };
}

export function handleGetTeamMembersForOrganization(parentItemId) {
  if (!parentItemId) {
    return (dispatch) => {
      dispatch(getTeamMembersSuccess({employees: []}));
    };
  }
  return (dispatch) => {
    dispatch(getTeamMembersStarted());
    return ClientService.instance
      .fetch(`/employees?businessDivisionId=${parentItemId}&offset=0&limit=100`)
      .then((response) => {
        dispatch(getTeamMembersSuccess(response));
      })
      .catch((error) => {
        dispatch(getTeamMembersFailure(error.message));
      });
  };
}

export default handleActions(
  {
    GET_EMPLOYEES_STARTED: (state) => ({
      ...state,
      isFetched: true,
    }),
    GET_EMPLOYEES_SUCCESS: (state, action) => ({
      ...state,
      isFetched: false,
      employees: [...state.employees, ...action.payload.employees],
      employeesCount: action.payload.count,
    }),
    GET_EMPLOYEES_FAILURE: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      isFetched: false,
      employeesAreFetchedError: true,
    }),
    FLUSH_EMPLOYEES: (state) => ({
      ...state,
      employeesCount: 0,
      employees: [],
    }),
    SHOW_FILTERS: (state) => ({
      ...state,
      isFiltersBlockOpen: true,
    }),
    HIDE_FILTERS: (state) => ({
      ...state,
      isFiltersBlockOpen: false,
    }),
    GET_TEAM_MEMBERS_STARTED: (state) => ({
      ...state,
      isTeamMembersLoading: true,
    }),
    GET_TEAM_MEMBERS_SUCCESS: (state, action) => ({
      ...state,
      teamMembersOfOrganization: [...action.payload.employees],
      isTeamMembersLoading: false,
    }),
    GET_TEAM_MEMBERS_FAILURE: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      isError: true,
      isTeamMembersLoading: false,
    }),
  },
  {
    employees: [],
    employeesCount: 0,
    isFetched: false,
    employeesAreFetchedError: false,
    isFiltersBlockOpen: false,
    teamMembersOfOrganization: [],
    isTeamMembersLoading: false,
  },
);
