import React, { useRef, useEffect } from 'react';
import propTypes from 'prop-types';
import './DatePickerBase.scss';

export const DatePickerBase = ({ children, onClickOutside }) => {
  const node = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!node.current.contains(e.target)) {
        onClickOutside();
      }
    };
    const handleKeyDown = (e) => {
      if (e.keyCode === 27) {
        onClickOutside();
      }
    };
    document.addEventListener('mousedown', handleOutsideClick, false);
    document.addEventListener('keydown', handleKeyDown, false);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, false);
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [onClickOutside]);
  return (
    <div className="datepicker-base" ref={node}>
      {children}
    </div>
  );
};

DatePickerBase.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
  onClickOutside: propTypes.func.isRequired,
};
