import React, { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  handleSetParentItem,
  handleAddNewItemModalOpen,
  handleGetOrgItemsBelonging,
  handleGetDepartmentItems,
  handleDeleteDepartmentItem,
  handleToggleDeleteItemModal,
  handleToggleView
} from './reducer';
import Department from './Department';
import AddNewItemModal from './AddNewItemModal';
import Grid from '../../shared/components/Grid';
import Modal from '../../shared/components/Modal';
import { handleInformation } from '../../shared/components/InformationModal/reducer';

class DepartmentContainer extends Component {
  componentDidMount() {
    this.props.handleGetOrgItemsBelonging();
    this.props.handleGetDepartmentItems();
  }

  closeDeletionModal = () => {
    this.props.handleToggleDeleteItemModal({
      isDeletionModalOpen: false,
      deleteItemModalTitle: '',
      deleteItemModalActionBodyText: '',
      deleteItemModalItem: null
    });
  }

  openDeletionModal = (item) => {
    this.props.handleToggleDeleteItemModal({
      isDeletionModalOpen: true,
      deleteItemModalTitle: 'Delete department',
      deleteItemModalActionBodyText: `Are you sure you want to delete department "${item.name}"?`,
      deleteItemModalItem: item
    });
  }

  toggleView = () => {
    this.props.handleToggleView();
  };

  handleAddNewItemModalOpen = () => {
    this.props.handleAddNewItemModalOpen({
      addNewItemModalTitle: 'newItem',
      addNewItemModalActionButtonText: 'saveItem',
    });
  };

  handleEditItemClick = (item) => {
    this.props.handleAddNewItemModalOpen({
      addNewItemModalTitle: 'editItem',
      addNewItemModalActionButtonText: 'updateItem',
      editItemId: item.id,
      departmentItems: this.props.allItems
    });
  };

  handleDeleteItemConfirmation = async () => {
    await this.props.handleDeleteDepartmentItem(this.props.deleteItemModalItem);
  };


  onHandleInformation =
    ({ title, message, isOpen }) =>
      () => {
        this.props.handleInformation({ title, message, isOpen });
      };

  render() {
    const {
      allItems,
      organizationItems,
      itemsAreLoading,
      isError,
      isModalOpen,
      isDeletionModalOpen,
      deleteItemModalTitle,
      deleteItemModalActionBodyText,
      deleteItemModalItem
    } = this.props;

    if (isError) {
      return null;
    }

    return (
      <>
        <Department
          allItems={allItems}
          itemsAreLoading={itemsAreLoading}
          listView={this.props.listView}
          toggleView={this.toggleView}
          onAddNewItemModalOpen={this.handleAddNewItemModalOpen}
          onHandleInformation={this.onHandleInformation}
          onEditItemModalOpen={this.handleEditItemClick}
          onDeleteItemModalOpen={this.openDeletionModal}
          onDeleteConfirmation={this.handleDeleteItemConfirmation}
        />
        {isModalOpen && <AddNewItemModal isModalOpen={isModalOpen} organizationItems={organizationItems} />}
        {isDeletionModalOpen && 
          <Modal
            item={deleteItemModalItem}
            title={deleteItemModalTitle}
            open={isDeletionModalOpen}
            onClose={this.closeDeletionModal}
            onSubmit={this.handleDeleteItemConfirmation}
            callToActionText='Delete'
            classNames="add-new-item"
            isModalLoading={itemsAreLoading}> 
            <Grid container spacing={0}>
              {deleteItemModalActionBodyText}
            </Grid>
          </Modal>
        }
      </>
    );
  }
}

DepartmentContainer.propTypes = {
  allItems: propTypes.array.isRequired,
  organizationItems: propTypes.array.isRequired,
  itemsAreLoading: propTypes.bool.isRequired,
  isError: propTypes.bool,
  listView: propTypes.bool.isRequired,
  isModalOpen: propTypes.bool.isRequired,
  deleteItemModalTitle: propTypes.string,
  deleteItemModalActionBodyText: propTypes.string,
  deleteItemModalItem: propTypes.object,
  isDeletionModalOpen: propTypes.bool.isRequired,
  handleAddNewItemModalOpen: propTypes.func.isRequired,
  handleGetOrgItemsBelonging: propTypes.func.isRequired,
  handleGetDepartmentItems: propTypes.func.isRequired,
  handleInformation: propTypes.func.isRequired,
  handleDeleteDepartmentItem: propTypes.func.isRequired,
  handleToggleDeleteItemModal: propTypes.func.isRequired,
  handleToggleView: propTypes.func.isRequired
};

function mapStateToProps(state) {
  const { department } = state;
  const {
    items,
    organizationItems,
    isError,
    isAddNewItemModalOpen: isModalOpen,
    isDeletionModalOpen,
    deleteItemModalTitle,
    deleteItemModalActionBodyText,
    deleteItemModalItem,
    itemsAreLoading,
    listView
  } = department;

  return {
    allItems: items,
    organizationItems,
    itemsAreLoading,
    isError,
    isModalOpen,
    deleteItemModalTitle,
    deleteItemModalActionBodyText,
    isDeletionModalOpen,
    deleteItemModalItem,
    listView
  };
}

export default connect(mapStateToProps, {
  handleGetDepartmentItems,
  handleSetParentItem,
  handleAddNewItemModalOpen,
  handleGetOrgItemsBelonging,
  handleInformation,
  handleDeleteDepartmentItem,
  handleToggleDeleteItemModal,
  handleToggleView
})(DepartmentContainer);
