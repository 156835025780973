import React from 'react';
import countries from 'i18n-iso-countries';
import { StyledEngineProvider } from '@mui/material/styles';
import { Provider as ReduxStoreProvider } from 'react-redux';
import {
  Routes,
  Route
} from 'react-router-dom';
import { Home } from './Home';
import { Team } from './Team';
import { Staff } from './Staff';
import { Organization } from './Organization';
import { Department } from './Department';
import { StaffProfile } from './Staff/StaffProfile';
import { Location } from './Location';
import { Company } from './Company';
import { Role } from './Role';
import store from '../shared/state/store';
import Sidebar from '../shared/components/Sidebar';
import AuthProvider from '../shared/components/AuthProvider';
import AppConfigProvider from '../shared/components/AppConfigProvider';
import JiraDataProvider from '../shared/components/JiraDataProvider';
import Notification from '../shared/components/Notification';
import InformationModal from '../shared/components/InformationModal';
import ErrorBoundary from '../shared/components/ErrorBoundary';
import HeaderContainer from '../shared/components/Header/HeaderContainer';
import BackEndDataProvider from '../shared/components/BackEndDataProvider';

import './App.scss';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
countries.registerLocale(require('i18n-iso-countries/langs/sv.json'));
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
countries.registerLocale(require('i18n-iso-countries/langs/da.json'));

class App extends React.Component {
  render() {
    return ( 
      <ReduxStoreProvider store={store}>
        <AppConfigProvider>
          <AuthProvider>
            <JiraDataProvider>
              <BackEndDataProvider>
                <StyledEngineProvider injectFirst>
                   
                    <HeaderContainer />
                    <Sidebar />
                    <main className="app">
                    <Routes>
                          <Route path="/" element={<Home/>} />
                        <Route
                          path="/organization"
                          element={<Organization/>}
                        />
                        <Route
                          path="/department"
                          element={<Department/>}
                        />
                         <Route
                          path="/team"
                          element= {<Team/>}
                        /> 
                        
                        <Route path="/locations" element={<Location/>} />
                        <Route path="/staff" element={<Staff/>} />

                        <Route
                          path="/staff/:id"
                          element={<StaffProfile/> }
                        />
                        <Route path="/companies" element={<Company/>} />
                        <Route path="/roles" element={<Role/>} />
                        </Routes>
                    </main>
                    <footer className="app-footer" />
                    <Notification />
                    <InformationModal />
                  
                </StyledEngineProvider>
              </BackEndDataProvider>
            </JiraDataProvider>
          </AuthProvider>
        </AppConfigProvider>
      </ReduxStoreProvider>
    );
  }
}

export default function AppWrappedInErrorBoundary() {
  return (
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  );
}
