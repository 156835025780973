export const OFFICE_LOCATIONS = 'officeLocations';
export const COUNTRY_SETTINGS = 'countrySettings';
export const TABLET_MODELS = 'tabletModels';
export const DOCKING_STATIONS = 'dockingStations';
export const COMPUTER_MODELS = 'computerModels';
export const MONITORS = 'monitors';
export const PREFERRED_LANGUAGES = 'preferredLanguages';
export const APPLICATIONS = 'applications';
export const COUNTRY_POWER_PLUGS = 'countryPowerPlugs';
export const MOUSES = 'mouses';
export const COUNTRY_KEYBOARDS = 'countryKeyboards';
export const PHONE_MODELS = 'phoneModels';
export const KEYBOARDS = 'keyboards';
export const OUTBOUND_CALL_NUMBERS = 'outboundCallNumbers';
export const DELIVERY_ADDRESSES = 'deliveryAddresses';
export const COMPANY_LIST = 'companyList';
