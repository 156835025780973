import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Button from '@mui/material/Button';
import { TreeItemPropTypes } from './propTypes';
import './Tree.scss';

export const Tree = ({ data, handleToggle, handleSelect }) => (
  <ul className="select-tree__list">
    {data.map(({ id, name, isSelected, isOpen, children }) => (
      <li
        key={id}
        className={classnames('select-tree__item', {
          'select-tree__item--is-open': isOpen,
        })}
      >
        <IconButton
          onClick={(e) => handleToggle(e, id)}
          className={classnames('select-tree__icon-btn', {
            'select-tree__icon-btn--no-children': !children.length,
          })}
          TouchRippleProps={{
            classes: {
              root: 'select-tree__ripple',
              rippleVisible: 'select-tree__ripple--visible',
              child: 'select-tree__ripple-child',
            },
          }}
          disabled={!children.length}
        >
          {isOpen ? (
            <KeyboardArrowDownIcon className="select-tree__icon" />
          ) : (
            <KeyboardArrowRightIcon className="select-tree__icon" />
          )}
        </IconButton>

        <Checkbox
          name={`name-${id}`}
          checked={isSelected}
          onChange={(e) => handleSelect(e, id)}
          TouchRippleProps={{
            classes: {
              root: 'select-tree__ripple',
              rippleVisible: 'select-tree__ripple--visible',
              child: 'select-tree__ripple-child',
            },
          }}
          classes={{
            root: 'select-tree__checkbox',
          }}
          checkedIcon={<CheckBoxIcon className="select-tree__icon" />}
          icon={<CheckBoxOutlineBlankIcon className="select-tree__icon" />}
        />
        <Button
          onClick={(e) => handleToggle(e, id)}
          variant="text"
          classes={{
            root: 'select-tree__item-btn',
            text: 'select-tree__item-btn-label',
            disabled: 'select-tree__item-btn--disabled',
          }}
          disabled={!children.length}
        >
          {name}
        </Button>
        {children && (
          <Tree
            data={children}
            isOpen={isOpen}
            handleToggle={handleToggle}
            handleSelect={handleSelect}
          />
        )}
      </li>
    ))}
  </ul>
);

Tree.propTypes = {
  data: PropTypes.arrayOf(TreeItemPropTypes),
  handleToggle: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
};
