import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isPermitted, getPermissions } from './permissions';

class Access extends React.Component {
  static propTypes = {
    to: PropTypes.any,
    rule: PropTypes.any,
    children: PropTypes.func,
    permissions: PropTypes.any,
  };

  render() {
    const { to, rule, children, permissions, ...otherProps } = this.props;
    return children(isPermitted(rule, to, permissions), otherProps);
  }
}

const mapStateToProps = (state) => ({
  permissions: getPermissions(state.auth),
});

export default connect(mapStateToProps)(Access);
