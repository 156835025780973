import React, { Component } from 'react';
import propTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Card from '../../../shared/components/Card';
import './TeamMemberItem.scss';

class TeamMemberItem extends Component {
  render() {
    const {
      manager,
    } = this.props;

    const managerFullName = `${manager.firstName || ''} ${manager.lastName || ''}`;

    return (
      <Card className="team-member-item-card">
        <div className="team-member-item-card__title">
          <span
            className="team-member-item-card__title-text"
            title={managerFullName}
          >
            {managerFullName}
          </span>
        </div>
        <div
          className="team-member-item-card__content"
          title={manager.roleRef?.name}>
          {manager.roleRef?.name}
        </div>
      </Card>
    );
  }
}

TeamMemberItem.propTypes = {
  manager: propTypes.shape({
    id: propTypes.string,
    firstName: propTypes.string,
    lastName: propTypes.string,
    roleRef: propTypes.shape({
      id: propTypes.string,
      name: propTypes.string,
    })
  }),
};

export default withTranslation()(TeamMemberItem);
