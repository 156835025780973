import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import classnames from 'classnames';

import TextField from '@mui/material/TextField';
import InputComponent from './InputComponent';

export default class Control extends PureComponent {
  state = {
    focused: false,
  };

  handleBlur = () => {
    this.setState({ focused: false });
  };

  handleFocus = () => {
    this.setState({ focused: true });
  };

  render() {
    const { focused } = this.state;
    const { selectProps, getValue, children, innerProps } = this.props;

    const value = get('0.value')(getValue()) || '';
    const { error } = selectProps;

    const labelClassName = classnames('autocomplete-field__label', {
      'autocomplete-field__label--error': error,
      'autocomplete-field__label--filled': !focused && value,
      'autocomplete-field__label--focused': focused && !error,
    });

    const underlineClassName = classnames({
      'autocomplete-field__underline': !error,
      'autocomplete-field__underline--error': error,
    });

    return (
      <TextField
       variant="standard"
        label={selectProps.label}
        value={value}
        error={!!error}
        fullWidth
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        InputLabelProps={{
          classes: {
            root: labelClassName,
            shrink: `autocomplete-field__label--shrinked`,
          },
        }}
        InputProps={{
          classes: { underline: underlineClassName },
          inputComponent: InputComponent,
          inputProps: {
            children,
            ...innerProps,
          },
        }}
        {...selectProps.textFieldProps}
      />
    );
  }
}

Control.propTypes = {
  label: PropTypes.string,
  getValue: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};
