import { Permissions } from '../Access/constants';

const links = [
  {
    text: 'myProfile',
    url: '/staff/me',
    permission: Permissions.ReadOwnProfile,
  },
  {
    text: 'myTeam',
    url: '/team',
    permission: Permissions.MyTeam,
  },
  {
    text: 'organizationalStructure',
    url: '/organization',
    permission: Permissions.OrganizationalStructure,
  },
  {
    text: 'departmentalStructure',
    url: '/department',
    permission: Permissions.DepartmentalStructure,
  },
  {
    text: 'catalog',
    url: '/staff',
  },
  {
    text: 'locations',
    url: '/locations',
  },
  {
    text: 'companies',
    url: '/companies',
  },
  {
    text: 'roles',
    url: '/roles',
  },
];

export default links;
