import React from 'react';
import './StaffAppLink.scss';

const StaffAppLink = () => (
  <a
    href="mailto:staffapp@akelius.com"
    className="staff-app-link"
    rel="noopener noreferrer"
  >
    staffapp@akelius.com
  </a>
);

export default StaffAppLink;
