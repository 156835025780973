import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { handleGetOrgItems } from '../Organization/reducer';
import { getOrganization } from '../../shared/helpers/common';
import { handleGetSubordinates, handleGetTeamManager } from './reducer';
import PageHead from '../../shared/components/PageHead';
import Team from './Team';
import Spinner from '../../shared/components/Spinner';
import { employeePropTypes } from './propTypes';

class TeamContainer extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    authUserId: PropTypes.string.isRequired,
    handleGetTeamManager: PropTypes.func.isRequired,
    handleGetSubordinates: PropTypes.func.isRequired,
    handleGetOrgItems: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isTeamManagerFetching: PropTypes.bool.isRequired,
    subordinates: PropTypes.arrayOf(
      PropTypes.shape({ employeePropTypes }).isRequired,
    ),
    teamManager: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        businessDivision: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.object,
        ]),
        companyStreet: PropTypes.string,
        companyCity: PropTypes.string,
        email: PropTypes.string,
        businessDivisionId: PropTypes.string,
      }),
    ]),
    orgItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  componentDidMount = () => {
    const { authUserId } = this.props;
    this.props.handleGetTeamManager(authUserId);
    this.props.handleGetSubordinates(authUserId);
    this.props.handleGetOrgItems();
  };

  render() {
    const {
      isFetching,
      isTeamManagerFetching,
      subordinates,
      teamManager,
      t,
      orgItems,
    } = this.props;

    const organization =
      teamManager && orgItems.length
        ? getOrganization(teamManager.businessDivisionId, orgItems)
        : null;

    const businessDivisionName =
      organization && organization.name ? organization.name : '-';

    const transformedManager = teamManager && {
      ...teamManager,
      businessDivisionId: businessDivisionName,
     };

    return (
      <div>
        <PageHead text={t('myTeam')} />
        <Spinner isLoading={isFetching || isTeamManagerFetching}>
          <Team
            teamManager={transformedManager}
            subordinates={subordinates}
            orgItems={orgItems}
          />
        </Spinner>
      </div>
    );
  }
}

function mapStateToProps({ auth, team, organization }) {
  const { isFetching, isTeamManagerFetching, subordinates, teamManager } = team;
  const orgItems = organization.items;
  return {
    orgItems,
    authUserId: auth.id,
    isFetching,
    isTeamManagerFetching,
    subordinates,
    teamManager,
  };
}

export default connect(mapStateToProps, {
  handleGetOrgItems,
  handleGetSubordinates,
  handleGetTeamManager,
})(withTranslation()(TeamContainer));
