import { compose, map, groupBy, toPairs, sortBy } from 'lodash/fp';
import { t } from '../../i18n';

const getDefaultBusinessDivisionName = (subordinate, orgItems = []) =>
  orgItems.find((orgItem) => orgItem.id === subordinate.businessDivisionId)
    ?.name || t('employeesWithoutOrganizationItemBelonging');

export const isItemWithinHierarchy = (
  item = {},
  arrOfItems = [],
  currentUserId = '',
) => {
  const parent = arrOfItems.find((i) => i.id === item.parentId) || {};
  if (parent.managerId === undefined) {
    return false;
  }
  if (parent.managerId === currentUserId) {
    return true;
  }
  if (parent.managerId !== currentUserId && parent.parentId === null) {
    return false;
  }
  if (parent.managerId !== currentUserId && parent.parentId !== null) {
    return isItemWithinHierarchy(parent, arrOfItems, currentUserId);
  }
  return false;
};

export const getItemsWithinHierarchy = (
  allOrgItems,
  allOrgItemsManagedByThisSubordinate,
  currentUserId,
) => allOrgItemsManagedByThisSubordinate.filter(
    (orgItemManagedByThisSubordinate) =>
      isItemWithinHierarchy(
        orgItemManagedByThisSubordinate,
        allOrgItems,
        currentUserId,
      ),
  );

export const getBusinessDivisionName = (
  subordinate = {},
  orgItems = [],
  currentUserId,
) => {
  const isSubordinateManger = subordinate.isManager === true;

  if (!isSubordinateManger) {
    return getDefaultBusinessDivisionName(subordinate, orgItems);
  }

  const allOrgItemsManagedByThisSubordinate = orgItems.filter(
    (orgItem) => orgItem.managerId === subordinate.id,
  );

  if (!allOrgItemsManagedByThisSubordinate.length) {
    return getDefaultBusinessDivisionName(subordinate, orgItems);
  }

  const organizationItemsWithinCurrentUserHierarchy = getItemsWithinHierarchy(
    orgItems,
    allOrgItemsManagedByThisSubordinate,
    currentUserId,
  );

  if (!organizationItemsWithinCurrentUserHierarchy.length) {
    return getDefaultBusinessDivisionName(subordinate, orgItems);
  }

  return sortBy(['name'])(organizationItemsWithinCurrentUserHierarchy)[0].name;
};

export const getComposedSubordinatesData = (
  subordinates,
  orgItems,
  currentUserId,
) =>
  compose(
    map((entry) => ({
      orgItemName: entry[0],
      members: sortBy(['firstName', 'lastName'])(entry[1]),
    })),
    toPairs,
    groupBy('orgItem'),
    map((entity) => ({
      id: entity?.id || '-',
      firstName: entity?.firstName || '-',
      lastName: entity?.lastName || '-',
      title: entity?.title || '-',
      businessDivision: getDefaultBusinessDivisionName(entity, orgItems),
      businessDivisionId: getBusinessDivisionName(
        entity,
        orgItems,
        currentUserId,
      ),
      orgItem: getBusinessDivisionName(entity, orgItems, currentUserId),
      companyStreet: entity?.companyStreet || '-',
      companyCity: entity?.companyCity || '-',
      email: entity?.email || '-',
     })),
  )(subordinates);
