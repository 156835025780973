import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { handleHeaders } from './reducer';
import Spinner from '../Spinner';
import { allowedHeaders } from './constants';
import { useApplicationHeaders } from './applicationHeadersHook';

const AppConfigProvider = (props) => {
  const {
    isFetching: headersAreFetching,
    error: headersError,
    data: headers,
  } = useApplicationHeaders(allowedHeaders);

  if (headersAreFetching) {
    return <Spinner isLoading={true} isCoveringPage={true} />;
  }

  if (!headersError) {
    props.handleHeaders(headers);
    return props.children;
  }

  return <h1>HEADERS ERROR!!!</h1>;
};

AppConfigProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,

  handleHeaders: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  handleHeaders,
};

export default connect(null, mapDispatchToProps)(AppConfigProvider);
