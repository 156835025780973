import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StaffCatalogEmployeeCard from '../StaffCatalogEmployeeCard';
import Spinner from '../../../shared/components/Spinner';
import { DEFAULT_LIMIT, getSearchQuery, isSearchModeEnabled } from './helpers';
import Grid from '../../../shared/components/Grid';
import './StaffCatalogPanel.scss';

class StaffCatalogPanel extends Component {
  constructor() {
    super();
    this.observerRef = React.createRef();

    this.intersectionObserver = new IntersectionObserver(this.observerHandler, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });
  }

  componentDidMount() {
    this.toggleObserveTarget();
  }

  componentDidUpdate() {
    this.toggleObserveTarget();
  }

  componentWillUnmount() {
    this.intersectionObserver.disconnect();
  }

  toggleObserveTarget = (action = true, target = null) => {
    if (!target && !this.observerRef.current) {
      return;
    }
    if (action) {
      this.intersectionObserver.observe(target || this.observerRef.current);
    } else {
      this.intersectionObserver.unobserve(target || this.observerRef.current);
    }
  };

  observerHandler = ([entry]) => {
    const {
      loading,
      employeesCount,
      getCatalogEmployees,
      getSearchedEmployees,
      formValues,
      offset,
    } = this.props;

    const { isIntersecting, intersectionRatio, target } = entry;

    if (employeesCount - offset < DEFAULT_LIMIT) {
      return;
    }

    if (isIntersecting && intersectionRatio === 1) {
      this.toggleObserveTarget(false, target);

      if (!loading) {
        this.props.setOffset(offset + DEFAULT_LIMIT, async (updatedOffset) => {
          isSearchModeEnabled(getSearchQuery(formValues))
            ? await getSearchedEmployees(
                getSearchQuery(formValues),
                updatedOffset,
              )
            : await getCatalogEmployees(updatedOffset);
          this.toggleObserveTarget();
        });
      }
    }
  };

  render() {
    const { t, employees, loading, employeesCount } = this.props;

    return (
      <>
        <div className="staff-catalog-panel">
          <div className="staff-catalog-panel__total-count">{`${t(
            'staff',
          )} ${employeesCount}`}</div>
          <Grid container>
            {employees.map((employee) => (
                <Grid item lg={6} md={6} sm={12} xs={12} key={employee.id}>
                  <StaffCatalogEmployeeCard
                    employee={employee}
                    ref={this.observerRef}
                  />
                </Grid>
              ))}
          </Grid>
        </div>
        <Spinner isLoading={loading} />
      </>
    );
  }
}

StaffCatalogPanel.propTypes = {
  t: PropTypes.func.isRequired,
  employees: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  employeesCount: PropTypes.number.isRequired,
  getCatalogEmployees: PropTypes.func.isRequired,
  getSearchedEmployees: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  offset: PropTypes.number.isRequired,
  setOffset: PropTypes.func.isRequired,
};

export default StaffCatalogPanel;
