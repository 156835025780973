import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FileUploadDropZone } from './FileUploadDropZone';

export const FileUploadDropZoneField = ({ name, ...rest }) => <Field name={name} component={FileUploadDropZone} {...rest} />;

FileUploadDropZoneField.propTypes = {
  name: PropTypes.string.isRequired,
};
