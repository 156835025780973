import { find } from 'lodash/fp';

export const getOrganizationTypesSchema = ({
  branch,
  departments,
  developmentTeam,
  city,
  operations,
  root,
  supportFunction,
  team,
  unit,
}) => ({
  [root]: [departments, operations],
  [departments]: [unit, team, departments, developmentTeam],
  [operations]: {
    region: {
      true: [city, supportFunction],
    },
    country: {
      true: [unit, branch],
    },
    default: [],
  },
  [unit]: [team, developmentTeam],
  [developmentTeam]: [developmentTeam],
  [team]: [team],
  [branch]: [team],
  [city]: {
    country: {
      true: [branch, supportFunction],
    },
    default: [],
  },
  [supportFunction]: {
    country: {
      true: [team],
    },
    default: [team],
  },
});

export const getAllowedChildrenOrgItemTypes = (
  orgItem = {},
  orgTree,
  organizationItemTypeMap,
) => {
  if (!organizationItemTypeMap) {
    return [];
  }
  if (!orgItem.typeId) {
    return [organizationItemTypeMap.root];
  }

  const orgTypeSchema =
    getOrganizationTypesSchema(organizationItemTypeMap)[orgItem.typeId] || [];
  if (Array.isArray(orgTypeSchema)) {
    return orgTypeSchema;
  }

  const parentOrgItem = find({ id: orgItem.parentId })(orgTree);
  const result = Object.keys(orgTypeSchema).map((key) => {
    switch (key) {
      case 'name':
        return orgTypeSchema[key][orgItem.name.toLowerCase()];
      case 'region':
        return orgTypeSchema[key][!!orgItem.region];
      case 'country':
        return orgTypeSchema[key][!!orgItem.country];
      case 'parent':
        if (
          typeof orgTypeSchema[key].typeId[parentOrgItem.typeId] === 'function'
        ) {
          return orgTypeSchema[key].typeId[parentOrgItem.typeId](parentOrgItem);
        }
        return orgTypeSchema[key].typeId[parentOrgItem.typeId];
      default:
        return undefined;
    }
  });

  const [types] = result.filter((item) => item);
  if (types) {
    return types;
  }

  return orgTypeSchema.default;
};

export const buildOrgItemTypes = (
  parentItemId,
  items,
  organizationItemTypeMap,
) => {
  const parent = find({ id: parentItemId })(items);

  const allowedChildrenOrgItemTypes = getAllowedChildrenOrgItemTypes(
    parent,
    items,
    organizationItemTypeMap,
  );

  return allowedChildrenOrgItemTypes.map((typeId) => ({
    text: typeId,
    value: typeId,
  }));
};