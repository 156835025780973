import { isOrganizationAlreadyMapped, isNotUniqueName } from './helpers';
import { t } from '../../../i18n';

export default function submitValidation(
  { name, id, rootId },
  editableId,
  departmentItems,
) {
  const errors = {};

  if (!name) {
    errors.name = t('required');
  }

  if (editableId && !id) {
    errors.id = t('required');
  }

  if (!rootId) {
    errors.rootId = t('required');
  }

  if (isOrganizationAlreadyMapped(editableId, departmentItems)) {
    errors.name = t('errorAddNewDepartmentItemUniqueOrganization', { name });
  }

  if (name && isNotUniqueName(name, editableId, departmentItems)) {
    errors.name = t('errorAddNewDepartmentItemUniqueName', { name });
  }

  return errors;
}
