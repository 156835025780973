import React from 'react';
import { createActions, handleActions } from 'redux-actions';
import ClientService from '../../services/client';
import { handleNotification } from '../../shared/components/Notification/reducer';
import StaffAppLink from '../../shared/components/StaffAppLink';

const GET_SUBORDINATES_STARTED = 'GET_SUBORDINATES_STARTED';
const GET_SUBORDINATES_SUCCESS = 'GET_SUBORDINATES_SUCCESS';
const GET_SUBORDINATES_FAILURE = 'GET_SUBORDINATES_FAILURE';

const GET_TEAM_MANAGER_STARTED = 'GET_TEAM_MANAGER_STARTED';
const GET_TEAM_MANAGER_SUCCESS = 'GET_TEAM_MANAGER_SUCCESS';
const GET_TEAM_MANAGER_FAILURE = 'GET_TEAM_MANAGER_FAILURE';

const {
  getSubordinatesStarted,
  getSubordinatesSuccess,
  getSubordinatesFailure,
  getTeamManagerStarted,
  getTeamManagerSuccess,
  getTeamManagerFailure,
} = createActions(
  GET_SUBORDINATES_STARTED,
  GET_SUBORDINATES_SUCCESS,
  GET_SUBORDINATES_FAILURE,
  GET_TEAM_MANAGER_STARTED,
  GET_TEAM_MANAGER_SUCCESS,
  GET_TEAM_MANAGER_FAILURE,
);

export function handleGetTeamManager(id) {
  return async (dispatch) => {
    dispatch(getTeamManagerStarted());
    try {
      const teamManager = await ClientService.instance.fetch(
        `/employees/${id}`,
      );
      dispatch(getTeamManagerSuccess(teamManager));
    } catch (err) {
      dispatch(getTeamManagerFailure(err));
      dispatch(
        handleNotification({
          message: 'errorGeneralApi',
          addon: <StaffAppLink />,
          type: 'error',
          isActive: true,
        }),
      );
    }
  };
}

export function handleGetSubordinates(id) {
  return async (dispatch) => {
    dispatch(getSubordinatesStarted());
    try {
      const subordinates = await ClientService.instance.fetch(
        `/employees/${id}/subordinates`,
      );
      dispatch(getSubordinatesSuccess(subordinates));
    } catch (err) {
      dispatch(getSubordinatesFailure(err));
      dispatch(
        handleNotification({
          message: 'errorGeneralApi',
          addon: <StaffAppLink />,
          type: 'error',
          isActive: true,
        }),
      );
    }
  };
}

export default handleActions(
  {
    GET_SUBORDINATES_STARTED: (state) => ({
      ...state,
      isFetching: true,
    }),

    GET_SUBORDINATES_SUCCESS: (state, action) => ({
      ...state,
      subordinates: action.payload,
      isFetching: false,
    }),

    GET_SUBORDINATES_FAILURE: (state, action) => ({
      ...state,
      isFetching: false,
      errorMessage: action.payload,
    }),

    GET_TEAM_MANAGER_STARTED: (state) => ({
      ...state,
      teamMangerIsFetching: true,
    }),
    GET_TEAM_MANAGER_SUCCESS: (state, action) => ({
      ...state,
      teamManager: action.payload,
      teamMangerIsFetching: false,
    }),
    GET_TEAM_MANAGER_FAILURE: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      teamMangerIsFetching: false,
    }),
  },
  {
    subordinates: [],
    isFetching: false,
    teamManager: null,
    isTeamManagerFetching: false,
  },
);
