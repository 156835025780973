import { createAction, handleActions } from 'redux-actions';
import {
  COUNTRY_SETTINGS,
  OFFICE_LOCATIONS,
  TABLET_MODELS,
  DOCKING_STATIONS,
  COMPUTER_MODELS,
  MONITORS,
  PREFERRED_LANGUAGES,
  APPLICATIONS,
  COUNTRY_POWER_PLUGS,
  MOUSES,
  COUNTRY_KEYBOARDS,
  PHONE_MODELS,
  KEYBOARDS,
  OUTBOUND_CALL_NUMBERS,
  DELIVERY_ADDRESSES,
  COMPANY_LIST
} from './constants';

const officeLocationsHandler = createAction(OFFICE_LOCATIONS);
const countrySettingsHandler = createAction(COUNTRY_SETTINGS);
const tabletModelsHandler = createAction(TABLET_MODELS);
const dockingStationsHandler = createAction(DOCKING_STATIONS);
const computerModelsHandler = createAction(COMPUTER_MODELS);
const monitorsHandler = createAction(MONITORS);
const preferredLanguageHandler = createAction(PREFERRED_LANGUAGES);
const applicationsHandler = createAction(APPLICATIONS);
const countryPowerPlugsHandler = createAction(COUNTRY_POWER_PLUGS);
const mousesHandler = createAction(MOUSES);
const countryKeyboardsHandler = createAction(COUNTRY_KEYBOARDS);
const phoneModelsHandler = createAction(PHONE_MODELS);
const keyboardsHandler = createAction(KEYBOARDS);
const outboundCallNumbersHandler = createAction(OUTBOUND_CALL_NUMBERS);
const deliveryAddressesHandler = createAction(DELIVERY_ADDRESSES);
const companyListHandler = createAction(COMPANY_LIST);

export function handleOfficeLocations(officeLocations) {
  return (dispatch) => dispatch(officeLocationsHandler(officeLocations));
}
export function handleTabletModels(tabletModels) {
  return (dispatch) => dispatch(tabletModelsHandler(tabletModels));
}
export function handleDockingStations(tabletModels) {
  return (dispatch) => dispatch(dockingStationsHandler(tabletModels));
}
export function handleComputerModels(computerModels) {
  return (dispatch) => dispatch(computerModelsHandler(computerModels));
}
export function handleCountrySettings(officeLocations) {
  return (dispatch) => dispatch(countrySettingsHandler(officeLocations));
}
export function handleMonitors(monitors) {
  return (dispatch) => dispatch(monitorsHandler(monitors));
}
export function handlePreferredLanguages(monitors) {
  return (dispatch) => dispatch(preferredLanguageHandler(monitors));
}
export function handleApplications(applications) {
  return (dispatch) => dispatch(applicationsHandler(applications));
}
export function handleCountryPowerPlugs(applications) {
  return (dispatch) => dispatch(countryPowerPlugsHandler(applications));
}
export function handleMouses(mouses) {
  return (dispatch) => dispatch(mousesHandler(mouses));
}
export function handleCountryKeyboards(countryKeyboards) {
  return (dispatch) => dispatch(countryKeyboardsHandler(countryKeyboards));
}
export function handlePhoneModels(phoneModels) {
  return (dispatch) => dispatch(phoneModelsHandler(phoneModels));
}
export function handleKeyboards(phoneModels) {
  return (dispatch) => dispatch(keyboardsHandler(phoneModels));
}
export function handleOutboundCallNumbers(phoneModels) {
  return (dispatch) => dispatch(outboundCallNumbersHandler(phoneModels));
}
export function handleDeliveryAddresses(phoneModels) {
  return (dispatch) => dispatch(deliveryAddressesHandler(phoneModels));
}
export function handleCompanyList(companyList) {
  return (dispatch) => dispatch(companyListHandler(companyList));
}

const initialState = {
  [OFFICE_LOCATIONS]: null,
  [COUNTRY_SETTINGS]: null,
  [TABLET_MODELS]: null,
  [DOCKING_STATIONS]: null,
  [COMPUTER_MODELS]: null,
  [MONITORS]: null,
  [PREFERRED_LANGUAGES]: null,
  [APPLICATIONS]: null,
  [COUNTRY_POWER_PLUGS]: null,
  [MOUSES]: null,
  [COUNTRY_KEYBOARDS]: null,
  [PHONE_MODELS]: null,
  [KEYBOARDS]: null,
  [OUTBOUND_CALL_NUMBERS]: null,
  [DELIVERY_ADDRESSES]: null,
  [COMPANY_LIST]: null
};

export default handleActions(
  {
    [OFFICE_LOCATIONS]: (state, action) => ({
      ...state,
      officeLocations: action.payload,
    }),
    [COUNTRY_SETTINGS]: (state, action) => ({
      ...state,
      countrySettings: action.payload,
    }),
    [TABLET_MODELS]: (state, action) => ({
      ...state,
      tabletModels: action.payload,
    }),
    [DOCKING_STATIONS]: (state, action) => ({
      ...state,
      dockingStations: action.payload,
    }),
    [COMPUTER_MODELS]: (state, action) => ({
      ...state,
      computerModels: action.payload,
    }),
    [MONITORS]: (state, action) => ({
      ...state,
      monitors: action.payload,
    }),
    [PREFERRED_LANGUAGES]: (state, action) => ({
      ...state,
      preferredLanguages: action.payload,
    }),
    [APPLICATIONS]: (state, action) => ({
      ...state,
      applications: action.payload,
    }),
    [COUNTRY_POWER_PLUGS]: (state, action) => ({
      ...state,
      countryPowerPlugs: action.payload,
    }),
    [MOUSES]: (state, action) => ({
      ...state,
      mouses: action.payload,
    }),
    [COUNTRY_KEYBOARDS]: (state, action) => ({
      ...state,
      countryKeyboards: action.payload,
    }),
    [PHONE_MODELS]: (state, action) => ({
      ...state,
      phoneModels: action.payload,
    }),
    [KEYBOARDS]: (state, action) => ({
      ...state,
      keyboards: action.payload,
    }),
    [OUTBOUND_CALL_NUMBERS]: (state, action) => ({
      ...state,
      outboundCallNumbers: action.payload,
    }),
    [DELIVERY_ADDRESSES]: (state, action) => ({
      ...state,
      deliveryAddresses: action.payload,
    }),
    [COMPANY_LIST]: (state, action) => ({
      ...state,
      companyList: action.payload,
    }),
  },
  { ...initialState },
);
