import React from 'react';
import propTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import {
  handleMoveItemModalClose,
  handleMoveOrganizationItem,
  handleGetOrgItemsBelonging,
} from '../reducer';
import MoveItemModal from './reduxForm';
import { formatOrgItems } from '../OrganizationItem/helpers';
import { akeliusBusinessDivisionContext } from '../../../shared/components/AkeliusBusinessDivision/context';
import { handleNotification } from '../../../shared/components/Notification/reducer';
import StaffAppLink from '../../../shared/components/StaffAppLink';

export const MoveItemModalContainer = ({
  isModalOpen,
  isModalLoading,
  organizationItems,
  // eslint-disable-next-line no-shadow
  handleMoveItemModalClose,
  // eslint-disable-next-line no-shadow
  handleMoveOrganizationItem,
  // eslint-disable-next-line no-shadow
  handleGetOrgItemsBelonging,
  // eslint-disable-next-line no-shadow
  handleNotification,
  targetMoveItem,
  parentItemId,
  t,
}) => {
  const context = React.useContext(akeliusBusinessDivisionContext);

  const handleModalSubmit = async () => {
    if (context.submitHandler) {
      const parentId = context.selectedId;
      context.submitHandler(context.selectedId);
      context.setSelectedId(null);
      context.setSubmitHandler(null);

      const previousParentId = organizationItems.find(
        (item) => item.id === parentItemId,
      );
      const nextParent = organizationItems.find((item) => item.id === parentId);
      try {
        if (targetMoveItem.id === parentId ||  previousParentId.id === parentId) {
          await handleNotification({
            message: 'The unit already belongs to: {{name}}',
            interpolate: {
              name: targetMoveItem.name,
            },
            addon: <StaffAppLink />,
            type: 'error',
            isActive: true,
          });
        } 
        else {
        await handleMoveOrganizationItem(targetMoveItem.id, parentId);
        await handleGetOrgItemsBelonging();

        await handleNotification({
          message: 'organizationItemMoveSuccessMessage',
          interpolate: {
            name: targetMoveItem.name,
            root: previousParentId?.name || 'Root',
            destination: nextParent?.name || 'Root',
          },
          type: 'success',
          isActive: true,
        });
      }
      } catch (error) {
        await handleNotification({
          message: 'organizationErrorMessage',
          addon: <StaffAppLink />,
          type: 'error',
          isActive: true,
        });
      }
    }
  };
  const handleModalClose = () => handleMoveItemModalClose();
  return (
    <MoveItemModal
      modalTitle={'move item'}
      callToActionText={t('move')}
      isOpen={isModalOpen}
      isModalLoading={isModalLoading}
      onClose={handleModalClose}
      handleSubmit={handleModalSubmit}
      organizationItems={organizationItems}
    />
  );
};

const mapStateToProps = (state) => ({
  organizationItems: formatOrgItems(state.organization.items),
  isModalLoading: state.organization.isMoveModalFetching,
  targetMoveItem: state.organization.targetMoveItem,
  parentItemId: state.organization.parentItemId,
});

export default connect(mapStateToProps, {
  handleMoveItemModalClose,
  handleMoveOrganizationItem,
  handleGetOrgItemsBelonging,
  handleNotification,
})(withTranslation()(MoveItemModalContainer));

MoveItemModalContainer.propTypes = {
  isModalOpen: propTypes.bool.isRequired,
  isModalLoading: propTypes.bool.isRequired,
  handleMoveItemModalClose: propTypes.func.isRequired,
  handleMoveOrganizationItem: propTypes.func.isRequired,
  handleGetOrgItemsBelonging: propTypes.func.isRequired,
  handleNotification: propTypes.func.isRequired,
  organizationItems: propTypes.array,
  t: propTypes.func.isRequired,
  targetMoveItem: propTypes.object,
  parentItemId: propTypes.string,
};
