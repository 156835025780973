const formatOrgItems = (items) =>
  items.map((orgItem) => ({
    id: orgItem.id,
    text: orgItem.name,
    name: orgItem.name,
    type: orgItem.type,
    value: orgItem.name,
    region: orgItem.region,
    country: orgItem.country,
    parentId: orgItem.parentId,
    managerId: orgItem.managerId,
    departmentName:
      orgItem.department && orgItem.department.name
        ? orgItem.department.name
        : null,
  }));
export { formatOrgItems };
