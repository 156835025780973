import React, { Component } from 'react';
import { Field, fieldPropTypes, change } from 'redux-form';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import Icon from '../Icon';
import { getParsedErrorMessage } from '../../utils/languageUtils';
import IconButton from '../IconButton';
import './TextInputStyle.scss';

class WrappedTextInput extends Component {
  static propTypes = {
    ...fieldPropTypes,
  };

  constructor() {
    super();
    this.inputRef = null;
  }

  handleClearField = () => {
    const { form, dispatch } = this.props.meta;
    const { name } = this.props.input;
    dispatch(change(form, name, ''));
    this.inputRef && this.inputRef.focus();
  };

  render() {
    const {
      meta,
      input,
      disabled,
      multiline,
      t,
      tReady,
      autoFocus,
      endAdornment,
      searchField,
      autoSearch,
      ...props
    } = this.props;

    const isError = !!(meta.invalid && meta.error);

    return (
      <div
        className={classnames('text-field', {
          'text-field--search-field': searchField,
        })}
      >
        <TextField
          variant="standard"
          {...input}
          {...props}
          error={isError}
          disabled={disabled}
          multiline={multiline}
          className={classnames({
            textarea: multiline,
          })}
          inputRef={(inputNode) => {
            this.inputRef = inputNode;
          }}
          autoComplete="off"
          helperText={getParsedErrorMessage(meta.error)(t)}
          InputLabelProps={{
            classes: {
              root: classnames('text-field__label', {
                'text-field__label--error': isError,
              }),
              shrink: 'text-field__label--shrinked',
              focused: 'text-field__label--focused',
              disabled: 'text-field__label--disabled',
            },
          }}
          InputProps={{
            ...this.props.InputProps,
            autoFocus,
            classes: {
              root: multiline && 'text-field__textarea',
              underline: classnames({
                'text-field__underline': !isError,
                'text-field__underline--error': isError,
                'text-field__underline--disabled': disabled,
              }),
            },
            endAdornment: searchField && input.value && (
              <InputAdornment position="end">
                <IconButton
                  onClick={this.handleClearField}
                  className="text-field__search-clear-btn"
                >
                  <Icon icon="ak-close" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          FormHelperTextProps={{
            classes: {
              root: 'text-field__helper',
              error: 'text-field__helper--error',
            },
          }}
        />
        {searchField && (
          <IconButton disabled={autoSearch}>
            <Icon icon="ak-search" />
          </IconButton>
        )}
      </div>
    );
  }
}

const TextInputField = (props) => (
  <Field {...props} component={WrappedTextInput} />
);

export default withTranslation()(TextInputField);
