import PropTypes from 'prop-types';

const TreeItemShape = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  parentId: PropTypes.string,
};
TreeItemShape.children = PropTypes.oneOfType([
  PropTypes.array,
  PropTypes.arrayOf(TreeItemShape),
]);

export const TreeItemPropTypes = PropTypes.shape(TreeItemShape);
