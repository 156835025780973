import PropTypes from 'prop-types';
import React from 'react';
import './MenuGroupLabel.scss';

const MenuGroupLabel = (props) => {
  const { text } = props;
  return <div className="menu-group-label">{text || props.children}</div>;
};

MenuGroupLabel.propTypes = {
  text: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default MenuGroupLabel;
