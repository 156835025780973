import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from '../../Icon';
import Button from '../../Button';
import './DatePickerInput.scss';

export const DatePickerInput = ({
  label,
  onCalendarInputButtonClick,
  isCalendarOpen,
  onCalendarFocus,
  onCalendarBlur,
  calendarInputValue,
  onCalendarInputChange,
  hasError,
  message,
  disabled,
  name,
}) => (
    <div className="datepicker-input">
      <TextField
        variant="standard"
        name={name}
        label={label}
        placeholder={label}
        value={calendarInputValue}
        error={hasError}
        fullWidth={true}
        helperText={message}
        disabled={disabled}
        onFocus={onCalendarFocus}
        onBlur={onCalendarBlur}
        onChange={onCalendarInputChange}
        FormHelperTextProps={{
          classes: {
            root: 'datepicker-input__helper-text',
            error: 'datepicker-input__helper-text--error',
          },
        }}
        InputLabelProps={{
          classes: {
            root: classnames('datepicker-input__label', {
              'datepicker-input__label--error': hasError,
            }),
            shrink: 'datepicker-input__label--shrinked',
            focused: 'datepicker-input__label--focused',
          },
        }}
        InputProps={{
          classes: {
            underline: classnames({
              'datepicker-input__underline': !hasError,
              'datepicker-input__underline--error': hasError,
              'datepicker-input__underline--disabled': disabled,
            }),
          },
          endAdornment: (
            <InputAdornment position="end">
              <Button
                disabled={disabled}
                onClick={onCalendarInputButtonClick}
                className={classnames('datepicker-input__btn', {
                  'datepicker-input__btn--active': isCalendarOpen,
                })}
              >
                <Icon
                  icon="ak-event"
                  className={classnames('datepicker-input__icon', {
                    'datepicker-input__icon--focused': isCalendarOpen,
                    'datepicker-input__icon--error': hasError,
                    'datepicker-input__icon--disabled': disabled,
                  })}
                />
              </Button>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );

DatePickerInput.propTypes = {
  label: propTypes.string.isRequired,
  onCalendarInputButtonClick: propTypes.func.isRequired,
  isCalendarOpen: propTypes.bool.isRequired,
  onCalendarFocus: propTypes.func.isRequired,
  onCalendarBlur: propTypes.func.isRequired,
  calendarInputValue: propTypes.string.isRequired,
  onCalendarInputChange: propTypes.func.isRequired,
  hasError: propTypes.bool,
  message: propTypes.string,
  disabled: propTypes.bool,
  name: propTypes.string.isRequired,
};
