import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import './AkeliusToolTip.scss';

export default function AkeliusToolTip({ children, title, placement }) {
  if (!children) return null;
  if (children.props.disabled) return children;
  return (
    <Tooltip
      title={title}
      arrow
      placement={placement}
      classes={{
        tooltip: 'ak-tool-tip',
        arrow: 'ak-tool-tip__arrow',
      }}
    >
      <div>{children}</div>
    </Tooltip>
  );
}

AkeliusToolTip.defaultProps = {
  title: '',
  placement: 'top',
};

AkeliusToolTip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  placement: PropTypes.string.isRequired,
};
