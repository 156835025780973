import { isNotUniqueName } from './helpers';
import { t } from '../../../i18n';

export default function submitValidation(
  { name, typeId, region, country, teamId },
  editableId,
  parentItemType,
  organizationItems,
  organizationItemTypeMap,
) {
  const errors = {};

  if (!name) {
    errors.name = t('required');
  }

  if (!typeId) {
    errors.typeId = t('required');
  }

  if (typeId === organizationItemTypeMap.developmentTeam && !teamId) {
    errors.teamId = t('required');
  }

  if (
    parentItemType === organizationItemTypeMap.operations &&
    typeId === organizationItemTypeMap.city &&
    !country
  ) {
    errors.country = t('required');
  }

  if (
    parentItemType !== organizationItemTypeMap.operations &&
    typeId === organizationItemTypeMap.operations &&
    !region
  ) {
    errors.region = t('required');
  }

  if (isNotUniqueName(name, editableId, organizationItems)) {
    errors.name = t('errorAddNewOrganizationItemUniqueName', { name });
  }

  return errors;
}
