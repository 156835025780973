import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Field, fieldPropTypes } from 'redux-form';

import './CheckBox.scss';

import FormControlLabel from '@mui/material/FormControlLabel';

const WrappedCheckBox = (props) => {
  const { input, label, disabled, boldLabel, ...restOfProps } = props;

  return (
    <FormControlLabel
      {...input}
      {...restOfProps}
      checked={input.value || false}
      classes={{
        root: 'checkbox',
        disabled: 'checkbox--disabled',
        label: `checkbox__label ${boldLabel && 'checkbox__label--bold'}`,
      }}
      control={
        <Checkbox
          className="checkbox__input"
          disabled={disabled}
          color="primary"
        />
      }
      label={label}
    />
  );
};

WrappedCheckBox.propTypes = {
  ...fieldPropTypes,
};

const CheckBoxField = (props) => (
  <Field {...props} component={WrappedCheckBox} />
);

export default CheckBoxField;
