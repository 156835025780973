import createAuth0Client from '@auth0/auth0-spa-js';
import ApiError from '../../services/apiError';

class Auth0Client {
  static async create(config) {
    try {
      const client = await createAuth0Client(config);
      return new Auth0Client(client);
    } catch (err) {
      throw new ApiError({
        message: 'Authentication failed',
      });
    }
  }

  constructor(auth0Client) {
    if (!Auth0Client.instance) {
      this.auth0Client = auth0Client;
      Auth0Client.instance = this;
    }
    return Auth0Client.instance;
  }
}

export default Auth0Client;
