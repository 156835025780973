export function getParentItem(id, organizationItems) {
  return organizationItems.find((item) => item.id === id) || {};
}

export function getManager(currentItem, organizationItems, currentUserId) {
  if (currentItem && organizationItems) {
    const { manager, managerId, parentId } = currentItem;

    if (manager && managerId !== currentUserId) {
      return { name: `${manager.firstName} ${manager.lastName}`, managerId };
    }

    if (parentId) {
      const parentItem = getParentItem(parentId, organizationItems);
      return getManager(parentItem, organizationItems, currentUserId);
    }
  }

  return undefined;
}

export function getOrganization(businessDivisionId, organizations) {
  return organizations && businessDivisionId
    ? organizations.find((org) => businessDivisionId === org.id)
    : undefined;
}

export function groupRolesByCategory(rolesOptions) {
  let roles;
  if (rolesOptions) {
    const groupedRoles = {};
    rolesOptions.forEach((role) => {
      const { category } = role;
      if (!groupedRoles[category]) {
        groupedRoles[category] = [];
      }
      const item = { key: role.id, value: role.name };
      groupedRoles[category].push(item);
    });
    roles = groupedRoles;
  }
  return roles;
}
