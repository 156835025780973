import { useState, useEffect } from 'react';
import ClientService from '../../../services/client';
import ApiError from '../../../services/apiError';
import {
  getOfficeLocationsOptions,
  getApplicationOptions,
  getOptions,
  getDeliveryAddressOptions,
} from './helpers';

export const JiraProviderHook = () => {
  const [officeLocations, setOfficeLocations] = useState(null);
  const [countrySettings, setCountrySettings] = useState(null);
  const [tabletModels, setTabletModels] = useState(null);
  const [dockingStations, setDockingStations] = useState(null);
  const [computerModels, setComputerModels] = useState(null);
  const [monitors, setMonitors] = useState(null);
  const [preferredLanguages, setPreferredLanguages] = useState(null);
  const [applications, setApplications] = useState(null);
  const [countryPowerPlugs, setCountryPowerPlugs] = useState(null);
  const [mouses, setMouses] = useState(null);
  const [countryKeyboards, setCountryKeyboards] = useState(null);
  const [phoneModels, setPhoneModels] = useState(null);
  const [keyboards, setKeyboards] = useState(null);
  const [outboundCallNumbers, setOutboundCallNumbers] = useState(null);
  const [deliveryAddresses, setDeliveryAddresses] = useState(null);
  const [companyList, setCompanyList] = useState(null);

  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    ClientService.instance
      .fetch('/enumerations/')
      .then((enumerations) => {
        setOfficeLocations(getOfficeLocationsOptions(enumerations));
        setCountrySettings(getOptions(enumerations, 'Country settings'));
        setTabletModels(getOptions(enumerations, 'Tablet model'));
        setDockingStations(getOptions(enumerations, 'Docking station', false));
        setComputerModels(getOptions(enumerations, 'Computer model'));
        setMonitors(getOptions(enumerations, 'Monitor', false));
        setPreferredLanguages(getOptions(enumerations, 'Preffered language'));
        setApplications(getApplicationOptions(enumerations));
        setCountryPowerPlugs(getOptions(enumerations, 'Country power plug'));
        setMouses(getOptions(enumerations, 'Mouse', false));
        setPhoneModels(getOptions(enumerations, 'Phone model'));
        setCountryKeyboards(getOptions(enumerations, 'Country keyboard'));
        setKeyboards(getOptions(enumerations, 'Keyboard', false));
        setOutboundCallNumbers(
          getOptions(enumerations, 'Displayed number outbound call'),
        );
        setDeliveryAddresses(getDeliveryAddressOptions(enumerations));
        setCompanyList(getOptions(enumerations, 'Company list'));
      })
      .catch((err) => {
        setError(err);
        throw new ApiError({
          message: 'Jira enumerations have been failed',
        });
      })
      .finally(() => setIsFetching(false));
  }, []);

  return {
    isFetching,
    error,
    officeLocations,
    countrySettings,
    tabletModels,
    dockingStations,
    computerModels,
    monitors,
    preferredLanguages,
    applications,
    countryPowerPlugs,
    mouses,
    countryKeyboards,
    phoneModels,
    keyboards,
    outboundCallNumbers,
    deliveryAddresses,
    companyList
  };
};
