import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import classNames from 'classnames';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import { Field, fieldPropTypes } from 'redux-form';
import MenuGroupLabel from './MenuGroupLabel';
import './DropDownWithCategories.scss';

class DropDownWithCategories extends Component {
  static propTypes = {
    ...fieldPropTypes,
  };

  state = {
    isOpen: false,
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleChange = (event) => {
    const {
      input: { onChange },
    } = this.props;
    onChange && onChange(event.target.value);
  };

  getMenuItems = (groupedMenuItems) => {
    const groupLabels = Object.keys(groupedMenuItems);
    return groupLabels.map((groupLabel) => {
      const menuGroupLabel = (
        <MenuGroupLabel key={groupLabel}>{groupLabel}</MenuGroupLabel>
      );
      const menuGroupItems = groupedMenuItems[groupLabel].map((item) => (
        <MenuItem
          value={`${item.key}`}
          key={`${item.key}`}
          name={`${item.key}`}
          className="drop-down-with-categories__menu-item"
          classes={{
            selected: 'drop-down-with-categories__menu-item--selected',
          }}
        >
          {item.value}
        </MenuItem>
      ));
      return [menuGroupLabel, menuGroupItems];
    });
  };

  render() {
    const {
      meta,
      input: { name, value },
      menuItems,
      label,
      t,
    } = this.props;

    const isError = !!(meta.invalid && meta.error);

    const rootClassName = classNames({
      'drop-down-with-categories': true,
      'drop-down-with-categories--error': isError,
      
    });
    const labelClassName = 'drop-down-with-categories__label';
    const inputClassName = 'drop-down-with-categories__input';
    const selectClassName = 'drop-down-with-categories__select';

    return (
      <FormControl
        fullWidth
        error={isError}
        name={name}
        classes={{
          root: rootClassName,
        }}
        variant="standard"
        className='drop-down-with-categories__custom'
         
      >
        <InputLabel
          htmlFor={name}
          classes={{
            root: labelClassName,
            focused: `${labelClassName}--focused`,
            shrink: `${labelClassName}--shrink`,
          }}
        >
          {label}
        </InputLabel>
        <Select
          className={selectClassName}
          open={this.state.isOpen}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
          value={value}
          onChange={this.handleChange}
          inputProps={{
            name,
            className: inputClassName,
          }}
          MenuProps={{
            classes: {
              paper: 'drop-down-with-categories__paper',
              list: 'drop-down-with-categories__list',
            },
          }}
        >
          <MenuItem value="" className="drop-down-with-categories__menu-item">
            unassigned
          </MenuItem>
          {this.getMenuItems(menuItems)}
        </Select>
        {isError && (
          <FormHelperText
            classes={{
              root: 'drop-down-with-categories__helper',
              error: 'drop-down-with-categories__helper--error',
            }}
          >
            {t(meta.error)}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

const DropDownWithCategoriesField = (props) => (
  <Field {...props} component={DropDownWithCategories} />
);

export default withTranslation()(DropDownWithCategoriesField);
