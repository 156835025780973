import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import { staffProfileFieldDependencies } from '../../staffProfileFieldDependencies';
import curriedIsFieldDisabled from '../../../../../shared/helpers/isFieldDisabled';
import Access, { Permissions } from '../../../../../shared/components/Access';
import CheckBox from '../../../../../shared/components/CheckBox';
import TextInput from '../../../../../shared/components/TextInput';
import SelectField from '../../../../../shared/components/Select';
import { DropDownWithCategoriesField } from '../../../../../shared/components/DropDownWithCategories';
import { getOfficeStreet, getEmployeeTextData, getCountry } from '../../helpers';
import './StaffProfileOrganizationSection.scss';
import Grid from '../../../../../shared/components/Grid';
import AkeliusBusinessDivision from '../../../../../shared/components/AkeliusBusinessDivision';
import AkeliusDatePicker from '../../../../../shared/components/AkeliusDatePicker';
import BooleanGroupField from '../../../../../shared/components/BooleanGroup';
import { UserCategory } from '../../../../../shared/types';
import { groupRolesByCategory } from '../../../../../shared/helpers/common';

const StaffProfileOrganizationSection = (props) => {
  const {
    isPanelEditModeEnabled,
    employee,
    t,
    formValues,
    companyNameOptions,
    rolesOptions,
    preferredLanguagesOptions,
    countryOptions,
   } = props;

  let formattedCompanyNames = [];
  if (companyNameOptions) {
    formattedCompanyNames = companyNameOptions.map((e) => ({
      value: e.id,
      text: e.name,
    }));
  }
 
  const roles = groupRolesByCategory(rolesOptions);
  const isFieldDisabled = curriedIsFieldDisabled(
    staffProfileFieldDependencies,
    formValues,
  );
   const orgType = get('typeId')(employee);
  return (
    <>
       
                      <>
                        <div className="staff-profile__content">
                                    <div className="staff-profile-personal-section">
                                        <Grid
                                            container
                                            className="staff-profile-personal-section__section-line"
                                          >
                                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                                    {getEmployeeTextData(employee, 'firstName', t)}
                                                </Grid>
                                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                                    {getEmployeeTextData(employee, 'lastName', t)}
                                                </Grid>
                                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                             
                                                
                                                              <Access
                                                                rule={Permissions.ReadAndUpdateEmployeePreferredLanguageAndLeaveStatus}
                                                                to={employee}
                                                              >
                                                                {(canUpdate) => {
                                                                  if(!canUpdate) {
                                                                    return (<></>)
                                                                  }
                                                                  if(isPanelEditModeEnabled) {
                                                                   
                                                                    return (
                                                              
                                                                    <BooleanGroupField
                                                                    name="onLeave"
                                                                    label={t('onLeave')}
                                                                  />
                                                                
                                                                     
                                                                    )
                                                                  }
                                                              
                                                                   return (
                                                                        <>
                                                                        <div data-label={'onLeave'}>{t('onLeave')}</div>
                                                                        <div data-value={'onLeave'}>
                                                                          {t(get('onLeave')(employee)) || '-'}
                                                                        </div>
                                                                      </>
                                                                  );
                                                                   
                                                                }}
                                                              </Access>

                                                     
                                                </Grid>
                                              </Grid>
                                              <br/>
                                    </div>
                        </div>
                      </>
                     
 
      <div className="staff-profile-organization-section">


          <Access to={employee} rule={Permissions.UpdateEmployeeSpecialMembership}>
            {() => (
              <>


              
                  <Grid
                    container
                    className="staff-profile-organization-section__section-line"
                  >
                  <Grid item lg={4} md={4} sm={4} xs={12}>



                       <Access
                        to={employee}
                        rule={Permissions.UpdateEmployeeOrganizationInformation}
                        >
                         {(canUpdateOrg) =>  canUpdateOrg ? (
                         <Access to={employee} rule={Permissions.UpdateEmployeeJobTitle}>
                                      {(canUpdate) =>
                                        isPanelEditModeEnabled && canUpdate ? (
                                          <TextInput
                                            name="title"
                                            label={t('title')}
                                            placeholder={t('title')}
                                            fullWidth={true}
                                          />
                                        ) : (
                                          <>
                                            <div>{t('title')}</div>
                                            {get('title')(employee) || '-'}
                                          </>
                                        )
                                      }
                           </Access> ) :    <>
                                            <div>{t('title')}</div>
                                            {get('title')(employee) || '-'}
                                          </>
                            }

                      </Access>




               
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <div>{t('organizationType')}</div>
                    {orgType || '-'}
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>


                        <Access
                        to={employee}
                        rule={Permissions.UpdateEmployeeOrganizationInformation}
                        >
                        {(canUpdateOrg) =>  canUpdateOrg && isPanelEditModeEnabled? (
                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <AkeliusBusinessDivision
                                  name="businessDivisionId"
                                  formName={'staff_profile'}
                                  />
                          </Grid>
                          
                          ) :    <>
                                   <div>{t('businessDivision')}</div>
                                    <div>{employee?.businessDivision?.name || ''}</div>
                                  </>
                            }

                        </Access>

              
                  </Grid>
                  </Grid>
                  <Grid
                    container
                    className="staff-profile-organization-section__section-line"
                  >
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Access
                        to={employee}
                        rule={Permissions.UpdateEmployeeOrganizationInformation}
                        >
                        {(canUpdateOrg) =>  canUpdateOrg ? (
                        
                          <Access
                            rule={Permissions.UpdateEmployeeCompanyName}
                            to={employee}
                          >
                            {(canUpdateEmployeeRole) =>
                              canUpdateEmployeeRole && isPanelEditModeEnabled ? (
                                <SelectField
                                  name="companyId"
                                  label={t('companyName')}
                                  options={formattedCompanyNames}
                                  placeholder={t('companyName')}
                                />
                              ) : (
                                getEmployeeTextData(
                                  employee,
                                  'company.name',
                                  t,
                                  'company name',
                                )
                              )
                            }
                    </Access> 
                          ) :    <>
                                        {  getEmployeeTextData(
                                  employee,
                                  'company.name',
                                  t,
                                  'company name',
                                )}
                  </>
    }

                    </Access>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <div>{t('companyStreet')}</div>
                      {getOfficeStreet(employee)}
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                  
                   
                          <> 
                            <div className="group">
                                    <>
                                      <div data-label={'country'}>{t('country')}</div>
                                      <div data-value={'country'}>
                                          {getCountry(employee, countryOptions)}
                                      </div>
                                    </>
                            </div>
                          
                          </>
                       
                    </Grid>
                    
                  </Grid>
                  <Grid
                    container
                    className="staff-profile-organization-section__section-line"
                  >
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <div>{t('email')}</div>
                      {get('email')(employee) || '-'}
                    </Grid>
                  
                  </Grid>
                  <Grid
                    container
                    className="staff-profile-organization-section__section-line"
                  >
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Access
                        to={employee}
                        rule={Permissions.UpdateEmployeeCostCenter}
                      >
                        {(canUpdate) =>
                          isPanelEditModeEnabled && canUpdate ? (
                            <TextInput
                              name="costCenter"
                              label={t('costCenter')}
                              placeholder={t('costCenter')}
                              fullWidth={true}
                            />
                          ) : (
                            <>
                              <div>{t('costCenter')}</div>
                              <div>{get('costCenter')(employee)}</div>
                            </>
                          )
                        }
                      </Access>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <div>{t('manager')}</div>
                      {get('manager')(employee) || '-'}
                    </Grid>
                  </Grid>
                 
             
                  <Grid
                    container
                    className="staff-profile-organization-section__section-line"
                  >
                    <Grid item lg={4} md={4} sm={4} xs={12}>

                    <Access
                        to={employee}
                        rule={Permissions.UpdateEmployeeOrganizationInformation}
                        >
                        {(canUpdateOrg) =>  canUpdateOrg ? (
                                   <Access to={employee} rule={Permissions.UpdateUserCategory}>
                                                {(canUpdate) =>
                                                  isPanelEditModeEnabled && canUpdate ? (
                                                    <BooleanGroupField
                                                      name="userCategory"
                                                      label={t('userCategory')}
                                                      options={[UserCategory.Internal, UserCategory.External]}
                                                    />
                                                  ) : (
                                                    <>
                                                      <div>{t('userCategory')}</div>
                                                      {get('userCategory')(employee) || '-'}
                                                    </>
                                                  )
                                                }
                                              </Access>
                           
                          
                          ) :    <>
                                    <div>{t('userCategory')}</div>
                                  {get('userCategory')(employee) || '-'}
                                          </>
                            }

                        </Access>


 
                    </Grid>
              
                   <Grid item lg={4} md={4} sm={4} xs={12}> 
                    <Access
                              to={employee}
                              rule={Permissions.UpdateEmployeeOrganizationInformation}
                              >
                              {(canUpdateOrg) =>  canUpdateOrg ? (
                                <Access
                                  to={employee}
                                  rule={Permissions.UpdateEmployeeAccountExpiryDate}
                                >
                                  {(canUpdate) =>
                                    isPanelEditModeEnabled && canUpdate ? (
                                      <AkeliusDatePicker
                                        name="accountExpiryDate"
                                        label={`${t('accountExpiryDate')}`}
                                        placeholder={`${t('accountExpiryDate')}`}
                                        disabled={isFieldDisabled('accountExpiryDate')}
                                      />
                                    ) : (
                                      <>
                                        <div>{t('accountExpiryDate')}</div>
                                        {get('accountExpiryDate')(employee) || '-'}
                                      </>
                                    )
                                  }
                                </Access>
                                
                                ) :    <>
                                        <div>{t('accountExpiryDate')}</div>
                                        {get('accountExpiryDate')(employee) || '-'}
                                                </>
                                  }

                    </Access>
                    </Grid>
              
                    <Grid item lg={4} md={4} sm={4} xs={12}>  

                    <Access
                            rule={Permissions.ReadAndUpdateEmployeePreferredLanguageAndLeaveStatus}
                            to={employee}
                            >
                            {(canUpdateEmployeePreferredLanguage) => {
                              if(!canUpdateEmployeePreferredLanguage) {
                                return (<></>)
                              }


                            if(isPanelEditModeEnabled) {
                              return (
                               
                                <SelectField
                                name="preferredLanguage"
                                label={`${t('preferredLanguage')} *`}
                                options={preferredLanguagesOptions}
                                placeholder={`${t('preferredLanguage')} *`}
                              />
                                )
                            }
                            return (
                              <>
                              {getEmployeeTextData(employee, 'preferredLanguage', t)}
                            </>
                            )
                            
                            }}
                      </Access>
 
                  </Grid>

                  </Grid>
                  <Grid
                    container
                    className="staff-profile-organization-section__section-line"
                  >
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Access
                      to={employee}
                      rule={Permissions.UpdateEmployeeOrganizationInformation}
                      >
                      {(canUpdateOrg) =>  canUpdateOrg ? (
                            <Access
                            to={employee}
                            rule={Permissions.UpdateEmployeeManagerStatus}
                          >
                            {(canUpdate) =>
                              isPanelEditModeEnabled && canUpdate ? (
                                <CheckBox name="isManager" label={t('isManager')} />
                              ) : (
                                <>
                                  <div data-label={'isManager'}>{t('isManager')}</div>
                                  <div data-value={'isManager'}>
                                    {t(get('isManager')(employee))}
                                  </div>
                                </>
                              )
                            }
                          </Access>
                        
                        ) :    <>
                                    <div data-label={'isManager'}>{t('isManager')}</div>
                                  <div data-value={'isManager'}>
                                    {t(get('isManager')(employee))}
                                  </div>
                                        </>
                          }

                      </Access>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                     <Access
                  to={employee}
                  rule={Permissions.UpdateEmployeeOrganizationInformation}
                  >
                  {(canUpdateOrg) =>  canUpdateOrg ? (
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                                <Access
                                  to={employee}
                                  rule={Permissions.UpdateEmployeeApprovalRights}
                                >
                                  {(canUpdate) =>
                                    isPanelEditModeEnabled && canUpdate ? (
                                      <>
                                      <BooleanGroupField
                                        name="invoiceApprovalRights"
                                        label={t('invoiceApprovalRights')}
                                      />
                                    
                                     </>
                                    ) : (
                                      <>
                                        <div data-label={'invoiceApprovalRights'}>
                                          {t('invoiceApprovalRights')}
                                        </div>
                                        <div data-value={'invoiceApprovalRights'}>
                                          {t(get('invoiceApprovalRights')(employee))}
                                        </div>
                                      </>
                                    )
                                  }
                                </Access>
                              </Grid>
                              
                              ) :    <>
                                        <div data-value={'invoiceApprovalRights'}>
                                          {t(get('invoiceApprovalRights')(employee))}
                                        </div>
                                              </>
                                }

                            </Access>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Access
                      to={employee}
                      rule={Permissions.UpdateEmployeeOrganizationInformation}
                      >
                      {(canUpdateOrg) =>  canUpdateOrg ? (
                                         <Access
                                              to={employee}
                                              rule={Permissions.UpdateEmployeeApprovalLimit}
                                            >
                                              {(canUpdate) =>
                                                isPanelEditModeEnabled && canUpdate ? (
                                                  <TextInput
                                                    name="invoiceApprovalLimit"
                                                    label={t('invoiceApprovalLimit')}
                                                    placeholder={t('invoiceApprovalLimit')}
                                                    fullWidth={true}
                                                    disabled={isFieldDisabled('invoiceApprovalLimit')}
                                                  />
                                                ) : (
                                                  <>
                                                    <div>{t('invoiceApprovalLimit')}</div>
                                                    <div>{get('invoiceApprovalLimit')(employee)}</div>
                                                  </>
                                                )
                                              }
                                            </Access>
                      
                        
                        ) :    <>
                                  <div>{t('invoiceApprovalLimit')}</div>
                              <div>{get('invoiceApprovalLimit')(employee)}</div>
                                        </>
                          }

                      </Access>
                    </Grid>
                  </Grid>
                    
                    
                  <Access
                    to={employee}
                    rule={Permissions.UpdateEmployeeOrganizationInformation}
                    >
                    {(canUpdateOrg) =>  canUpdateOrg ? (
                      <Access rule={Permissions.UpdateEmployeeRole} to={employee}>
                      {(canUpdateEmployeeRole) => (
                        <>
                          {canUpdateEmployeeRole && isPanelEditModeEnabled ? (
                            <DropDownWithCategoriesField
                              name="roleRefId"
                              label={t('role')}
                              menuItems={roles}
                            />
                          ) : (
                            getEmployeeTextData(employee, 'roleRef.name', t, 'role')
                          )}
                        </>
                      )}
                    </Access>
                      
                      
                      ) :    <>
                                      {getEmployeeTextData(employee, 'roleRef.name', t, 'role')}
                                      </>
                        }

                    </Access>
                                        
                   
      

                {isPanelEditModeEnabled && <p>{t('requiredFieldsMessage')}</p>}
              </>
            )}
          </Access>
        
       </div>
      </>
  );
};

StaffProfileOrganizationSection.propTypes = {
  t: PropTypes.func.isRequired,
  employee: PropTypes.object.isRequired,
  preferredLanguagesOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  isPanelEditModeEnabled: PropTypes.bool.isRequired,
  formValues: PropTypes.object.isRequired,
  companyNameOptions: PropTypes.arrayOf(PropTypes.object),
  rolesOptions: PropTypes.arrayOf(PropTypes.object),
  countryOptions: PropTypes.array.isRequired,

};

export default StaffProfileOrganizationSection;
