import { createActions, handleActions } from 'redux-actions';
import ClientService from '../../services/client';
import { handleNotification } from '../../shared/components/Notification/reducer';

const GET_COMPANIES = 'GET_COMPANIES';
const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
const GET_COMPANIES_FAILURE = 'GET_COMPANIES_FAILURE';
const NEW_COMPANY = 'NEW_COMPANY';
const NEW_COMPANY_SUCCESS = 'NEW_COMPANY_SUCCESS';
const NEW_COMPANY_FAILURE = 'NEW_COMPANY_FAILURE';
const EDIT_COMPANY = 'EDIT_COMPANY';
const EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS';
const EDIT_COMPANY_FAILURE = 'EDIT_COMPANY_FAILURE';
const REMOVE_COMPANY = 'REMOVE_COMPANY';
const REMOVE_COMPANY_SUCCESS = 'REMOVE_COMPANY_SUCCESS';
const REMOVE_COMPANY_FAILURE = 'REMOVE_COMPANY_FAILURE';

const actionCreators = createActions(
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAILURE,

  NEW_COMPANY,
  NEW_COMPANY_SUCCESS,
  NEW_COMPANY_FAILURE,

  EDIT_COMPANY,
  EDIT_COMPANY_SUCCESS,
  EDIT_COMPANY_FAILURE,

  REMOVE_COMPANY,
  REMOVE_COMPANY_SUCCESS,
  REMOVE_COMPANY_FAILURE,
);

const {
  getCompanies,
  getCompaniesSuccess,
  getCompaniesFailure,
  newCompanySuccess,
  newCompanyFailure,
  editCompany,
  editCompanySuccess,
  editCompanyFailure,
  removeCompany,
  removeCompanySuccess,
  removeCompanyFailure,
} = actionCreators;

export function getAllCompanies() {
  return (dispatch) => {
    dispatch(getCompanies());
    return ClientService.instance
      .fetch('/companies')
      .then((response) => {
        dispatch(getCompaniesSuccess(response));
      })
      .catch((error) => {
        dispatch(getCompaniesFailure(error.message));
      });
  };
}

export function createCompany(parameters) {
  return (dispatch) => {
    dispatch(getCompanies());
    return ClientService.instance
      .post('/companies', {
        ...parameters,
      })
      .then((response) => {
        dispatch(newCompanySuccess());
        dispatch(
          handleNotification({
            message: 'new company added',
            type: 'success',
            isActive: true,
          }),
        );
        return response;
      })
      .catch((error) => {
        dispatch(newCompanyFailure(error));
        dispatch(
          handleNotification({
            message: 'error creating company',
            type: 'error',
            isActive: true,
          }),
        );
      });
  };
}

export function updateCompany(companyId, params) {
  return async (dispatch) => {
    dispatch(editCompany());
    return ClientService.instance
      .patch(`/companies/${companyId}`, { ...params })
      .then(() => {
        dispatch(editCompanySuccess());
        dispatch(
          handleNotification({
            message: 'company has been updated',
            type: 'success',
            isActive: true,
          }),
        );
      })
      .catch((error) => {
        dispatch(editCompanyFailure(error.message));
        dispatch(
          handleNotification({
            message: 'error updating company',
            type: 'error',
            isActive: true,
          }),
        );
        throw error;
      });
  };
}

export function deleteCompany(companyId) {
  return async (dispatch) => {
    dispatch(removeCompany());
    return ClientService.instance
      .delete(`/companies/${companyId}`)
      .then(() => {
        dispatch(removeCompanySuccess());
        dispatch(
          handleNotification({
            message: 'company has been removed',
            type: 'success',
            isActive: true,
          }),
        );
      })
      .catch((error) => {
        dispatch(removeCompanyFailure(error.message));
        dispatch(
          handleNotification({
            message: 'error deleting company',
            type: 'error',
            isActive: true,
          }),
        );
        throw error;
      });
  };
}

const companyReducer = handleActions(
  {
    GET_COMPANIES: (state) => ({
      ...state,
      isFetched: true,
    }),
    GET_COMPANIES_SUCCESS: (state, action) => ({
      ...state,
      items: [...action.payload],
      isFetched: false,
    }),
    GET_COMPANIES_FAILURE: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      isError: true,
      isFetched: false,
    }),
    NEW_COMPANY: (state, action) => ({
      ...state,
      item: action.payload,
      isFetched: false,
    }),
    NEW_COMPANY_SUCCESS: (state, action) => ({
      ...state,
      item: action.payload,
      isFetched: false,
    }),
    NEW_COMPANY_FAILURE: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      isError: true,
      isFetched: false,
    }),
    EDIT_COMPANY: (state, action) => ({
      ...state,
      item: action.payload,
      isFetched: false,
    }),
    EDIT_COMPANY_SUCCESS: (state, action) => ({
      ...state,
      item: action.payload,
      isFetched: false,
    }),
    EDIT_COMPANY_FAILURE: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      isError: true,
      isFetched: false,
    }),
    REMOVE_COMPANY: (state, action) => ({
      ...state,
      item: action.payload,
      isFetched: false,
    }),
    REMOVE_COMPANY_SUCCESS: (state, action) => ({
      ...state,
      item: action.payload,
      isFetched: false,
    }),
    REMOVE_COMPANY_FAILURE: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      isError: true,
      isFetched: false,
    }),
  },
  {
    companies: [],
    items: [],
    countries: [],
    cities: [],
    regions: [],
    organizationItems: [],
    errorMessage: null,
    editableItem: null,
    addNewItemModalTitle: '',
    addNewItemModalActionButtonText: '',
    isError: false,
    isFetched: false,
    isAddNewItemModalOpen: false,
    isAddNewItemModalFetching: false,
  },
);

export default companyReducer;
