import React, { Component } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import ClientService from '../../../services/client';
import OrganizationItem from './OrganizationItem';
import StaffAppLink from '../../../shared/components/StaffAppLink';
import { handleNotification } from '../../../shared/components/Notification/reducer';
import {
  handleGetOrgItems,
  handleSetParentItem,
  handleAddNewItemModalOpen,
  handleMoveItemModalOpen,
  handleMergeItemModalOpen,
} from '../reducer';
import { showLoading, hideLoading } from '../../../shared/components/Loader';
import { formatOrgItems } from './helpers';

class OrganizationItemContainer extends Component {
  handleMoveItemClick = () => {
    this.props.handleMoveItemModalOpen(this.props.item);
  };

  handleMergeItemClick = () => {
    this.props.handleMergeItemModalOpen(this.props.item);
  };

  handleItemClick = () => {
    const { item, onClick } = this.props;
    onClick && onClick(item.id);
  };

  handleEditItemClick = () => {
    this.props.handleAddNewItemModalOpen({
      addNewItemModalTitle: 'editItem',
      addNewItemModalActionButtonText: 'updateItem',
      editItemId: this.props.item.id,
    });
  };

  handleDeleteItemClick = () => {
    const { item } = this.props;

    this.props.showLoading();

    ClientService.instance
      .delete(`/organization/${item.id}`)
      .then(async () => {
        await this.props.handleGetOrgItems();
        this.props.handleNotification({
          message: 'organizationItemDeleteSuccessMessage',
          interpolate: {
            name: item.name,
          },
          type: 'success',
          isActive: true,
        });
        this.props.hideLoading();
      })
      .catch((error) => {
        let errorMessage;
        this.props.hideLoading();

        if (error.status === 410) {
          errorMessage = 'organizationItemNotFoundErrorMessage';
        } else if (error.status === 422) {
          errorMessage = 'organizationItemDeleteErrorMessage';
        } else {
          errorMessage = 'organizationErrorMessage';
        }

        this.props.handleNotification({
          message: errorMessage,
          addon: <StaffAppLink />,
          type: 'error',
          isActive: true,
        });
      });
  };

  render() {
    return (
      <OrganizationItem
        {...this.props}
        onItemClick={this.handleItemClick}
        onEditItemClick={this.handleEditItemClick}
        onDeleteItemClick={this.handleDeleteItemClick}
        onMoveItemClick={this.handleMoveItemClick}
        onMergeItemClick={this.handleMergeItemClick}
      />
    );
  }
}

OrganizationItemContainer.propTypes = {
  item: propTypes.shape({
    id: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    manager: propTypes.shape({
      firstName: propTypes.string,
      lastName: propTypes.string,
    }),
    parentId: propTypes.any,
    hasChildren: propTypes.bool,
    employeeCount: propTypes.number.isRequired,
  }).isRequired,
  onClick: propTypes.func,
  showLoading: propTypes.func,
  hideLoading: propTypes.func,
  handleGetOrgItems: propTypes.func,
  handleNotification: propTypes.func,
  handleSetParentItem: propTypes.func,
  handleAddNewItemModalOpen: propTypes.func,
  handleMoveItemModalOpen: propTypes.func,
  handleMergeItemModalOpen: propTypes.func,
};

const mapStateToProps = (state) => {
  const items = state.organization.items || [];

  return {
    organizationItems: formatOrgItems(items),
  };
};

export default connect(mapStateToProps, {
  showLoading,
  hideLoading,
  handleGetOrgItems,
  handleNotification,
  handleSetParentItem,
  handleAddNewItemModalOpen,
  handleMoveItemModalOpen,
  handleMergeItemModalOpen,
})(OrganizationItemContainer);
