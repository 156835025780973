import React from 'react';
// import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import App from './App/App';
import './i18n';
 
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <BrowserRouter>
    <App />
  </BrowserRouter>,
   );
 
// ReactDOM.render(<App />, document.getElementById('root'));
// const container = document.getElementById('root');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(<App/>);

// ReactDOM.render(
//     // <BrowserRouter>
//       <App />,
//     // </BrowserRouter>,
//     document.getElementById("root")
//   );


// const container = document.getElementById('app');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(<App />);