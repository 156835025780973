import React from 'react';

import PropTypes from 'prop-types';

const Value = ({ children }) => (
  <div className="autocomplete__value">{children}</div>
);

export default Value;

Value.propTypes = {
  children: PropTypes.any,
  containerClassName: PropTypes.string,
};
