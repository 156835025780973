import { reduxForm, SubmissionError } from 'redux-form';
import { Role } from './Role';
import { submitValidation } from './helper';

export const ROLE_FORM = 'role_form';

const submit = (values, _, { onFormSubmit }) => {
  const { id, name, category } = values;

  const errors = submitValidation(values);

  if (Object.keys(errors).length) {
    throw new SubmissionError(errors);
  }

  onFormSubmit({
    id,
    name,
    category,
  });
};

export default reduxForm({
  form: ROLE_FORM,
  onSubmit: submit,
})(Role);
