import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Avatar from '../../Avatar';
import FileUploadDropZone from '../../../../../shared/components/FileUploadDropZone';
import Button from '../../../../../shared/components/Button';
import './StaffProfileHeader.scss';
import Access, { Permissions } from '../../../../../shared/components/Access';
import Spinner from '../../../../../shared/components/Spinner';
import IconButtonWithToolTip from '../../../../../shared/components/IconButtonWithToolTip';

export const StaffProfileEmployeeImage = ({
  t,
  employeeImageUrl,
  employeeImageFilename,
  getEmployeeImage,
  deleteEmployeeImage,
  employeeImageUrlIsFetching,
  employeeImageUrlIsDeleting,
  employeeImageUrlIsCreating,
  handleStaffProfile,
  employee,
}) => {
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);

  const toggleIsEditModeEnabled = () => {
    isEditModeEnabled
      ? setIsEditModeEnabled(false)
      : setIsEditModeEnabled(true);
  };

  const { email, id } = employee;

  const onDeleteEmployeeImage = async () => {
    toggleIsEditModeEnabled();
    await deleteEmployeeImage(email);
    await handleStaffProfile(id);
  };

  useEffect(() => {
    if (email) {
      getEmployeeImage(email);
    }
    return () => {
      getEmployeeImage(null);
    };
  }, [email, getEmployeeImage]);

  return (
    <div className="staff-profile-header">
      <div className="staff-profile-header__image-wrap">
        <Spinner
          isLoading={
            employeeImageUrlIsFetching ||
            employeeImageUrlIsDeleting ||
            employeeImageUrlIsCreating
          }
        >
          <Avatar imageUrl={employeeImageUrl} />
        </Spinner>

        <Access rule={Permissions.UploadEmployeeImage} to={employee}>
          {(granted) => {
            if (!granted || !email) {
              return null;
            }

            return (
              !isEditModeEnabled && (
                <div className="staff-profile-header__controls--wrap">
                  <div className="staff-profile-header__edit-btn-wrap">
                    <IconButtonWithToolTip
                      title={t('edit')}
                      icon="ak-edit"
                      buttonClasses={classnames(
                        'staff-profile-header__edit-image-btn',
                        {
                          'staff-profile-header__edit-image-btn--active': isEditModeEnabled,
                        },
                      )}
                      iconClasses={classnames(
                        'staff-profile-header__edit-image-icon',
                        {
                          'staff-profile-header__edit-image-icon--active': isEditModeEnabled,
                        },
                      )}
                      onClick={toggleIsEditModeEnabled}
                    />
                  </div>
                </div>
              )
            );
          }}
        </Access>
      </div>
      {isEditModeEnabled && employeeImageUrl && (
        <div className="staff-profile-header__controls--wrap">
          <div className="staff-profile-header__controls">
            <div className="staff-profile-header__file-name">{employeeImageFilename}</div>
            <IconButtonWithToolTip
              title={t('delete')}
              icon="ak-delete"
              buttonClasses="staff-profile-header__delete-image-btn"
              onClick={onDeleteEmployeeImage}
            />
            <Button secondary onClick={toggleIsEditModeEnabled}>
              cancel
            </Button>
          </div>
        </div>
      )}
      {isEditModeEnabled && !employeeImageUrl && (
        <div className="staff-profile-header__controls--wrap">
          <p>Please use Intranet to upload employee pictures.</p>
          <div className="staff-profile-header__controls">
            <FileUploadDropZone
              name={'employeeImage'}
              label={t('dropZoneFileUploadLabel')}
              className="staff-profile-header__drop-zone"
              onChange={toggleIsEditModeEnabled}
            />

            <Button onClick={toggleIsEditModeEnabled}>cancel</Button>
          </div>
        </div>
      )}
    </div>
  );
};

StaffProfileEmployeeImage.propTypes = {
  t: PropTypes.func.isRequired,
  employeeImageUrl: PropTypes.string,
  employeeImageFilename: PropTypes.string,
  getEmployeeImage: PropTypes.func.isRequired,
  deleteEmployeeImage: PropTypes.func.isRequired,
  employeeImageUrlIsFetching: PropTypes.bool.isRequired,
  employeeImageUrlIsDeleting: PropTypes.bool.isRequired,
  employeeImageUrlIsCreating: PropTypes.bool.isRequired,
  handleStaffProfile: PropTypes.func.isRequired,
  employee: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
};
