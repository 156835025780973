import querystring from 'querystring';
import { isEmpty } from 'lodash/fp';
import { createActions, handleActions } from 'redux-actions';
import { handleNotification } from '../../../shared/components/Notification';
import ClientService from '../../../services/client';

const GET_STAFF_MEMBER_STARTED = 'GET_STAFF_MEMBER_STARTED';
const GET_STAFF_MEMBER_SUCCESS = 'GET_STAFF_MEMBER_SUCCESS';
const GET_STAFF_MEMBER_FAILURE = 'GET_STAFF_MEMBER_FAILURE';
const DELETE_EMERGENCY_CONTACT_STARTED = 'DELETE_EMERGENCY_CONTACT_STARTED';
const DELETE_EMERGENCY_CONTACT_SUCCESS = 'DELETE_EMERGENCY_CONTACT_SUCCESS';
const DELETE_EMERGENCY_CONTACT_FAILURE = 'DELETE_EMERGENCY_CONTACT_FAILURE';

const UPDATE_STAFF_PROFILE_STARTED = 'UPDATE_STAFF_PROFILE_STARTED';
const UPDATE_STAFF_PROFILE_SUCCESS = 'UPDATE_STAFF_PROFILE_SUCCESS';
const UPDATE_STAFF_PROFILE_FAILURE = 'UPDATE_STAFF_PROFILE_FAILURE';

const FLUSH_EMPLOYEE_DATA = 'FLUSH_EMPLOYEE_DATA';



const actionCreators = createActions(
  GET_STAFF_MEMBER_STARTED,
  GET_STAFF_MEMBER_SUCCESS,
  GET_STAFF_MEMBER_FAILURE,
  DELETE_EMERGENCY_CONTACT_STARTED,
  DELETE_EMERGENCY_CONTACT_SUCCESS,
  DELETE_EMERGENCY_CONTACT_FAILURE,
  UPDATE_STAFF_PROFILE_STARTED,
  UPDATE_STAFF_PROFILE_SUCCESS,
  UPDATE_STAFF_PROFILE_FAILURE,
  FLUSH_EMPLOYEE_DATA,
);

const {
  getStaffMemberStarted,
  getStaffMemberSuccess,
  getStaffMemberFailure,
  deleteEmergencyContactStarted,
  deleteEmergencyContactSuccess,
  deleteEmergencyContactFailure,
  updateStaffProfileStarted,
  updateStaffProfileSuccess,
  updateStaffProfileFailure,
  flushEmployeeData,


} = actionCreators;

export function deleteEmergencyContact(contactId) {
  if (!contactId) {
    throw new Error('missing contact id');
  }

  return async (dispatch) => {
    try {
      dispatch(deleteEmergencyContactStarted());
      await ClientService.instance.delete(`/contacts/${contactId}`);
      dispatch(deleteEmergencyContactSuccess());
    } catch (error) {
      dispatch(deleteEmergencyContactFailure(error.message));
      dispatch(
        handleNotification({
          message: 'errorGeneralApi',
          type: 'error',
          isActive: true,
        }),
      );
    }
  };
}

export function handleStaffProfile(employeeId, params = null) {
  if (!employeeId) {
    throw new Error('Missing employee id');
  }

  const queryString = querystring.stringify(params);
  const url = `/employees/${employeeId}`;

  const query = !params ? url : `${url}?${queryString}`;

  return async (dispatch) => {
    try {
      let employee;

      dispatch(getStaffMemberStarted());

      employee = await ClientService.instance.fetch(query);

      if (!employee) {
        throw new Error('Missing employee ');
      }

      if (employee.businessDivisionId) {
        const businessDivisionIdQuery = `/organization/${employee.businessDivisionId}/type`;
        const type = await ClientService.instance.fetch(
          businessDivisionIdQuery,
        );
        employee = { ...employee, ...type };
      }

      dispatch(getStaffMemberSuccess(employee));
    } catch (error) {
      dispatch(getStaffMemberFailure(error.message));
      dispatch(
        handleNotification({
          message: 'errorGeneralApi',
          type: 'error',
          isActive: true,
        }),
      );
    }
  };
}

export function handleStaffProfileUpdate(employeeId, data) {
  if (!employeeId) {
    throw new Error('Missing employee id');
  }
  if (isEmpty(Object.keys(data))) {
    throw new Error('Missing updated fields');
  }
 
   return async (dispatch) => {
    let updatedProfileData;
    try {
      dispatch(updateStaffProfileStarted());
      updatedProfileData = await ClientService.instance.patch(
        `/requests/change/${employeeId}`,
        data,
      );
      dispatch(updateStaffProfileSuccess());
      return updatedProfileData;
    } catch (error) {
      dispatch(updateStaffProfileFailure(error.message));
      dispatch(
        handleNotification({
          message: 'errorGeneralApi',
          type: 'error',
          isActive: true,
        }),
      );
      return updatedProfileData;
    }
  };
}

export function flushEmployee() {
  return (dispatch) => {
    dispatch(flushEmployeeData());
  };
}

 
const defaultStateValues = {
  employee: {},
  isError: false,
  isLoading: true,
  isStaffProfileUpdating: false,
  isScheduledUpdatesFetching: false,
  scheduledUpdates: [],
  isScheduledUpdatesCreating: false
};

const staffProfile = handleActions(
  {
    DELETE_EMERGENCY_CONTACT_STARTED: (state) => ({
      ...state,
      isLoading: true,
    }),
    DELETE_EMERGENCY_CONTACT_SUCCESS: (state) => ({
      ...state,
      isLoading: false,
    }),
    DELETE_EMERGENCY_CONTACT_FAILURE: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      isError: true,
      isLoading: false,
    }),
    GET_STAFF_MEMBER_STARTED: (state) => ({
      ...state,
      isLoading: true,
    }),
    GET_STAFF_MEMBER_SUCCESS: (state, action) => ({
      ...state,
      employee: { ...action.payload },
      isLoading: false,
    }),
    GET_STAFF_MEMBER_FAILURE: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      isError: true,
      isLoading: false,
    }),
    UPDATE_STAFF_PROFILE_STARTED: (state) => ({
      ...state,
      isStaffProfileUpdating: true,
    }),
    UPDATE_STAFF_PROFILE_SUCCESS: (state, action) => ({
      ...state,
      ...action.payload,
      isStaffProfileUpdating: false,
    }),
    UPDATE_STAFF_PROFILE_FAILURE: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      isError: true,
      isStaffProfileUpdating: false,
    }),
    FLUSH_EMPLOYEE_DATA: () => ({
      ...defaultStateValues,
    }),
  },
  {
    ...defaultStateValues,
  },
);

export default staffProfile;
