import { overSome, overEvery } from 'lodash/fp';
import {
  hasStaffAdminRole,
  hasStaffAdminRegionRole,
  hasStaffAdminCountryRole,
  isDirectManagerForEmployee,
  hasEmployeeResourceOwnership,
  hasNotEmployeeResourceOwnership,
  isStaffAdminAbleToManageOrganizationItem,
  isStaffAdminRegionAndMatchesEmployeeRegion,
  isStaffAdminCountryAndMatchesEmployeeCountry,
  isManager,
  isManagerForOrganizationItem,
  isUserLoggedInSystem,
  hasAppAdminRole,
  hasServiceDeskRole,
  hasStaffOrganizationManagerRole,
} from './rules';

import { Permissions } from './constants';

export function getPermissions(authUser) {
  return {
    [Permissions.MyTeam]: isManager(authUser),
    [Permissions.CreateEmployee]: authUser.isManager,
    [Permissions.UpdateEmployeeOrganizationInformation]: overEvery([
      hasNotEmployeeResourceOwnership(authUser),
      overSome([
        hasStaffAdminRole(authUser),
        isDirectManagerForEmployee(authUser),
        hasServiceDeskRole(authUser),
      ]),
    ]),
    [Permissions.ReadAndUpdateEmployeePreferredLanguageAndLeaveStatus]: overSome([
      hasStaffOrganizationManagerRole(authUser),
      hasStaffAdminRole(authUser),
      hasServiceDeskRole(authUser),
    ]),
    [Permissions.UpdateEmployeeSpecialMembership]: overSome([
      hasStaffAdminRole(authUser),
      isStaffAdminRegionAndMatchesEmployeeRegion(authUser),
      isStaffAdminCountryAndMatchesEmployeeCountry(authUser),
    ]),
    [Permissions.ReadEmployeePersonalInformation]: overSome([
      isDirectManagerForEmployee(authUser),
      hasEmployeeResourceOwnership(authUser),
    ]),
    [Permissions.UpdateEmployeePersonalInformation]: overSome([
      isDirectManagerForEmployee(authUser),
      isStaffAdminRegionAndMatchesEmployeeRegion(authUser),
      isStaffAdminCountryAndMatchesEmployeeCountry(authUser),
      hasStaffAdminRole(authUser),
    ]),
    [Permissions.ReadEmployeeEmergencyContacts]: overSome([
      hasEmployeeResourceOwnership(authUser),
      isDirectManagerForEmployee(authUser),
      isStaffAdminRegionAndMatchesEmployeeRegion(authUser),
      isStaffAdminCountryAndMatchesEmployeeCountry(authUser),
    ]),
    [Permissions.UpdateEmployeeEmergencyContacts]: overSome([
      hasEmployeeResourceOwnership(authUser),
      isStaffAdminRegionAndMatchesEmployeeRegion(authUser),
      isStaffAdminCountryAndMatchesEmployeeCountry(authUser),
    ]),
    [Permissions.UpdateEmployeePrivatePhone]: overSome([
      hasEmployeeResourceOwnership(authUser),
      isStaffAdminRegionAndMatchesEmployeeRegion(authUser),
      isStaffAdminCountryAndMatchesEmployeeCountry(authUser),
    ]),
    [Permissions.UpdateEmployeeCostCenter]: overSome([
      isDirectManagerForEmployee(authUser),
      isStaffAdminRegionAndMatchesEmployeeRegion(authUser),
      isStaffAdminCountryAndMatchesEmployeeCountry(authUser),
    ]),
    [Permissions.UpdateEmployeeManagerStatus]: overSome([
      hasStaffAdminRole(authUser),
      isDirectManagerForEmployee(authUser),
      isStaffAdminRegionAndMatchesEmployeeRegion(authUser),
      isStaffAdminCountryAndMatchesEmployeeCountry(authUser),
      hasServiceDeskRole(authUser)
    ]),
    [Permissions.ReadEmployeeContractInformation]: overSome([
      isDirectManagerForEmployee(authUser),
      hasEmployeeResourceOwnership(authUser),
    ]),
    [Permissions.UpdateEmployeeContractInformation]: overEvery([
      hasNotEmployeeResourceOwnership(authUser),
      overSome([
        isDirectManagerForEmployee(authUser),
        isStaffAdminRegionAndMatchesEmployeeRegion(authUser),
        isStaffAdminCountryAndMatchesEmployeeCountry(authUser),
      ]),
    ]),
    [Permissions.UpdateEmployeeRole]: overSome([
      isDirectManagerForEmployee(authUser),
      hasStaffAdminRole(authUser),
      isStaffAdminRegionAndMatchesEmployeeRegion(authUser),
      isStaffAdminCountryAndMatchesEmployeeCountry(authUser),
    ]),
    [Permissions.UpdateEmployeeCompanyName]: overSome([
      isDirectManagerForEmployee(authUser),
      hasStaffAdminRole(authUser),
      isStaffAdminRegionAndMatchesEmployeeRegion(authUser),
      isStaffAdminCountryAndMatchesEmployeeCountry(authUser),
      hasServiceDeskRole(authUser)
    ]),
    [Permissions.UpdateEmployeeAccountExpiryDate]: overSome([
      hasStaffAdminRole(authUser),
      isDirectManagerForEmployee(authUser),
      isStaffAdminRegionAndMatchesEmployeeRegion(authUser),
      isStaffAdminCountryAndMatchesEmployeeCountry(authUser),
      hasServiceDeskRole(authUser)
    ]),
    [Permissions.UpdateEmployeeJobTitle]: overSome([
      hasStaffAdminRole(authUser),
      isDirectManagerForEmployee(authUser),
      isStaffAdminRegionAndMatchesEmployeeRegion(authUser),
      isStaffAdminCountryAndMatchesEmployeeCountry(authUser),
      hasServiceDeskRole(authUser)
    ]),
    [Permissions.ReadEmployeePerformanceInformation]: () => false,
    [Permissions.ReadEmployeeItHardwareInformation]: overSome([
      isManager(authUser),
      hasStaffAdminRole(authUser),
      hasEmployeeResourceOwnership(authUser),
    ]),
    [Permissions.OrganizationalStructure]: overSome([
      hasStaffAdminRole(authUser),
      hasAppAdminRole(authUser),
      hasServiceDeskRole(authUser),
      hasStaffOrganizationManagerRole(authUser),
    ]),
    [Permissions.DeleteOrganizationItem]: overSome([
      isStaffAdminAbleToManageOrganizationItem(authUser),
      isManagerForOrganizationItem(authUser),
      hasServiceDeskRole(authUser),
      hasStaffOrganizationManagerRole(authUser),
    ]),
    [Permissions.CreateOrganizationItem]: overSome([
      isStaffAdminAbleToManageOrganizationItem(authUser),
      isManagerForOrganizationItem(authUser),
      hasServiceDeskRole(authUser),
      hasStaffOrganizationManagerRole(authUser),
    ]),
    [Permissions.EditOrganizationItem]: overSome([
      isStaffAdminAbleToManageOrganizationItem(authUser),
      isManagerForOrganizationItem(authUser),
      hasServiceDeskRole(authUser),
      hasStaffOrganizationManagerRole(authUser),
    ]),

    [Permissions.CreateDepartmentItem]: overSome([hasAppAdminRole(authUser)]),
    [Permissions.EditDepartmentItem]: overSome([hasAppAdminRole(authUser)]),
    [Permissions.DeleteDepartmentItem]: overSome([hasAppAdminRole(authUser)]),
    [Permissions.OffBoardUser]: overSome([isManager(authUser)]),
    [Permissions.DepartmentalStructure]: overSome([hasAppAdminRole(authUser)]),
    [Permissions.CreateLocation]: overSome([hasStaffAdminRole(authUser)]),
    [Permissions.UpdateLocation]: overSome([hasStaffAdminRole(authUser)]),
    [Permissions.DeleteLocaton]: overSome([hasStaffAdminRole(authUser)]),
    [Permissions.ReadOwnProfile]: isUserLoggedInSystem(authUser),
    [Permissions.UploadEmployeeImage]: overEvery([
      hasNotEmployeeResourceOwnership(authUser),
      overSome([
        hasServiceDeskRole(authUser),
        hasStaffOrganizationManagerRole(authUser),
        isDirectManagerForEmployee(authUser),
        isStaffAdminRegionAndMatchesEmployeeRegion(authUser),
        isStaffAdminCountryAndMatchesEmployeeCountry(authUser),
        hasStaffAdminRole(authUser),
        hasAppAdminRole(authUser),
      ]),
    ]),
    [Permissions.UpdateEmployeePreferredLanguage]: overSome([
      isManager(authUser),
      hasStaffAdminRegionRole(authUser),
      hasStaffAdminCountryRole(authUser),
    ]),
    [Permissions.UpdateEmployeeOnLeaveStatus]: overEvery([
      hasNotEmployeeResourceOwnership(authUser),
      overSome([
        isDirectManagerForEmployee(authUser),
        isStaffAdminRegionAndMatchesEmployeeRegion(authUser),
        isStaffAdminCountryAndMatchesEmployeeCountry(authUser),
        hasStaffAdminRole(authUser),
      ]),
    ]),
    [Permissions.UpdateUserCategory]: overSome([
      isDirectManagerForEmployee(authUser),
      isStaffAdminRegionAndMatchesEmployeeRegion(authUser),
      isStaffAdminCountryAndMatchesEmployeeCountry(authUser),
      hasStaffAdminRole(authUser),
      hasServiceDeskRole(authUser)
    ]),
    [Permissions.UpdateEmployeeApprovalRights]: overSome([
      isDirectManagerForEmployee(authUser),
      hasStaffAdminRole(authUser),
      hasServiceDeskRole(authUser)
    ]),
    [Permissions.UpdateEmployeeApprovalLimit]: overSome([
      isDirectManagerForEmployee(authUser),
      hasStaffAdminRole(authUser),
      hasServiceDeskRole(authUser)
    ]),
    [Permissions.CreateCompany]: overSome([hasStaffAdminRole(authUser)]),
    [Permissions.UpdateCompany]: overSome([hasStaffAdminRole(authUser)]),
    [Permissions.DeleteCompany]: overSome([hasStaffAdminRole(authUser)]),
    [Permissions.CreateRole]: overSome([hasStaffAdminRole(authUser)]),
    [Permissions.UpdateRole]: overSome([hasStaffAdminRole(authUser)]),
    [Permissions.DeleteRole]: overSome([hasStaffAdminRole(authUser)]),
  };
}

export function isPermitted(rule, to, permissions) {
  const permitted = permissions[rule];
  return typeof permitted === 'function' ? permitted(to) : permitted;
}
