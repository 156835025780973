import { createActions, combineActions, handleAction } from 'redux-actions';

export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

const actionCreators = createActions({
  SHOW_LOADING: () => true,
  HIDE_LOADING: () => false,
});

export const { showLoading, hideLoading } = actionCreators;

const loadingReducer = handleAction(
  combineActions(SHOW_LOADING, HIDE_LOADING),
  (_, action) => !!action.payload,
  false,
);

export default loadingReducer;
