import { createAction, handleActions } from 'redux-actions';

const ORGANIZATION_UNIT_TYPES = 'organizationUnitTypes';
const ALL_COMPANIES = 'allCompanies';
const ALL_ROLES = 'allRoles';

const organizationUnitTypesHandler = createAction(ORGANIZATION_UNIT_TYPES);

export function handleOrganizationUnitTypes(data) {
  return (dispatch) => dispatch(organizationUnitTypesHandler(data));
}

const companiesTypesHandler = createAction(ALL_COMPANIES);
export function handleAllCompanies(data) {
  return (dispatch) => dispatch(companiesTypesHandler(data));
}

const rolesTypesHandler = createAction(ALL_ROLES);
export function handleAllRoles(data) {
  return (dispatch) => dispatch(rolesTypesHandler(data));
}

const initialState = {
  organizationUnitTypes: null,
  companies: null,
  roles: null,
};

export default handleActions(
  {
    [ORGANIZATION_UNIT_TYPES]: (state, action) => ({
      ...state,
      organizationUnitTypes: action.payload,
    }),
    [ALL_COMPANIES]: (state, action) => ({
      ...state,
      companies: action.payload,
    }),
    [ALL_ROLES]: (state, action) => ({
      ...state,
      roles: action.payload,
    }),
  },
  { ...initialState },
);
