import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon';
import './FileUploadDropZone.scss';

export const FileUploadDropZone = ({ input, meta, label, className = '' }) => {
  const { onChange, name } = input;
  const { dirty, error } = meta;
  const onDrop = useCallback(
    (acceptedFiles) => {
      onChange(acceptedFiles);
    },
    [onChange],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
  });

  return (
    <div
      className={classnames(`drop-zone ${className}`, {
        'drop-zone--is-active': isDragActive,
      })}
    >
      <div {...getRootProps()} className="drop-zone__container">
        <input {...getInputProps()} name={name} />
        <Icon
          icon="ak-upload"
          className="drop-zone__icon drop-zone__icon--upload-icon"
        />
        <label className="drop-zone__label">{label}</label>
      </div>
      {dirty && error && <span className="drop-zone__error">{error}</span>}
    </div>
  );
};

FileUploadDropZone.propTypes = {
  error: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    dirty: PropTypes.bool,
  }),
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};
