export const Country = {
  All: '*',
  Sweden: 'sweden',
  Germany: 'germany',
  France: 'france',
  Denmark: 'denmark',
  Canada: 'canada',
  UnitedKingdom: 'unitedKingdom',
  UnitedStates: 'unitedStates',
  Cyprus: 'cyprus',
};

export const City = {
  Berlin: 'berlin',
  Boston: 'boston',
  Copenhagen: 'copenhagen',
  Gothenburg: 'gothenburg',
  Hamburg: 'hamburg',
  London: 'london',
  Malmo: 'malmo',
  Montreal: 'montreal',
  NewYork: 'newYork',
  Paris: 'paris',
  Stockholm: 'stockholm',
  Toronto: 'toronto',
  Washington: 'washington',
  Nicosa: 'nicosia',
};

export const Region = {
  Europe: 'europe',
  Scandinavia: 'scandinavia',
  NorthAmerica: 'northAmerica',
  SouthEurope: 'southEurope',
};

export const UserCategory = {
  Internal: 'internal',
  External: 'external',
};
