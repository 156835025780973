import { get } from 'lodash/fp';
import { staffProfileSectionName } from './helpers';

const { contract, organization } = staffProfileSectionName;

function submitContractValidation(values, registeredFields) {
  const errors = {};

  if (registeredFields?.companyCountry && !get('companyCountry')(values)) {
    errors.companyCountry = 'required';
  }

  return errors;
}

function submitOrganizationValidation(values, registeredFields) {
  const errors = {};
  if (registeredFields?.costCenter && !get('costCenter')(values)) {
    errors.costCenter = 'required';
  }

  if (registeredFields?.role && !get('role')(values)) {
    errors.role = 'required';
  }

  return errors;
}

const submitHandler = {
  [contract]: (values, registerFields) =>
    submitContractValidation(values, registerFields),
  [organization]: (values, registerFields) =>
    submitOrganizationValidation(values, registerFields),
};

export default submitHandler;
