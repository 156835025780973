import * as React from 'react';
import { Field, fieldPropTypes } from 'redux-form';

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import './SelectField.scss';

class WrappedSelect extends React.Component {
  static propTypes = {
    ...fieldPropTypes,
  };

  handleChange = (value) => {
    const { input } = this.props;
    input.onChange(value);
  };

  render() {
    const {
      meta,
      input,
      input: { name },
      options,
      disabled,
      tReady,
      t,
      ...props
    } = this.props;
    const missingOptions = Object.keys(options).length === 0;

    const isError = !!(meta.invalid && meta.error);

    return (
      <div
        name={name}
        className={classnames('select-field', {
          'select-field--disabled': disabled,
        })}
      >
        <TextField
         variant="standard"
          {...input}
          {...props}
          select
          fullWidth={true}
          InputLabelProps={{
            classes: {
              root: classnames('select-field__label', {
                'select-field__label--error': isError,
              }),
              shrink: 'select-field__label--shrinked',
              focused: 'select-field__label--focused',
              disabled: 'select-field__label--disabled',
            },
          }}
          InputProps={{
            classes: {
              underline: classnames({
                'select-field__underline': !isError,
                'select-field__underline--error': isError,
                'select-field__underline--disabled': disabled,
              }),
            },
          }}
          FormHelperTextProps={{
            name,
            classes: {
              root: 'select-field__helper',
              error: 'select-field__helper--error',
            },
          }}
          error={isError}
          onChange={this.handleChange}
          disabled={missingOptions || disabled}
          helperText={t(meta.error)}
          SelectProps={{
            MenuProps: {
              classes: {
                paper: 'select-field__paper',
                list: 'select-field__list',
              },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              classes={{
                root: 'select-field__menu-item',
                selected: 'select-field__menu-item--selected',
              }}
            >
              {option.text}
            </MenuItem>
          ))}
        </TextField>
      </div>
    );
  }
}

const SelectField = (props) => (
  <Field {...props} props={props} component={WrappedSelect} />
);

export default withTranslation()(SelectField);
