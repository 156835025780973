import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../Spinner';
import {
  BackEndDataProviderHook,
  BackEndDataProviderCompaniesHook,
  BackEndDataProviderRolesHook,
} from './backEndDataProviderHook';
import {
  handleOrganizationUnitTypes as handleOrganizationUnitTypesConnect,
  handleAllCompanies as handleAllCompaniesConnect,
  handleAllRoles as handleAllRolesConnect,
} from './reducer';

const BackEndDataProvider = ({
  children,
  handleOrganizationUnitTypes,
  handleAllCompanies,
  handleAllRoles,
}) => {
  const { isFetching, error, organizationUnitTypes } =
    BackEndDataProviderHook();

  const {
    isFetching: isFetchingCompanies,
    error: isErrorCompanies,
    companies,
  } = BackEndDataProviderCompaniesHook();

  const {
    isFetching: isFetchingRoles,
    error: isErrorRoles,
    roles,
  } = BackEndDataProviderRolesHook();

  if (isFetching || isFetchingCompanies || isFetchingRoles) {
    return <Spinner />;
  }
  if (error || isErrorCompanies || isErrorRoles) {
    return <h1>Back end data data can not be fetched</h1>;
  }
  handleOrganizationUnitTypes(organizationUnitTypes);
  handleAllCompanies(companies);
  handleAllRoles(roles);

  return children;
};

BackEndDataProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  handleOrganizationUnitTypes: PropTypes.func.isRequired,
  handleAllCompanies: PropTypes.func.isRequired,
  handleAllRoles: PropTypes.func.isRequired,
};

export default connect(null, {
  handleOrganizationUnitTypes: handleOrganizationUnitTypesConnect,
  handleAllCompanies: handleAllCompaniesConnect,
  handleAllRoles: handleAllRolesConnect,
})(BackEndDataProvider);
