import moment from 'moment';

export const MODES = {
  DATES_MODE: 'datesMode',
  MONTHS_MODE: 'monthsMode',
  YEARS_MODE: 'yearsMode',
};

export const DATE_FORMAT = 'YYYY-MM-DD';

export const TOTAL_GRID_ITEMS = 42;

export const DATE_ACTUALITY = {
  PREVIOUS: 'previous',
  CURRENT: 'current',
  NEXT: 'next',
};

export const TODAY = moment().format(DATE_FORMAT);

export const DIRECTIONS = {
  LEFT: 'left',
  RIGHT: 'right',
};

export const DEFAULT_YEARS_RANGE = {
  START_YEAR: 1900,
  END_YEAR: 2200,
};

export const YEARS_GRID_SIZE = 24;

export const CELL_WIDTH = 43;
