import { createActions, handleActions } from 'redux-actions';
import ClientService from '../../services/client';

const GET_MANAGERS_STARTED = 'GET_MANAGERS_STARTED';
const GET_MANAGERS_SUCCESS = 'GET_MANAGERS_SUCCESS';
const GET_MANAGERS_FAILURE = 'GET_MANAGERS_FAILURE';

const actionCreators = createActions(
  GET_MANAGERS_STARTED,
  GET_MANAGERS_SUCCESS,
  GET_MANAGERS_FAILURE,
);

const {
  getManagersStarted,
  getManagersSuccess,
  getManagersFailure,
} = actionCreators;

async function fetchAllManagers() {
  let count = 0;
  let offset = 0;
  const limit = 10;

  let employees = [];
  do {
    const url = `/employees?isManager=true&offset=${offset}&limit=${limit}`;
    const response = await ClientService.instance.fetch(url);

    const { employees: data } = response;
    ({ count } = response);

    employees = [...employees, ...data];

    offset += limit;
  } while (offset < count);

  return employees;
}

export function fetchManagers() {
  return (dispatch) => {
    dispatch(getManagersStarted());
    return fetchAllManagers()
      .then((response) => {
        dispatch(getManagersSuccess(response));
      })
      .catch((error) => {
        dispatch(getManagersFailure(error));
      });
  };
}

const maangersReducer = handleActions(
  {
    GET_MANAGERS_STARTED: (state) => ({
      ...state,
      loading: true,
    }),
    GET_MANAGERS_SUCCESS: (state, action) => ({
      ...state,
      items: [...action.payload],
      loading: false,
    }),
    GET_MANAGERS_FAILURE: (state) => ({
      ...state,
      items: [],
      loading: false,
      error: true,
    }),
  },
  {
    items: [],
    loading: false,
    error: false,
  },
);

export default maangersReducer;
