import React, { useState } from 'react';
import { connect } from 'react-redux';
import './StaffCatalogEmployeeCard.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../../../shared/components/Icon';
import ClientService from '../../../services/client';
import Spinner from '../../../shared/components/Spinner';
import Card from '../../../shared/components/Card';

const StaffCatalogEmployeeCard = ({
  authUser,
  employee: {
    id,
    firstName,
    lastName,
    title,
    businessDivisionId,
    companyStreet,
    companyCity,
    email,
  },
  classNames = '',
  myForwardedRef,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const user = id === authUser ? 'me' : id;

  const handleImageLoad = () => {
    setIsLoading(false);
  };
  const handleImageError = () => {
    setIsError(true);
    setIsLoading(false);
  };

  return (
    <Card className={classNames} ref={myForwardedRef}>
      <Spinner isLoading={isLoading}>
        <Link to={`/staff/${user}`} className="employee-card__preview">
          <img
            src={`${ClientService.instance.backendUrl}/media/${email}`}
            className="employee-card__preview-image"
            alt={`${firstName} ${lastName}`}
            referrerPolicy="no-referrer"
            onLoad={handleImageLoad}
            onError={handleImageError}
            style={{
              display: `${isLoading || isError ? 'none' : 'inline-block'}`,
            }}
          />
          {isError && (
            <Icon icon="ak-person" className="employee-card__preview-icon" />
          )}
        </Link>
      </Spinner>
      <ul className="employee-card__info">
        <li className="employee-card__name">
          <Link
            className="employee-card__name-link"
            to={`/staff/${user}`}
          >{`${firstName} ${lastName}`}</Link>
        </li>
        <li className="employee-card__title">{title} </li>
        <li className="employee-card__businessDivision">
          {businessDivisionId}
        </li>
        <li className="employee-card__companyStreet">{companyStreet}</li>
        <li className="employee-card__companyCity">{companyCity}</li>
        <li className="employee-card__email">
          <a
            href={`mailto:${email}`}
            className="employee-card__email-link"
            rel="noopener noreferrer"
          >
            {email}
          </a>
        </li>

       
      </ul>
    </Card>
  );
};

StaffCatalogEmployeeCard.propTypes = {
  authUser: PropTypes.string.isRequired,
  employee: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    businessDivisionId: PropTypes.string,
    companyStreet: PropTypes.string,
    companyCity: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  classNames: PropTypes.any,
  isOutlined: PropTypes.bool,
  myForwardedRef: PropTypes.any,
};

const ConnectedCard = connect(({ auth }) => ({
  authUser: auth.id,
}))(StaffCatalogEmployeeCard);

export default React.forwardRef((props, ref) => (
  <ConnectedCard {...props} myForwardedRef={ref} />
));
