import { getOr } from 'lodash/fp';

export const DEFAULT_OFFSET = 0;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_DELAY = 500;
export const STAFF_CATALOG_FILTERS_FORM_NAME = 'staff_catalog_filters';

export const employeeFields = [
  'id',
  'firstName',
  'lastName',
  'title',
  'businessDivisionId',
  'companyStreet',
  'companyCity',
  'email',
];

export function getBusinessDivisionName(businessDivisionId, orgItems) {
  const { name } = orgItems.find((orgItem) => orgItem.id === businessDivisionId) || {};
  return name || '-';
}

export function getTransformedEmployees(employees, orgItems) {
  return employees.map((employee) => {
    const employeeKeys = Object.keys(employee);

    return employeeKeys.reduce((acc, employeeKey) => {
      if (employeeFields.find((field) => field === employeeKey)) {
        if (employeeKey === 'businessDivisionId') {
          acc[employeeKey] = getBusinessDivisionName(
            employee[employeeKey],
            orgItems,
          );
        } else {
          acc[employeeKey] = employee[employeeKey] || '-';
        }
      }
      return acc;
    }, {});
  });
}

export const getSearchQuery = (formValues) => {
  const search = getOr(null)('name')(formValues);
  const businessDivisionId = getOr(null)('businessDivisionId')(formValues);
  return { search, businessDivisionId };
};

export const isSearchModeEnabled = (values) => Object.keys(values).some((key) => Boolean(values[key]));
