import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { stopSubmit } from 'redux-form';
import { get, isEmpty } from 'lodash/fp';
import PageHead from '../../../../shared/components/PageHead';
import Spinner from '../../../../shared/components/Spinner';
import Button from '../../../../shared/components/Button';
import TextInputField from '../../../../shared/components/TextInput';
import StaffProfileHeader from '../StaffProfileHeader';
 

import {
  getPermittedFields,
  getDifferenceFromValues,
  blackListFields,
  staffProfileSectionName,
} from '../helpers';
import submitValidation from '../submitValidation';
import {
  getEmployeeCountryOption,
  useStaffProfilePanels,
  defaultPanelExpandedState,
  getActiveTab,
  createImmediateUpdate,
} from './staffProfileHelpers';
import StaffProfileOrganizationSection from '../StaffProfileSections/StaffProfileOrganizationSection';
import './StaffProfile.scss';
import Modal from '../../../../shared/components/Modal';
import IconButtonWithToolTip from '../../../../shared/components/IconButtonWithToolTip';
  
const { contract, personal, organization, it } = staffProfileSectionName;

const StaffProfile = ({
  reset,
  form,
  onPanelEditModeToggle,
  onPanelSave,
  initialFormValues,
  formValues,
  onToggleCurrentPanelName,
  registeredFields,
  dispatch,
  employee,
  handleSubmit,
  getCurrentStaffMember,
  currentPanelName,
  t,
  isLoading,
  isConfirmationModalOpen,
  employeeCountryOptions,
  companyNameOptions,
  rolesOptions,
  confirmationModalMessage,
  onConfirmationModalOpen,
  onConfirmationModalClose,
  onConfirmationModalSubmit,
  countryOptions,
  preferredLanguagesOptions,
}) => {
  const tabsRef = useRef();

  const {
    activePanel,
    setActivePanel,

    pendingPanel,
    setPendingPanel,
    panelExpanded,
    setPanelExpanded,
    panelEditModeEnabled,
    setPanelEditModeEnabled,
    isTabChangeModalOpen,
    setIsTabChangeModalOpen,
  } = useStaffProfilePanels();

  const togglePanelEditMode = (panelName) => {
    const isPanelEditModeExist =
      typeof panelEditModeEnabled[panelName] === 'boolean';

    if (!isPanelEditModeExist) {
      return;
    }

    setActivePanel(activePanel !== panelName ? panelName : '');
    setPanelEditModeEnabled({
      ...panelEditModeEnabled,
      [panelName]: !panelEditModeEnabled[panelName],
    });
    onPanelEditModeToggle(panelName, !panelEditModeEnabled[panelName]);
  };


  const handleTabChangeModalSubmit = () => {
    const activeTab = getActiveTab(panelExpanded);
    if (panelEditModeEnabled[activeTab]) {
      togglePanelEditMode(activeTab);
    }
    reset(form);
    setIsTabChangeModalOpen(false);
    setPanelExpanded({
      ...defaultPanelExpandedState,
      [pendingPanel]: true,
    });

    tabsRef.current.setActiveTab(pendingPanel);
    setPendingPanel('');
  };

  const toggleTabChangeModal = () =>
    setIsTabChangeModalOpen(!isTabChangeModalOpen);

  const handlePanelEditModeToggle = (event) => {
    event.stopPropagation();
    const panelName = event.currentTarget.id.split('-')[1];
    togglePanelEditMode(panelName);
  };

  const handlePanelSaveClick = async (event) => {
    event.stopPropagation();
    const panelName = event.currentTarget.id.split('-')[1];

 
    onPanelSave(panelName);

    const differentValues = getDifferenceFromValues(
      getPermittedFields(initialFormValues, blackListFields),
      getPermittedFields(formValues, blackListFields),
    );

    if (isEmpty(differentValues)) {
      togglePanelEditMode(panelName);
      onToggleCurrentPanelName(null);
    } else {
      let errors = {};

      if (typeof submitValidation[panelName] === 'function') {
        const submitErrors = submitValidation[panelName](
          formValues,
          registeredFields,
        );
        errors = { ...errors, ...submitErrors };
      }
      if (isEmpty(errors)) {
        await createImmediateUpdate({
          handleSubmit,
          getCurrentStaffMember,
          togglePanelEditMode,
          currentPanelName,
          onToggleCurrentPanelName,
        });
        togglePanelEditMode(panelName);
        return;
      }
      dispatch(stopSubmit('staff_profile', errors));
    }
  };

  const renderPanelButtons = (panelName) => {
    const isPanelExpanded = panelExpanded[panelName];
    const isPanelEditModeEnabled = panelEditModeEnabled[panelName];

    if (!isPanelExpanded || !isPanelEditModeEnabled) {
      return null;
    }

    return (
      <div className="staff-profile__panel-buttons">
        <Button
          id={`cancel-${panelName}`}
          secondary
          onClick={handlePanelEditModeToggle}
        >
          {t('cancel')}
        </Button>

        <Button id={`save-${panelName}`} primary onClick={handlePanelSaveClick}>
          {t('save')}
        </Button>

        <div className="staff-profile__hidden-panel-field">
          <TextInputField name="panelName" label={'panelName'} />
        </div>
      </div>
    );
  };

  const formatEmployeeCountry = (value) =>
    getEmployeeCountryOption(employeeCountryOptions)(value);

 
  return (
    <>
      <PageHead
        text={t(
          `${get('firstName')(employee) || ''}
                   ${get('lastName')(employee) || ''}`,
        )}
      />

      <Modal
        open={isTabChangeModalOpen}
        title={t('unsavedChanges')}
        callToActionColor={'danger'}
        callToActionText={t('continue')}
        onSubmit={handleTabChangeModalSubmit}
        onClose={toggleTabChangeModal}
      >
        {t('warningTabChangeText')}
      </Modal>

      <Modal
        open={isConfirmationModalOpen}
        title={t('warningPermissionChangeTitle')}
        callToActionColor={'danger'}
        callToActionText={t('iUnderstand')}
        onSubmit={onConfirmationModalSubmit}
        onClose={onConfirmationModalClose}
      >
        {confirmationModalMessage}
      </Modal>
      <div className="staff-profile">
        <StaffProfileHeader t={t} employee={employee} />

        <Spinner isLoading={isLoading}>
        
              <div className="staff-profile__content">
                <div className="staff-profile__edit-button-wrap">
            
                        <IconButtonWithToolTip
                          title={t('edit')}
                          disabled={
                            !!activePanel && organization !== activePanel
                          }
                          isActive={
                            !!activePanel && organization === activePanel
                          }
                          icon="ak-edit"
                          onClick={handlePanelEditModeToggle}
                          id={`edit-${organization}`}
                        />
                     
                </div>

                <StaffProfileOrganizationSection
                  employee={employee}
                  t={t}
                  isPanelEditModeEnabled={panelEditModeEnabled[organization]}
                  formValues={formValues}
                  companyNameOptions={companyNameOptions}
                  rolesOptions={rolesOptions}
                  preferredLanguagesOptions={preferredLanguagesOptions}
                  isPanelItEditModeEnabled={panelEditModeEnabled[it]}
                  employeeCountryOptions={employeeCountryOptions}
                  isPanelPersonalEditModeEnabled={panelEditModeEnabled[personal]}
                  formatEmployeeCountry={formatEmployeeCountry}
                  isPanelContractEditModeEnabled = {panelEditModeEnabled[contract]}
                  countryOptions={countryOptions}
                  onConfirmationModalOpen={onConfirmationModalOpen}

                />

            
                 {renderPanelButtons(organization)}
              </div> 
              
        </Spinner>
      </div>
    </>
  );
};

StaffProfile.propTypes = {
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  employee: PropTypes.any,
  isLoading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  organizations: PropTypes.array.isRequired,
  onPanelEditModeToggle: PropTypes.func.isRequired,
  employeeCountryOptions: PropTypes.array.isRequired,
  companyNameOptions: PropTypes.arrayOf(PropTypes.object),
  rolesOptions: PropTypes.arrayOf(PropTypes.object),
  formValues: PropTypes.object.isRequired,
  registeredFields: PropTypes.any,
  confirmationModalMessage: PropTypes.string,
  isConfirmationModalOpen: PropTypes.bool.isRequired,
  onConfirmationModalOpen: PropTypes.func.isRequired,
  onConfirmationModalClose: PropTypes.func.isRequired,
  onConfirmationModalSubmit: PropTypes.func.isRequired,
  countryOptions: PropTypes.array.isRequired,
  onPanelSave: PropTypes.func.isRequired,
  initialFormValues: PropTypes.object.isRequired,
  onToggleCurrentPanelName: PropTypes.func.isRequired,
  currentPanelName: PropTypes.string,
  getCurrentStaffMember: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  permissionsAuth: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  preferredLanguagesOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default StaffProfile;
