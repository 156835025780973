import React, { Component } from 'react';
import propTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Modal from '../../../shared/components/Modal';
import './MergeItemModal.scss';
import AkeliusBusinessDivisionField from '../../../shared/components/AkeliusBusinessDivision/AkeliusBusinessDivisionField';

class MergeItemModal extends Component {
  render() {
    const {
      t,
      modalTitle,
      handleSubmit,
      callToActionText,
      isOpen,
      isModalLoading,
      onClose,
      organizationItems,
    } = this.props;

    return (
      <div className="merge-item">
        <Modal
          title={t(modalTitle)}
          open={isOpen}
          callToActionText={t(callToActionText)}
          isModalLoading={isModalLoading}
          onClose={onClose}
          onSubmit={handleSubmit}
          classNames="merge-item__modal"
        >
          <AkeliusBusinessDivisionField
            name="mergeToBussinesDivisionId"
            organizationItems={organizationItems}
          />
        </Modal>
      </div>
    );
  }
}

MergeItemModal.propTypes = {
  t: propTypes.func.isRequired,
  modalTitle: propTypes.string.isRequired,
  handleSubmit: propTypes.func.isRequired,
  callToActionText: propTypes.string.isRequired,
  isOpen: propTypes.bool.isRequired,
  isModalLoading: propTypes.bool,
  onClose: propTypes.func.isRequired,
  organizationItems: propTypes.array,
};

export default withTranslation()(MergeItemModal);
