export const diffFromPlainObjects = (o1, o2) => {
  const resultDiff = Object.keys(o2).reduce((diff, key) => {
    if (o1[key] === o2[key]) return diff;
    return {
      ...diff,
      [key]: o2[key],
    };
  }, {});
  return resultDiff;
};
