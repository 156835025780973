import React from 'react';
import PropTypes from 'prop-types';
import { IconButton as MUIIconButton } from '@mui/material';
import './IconButton.scss';

const IconButton = (props) => {
  const { isActive, variant, children, className, ...rest } = props;
  return (
    <MUIIconButton
      className={`icon-button 
        ${variant ? `icon-button--${variant}` : ''}  
        ${isActive ? `icon-button--active` : ''}
        ${props.disabled ? `icon-button--disabled` : ''}
        ${className || ''}`}
      variant={variant}
      {...rest}
    >
      {children}
    </MUIIconButton>
  );
};

IconButton.defaultProps = {
  variant: 'outlined',
};

IconButton.propTypes = {
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.element,
  isActive: PropTypes.bool,
};

export default IconButton;
