import { useState } from 'react';
import {
  staffProfileSectionName,
} from '../helpers';

const { it, contract, personal, organization } = staffProfileSectionName;

export const defaultPanelExpandedState = {
  [contract]: false,
  [it]: false,
  [organization]: false,
  [personal]: false,
};

export const getActiveTab = (panelExpandedObject) =>
  Object.keys(panelExpandedObject).find(
    (key) => panelExpandedObject[key] === true,
  ) || null;

export const useStaffProfilePanels = () => {
  const [activePanel, setActivePanel] = useState('');

  const [pendingPanel, setPendingPanel] = useState('');
  const [panelExpanded, setPanelExpanded] = useState({
    ...defaultPanelExpandedState,
    [organization]: true,
  });
  const [panelEditModeEnabled, setPanelEditModeEnabled] = useState({
    ...defaultPanelExpandedState,
  });
  const [isTabChangeModalOpen, setIsTabChangeModalOpen] = useState(false);

  return {
    activePanel,
    setActivePanel,
    pendingPanel,
    setPendingPanel,
    panelExpanded,
    setPanelExpanded,
    panelEditModeEnabled,
    setPanelEditModeEnabled,
    isTabChangeModalOpen,
    setIsTabChangeModalOpen,
  };
};

export async function createImmediateUpdate({
  handleSubmit,
 
  getCurrentStaffMember,
  togglePanelEditMode,
  currentPanelName,
  onToggleCurrentPanelName,
}) {
  const submit = handleSubmit();
  submit.then &&
    submit.then(async (data) => {
      const isSuccess = !!data && !(data && data.error);
      if (isSuccess) {
        await getCurrentStaffMember();
        togglePanelEditMode(currentPanelName);
        onToggleCurrentPanelName(null);
      }
    });
}
 

export const getEmployeeCountryOption = (employeeCountries) => (countryCode) =>
  countryCode
    ? employeeCountries.find((item) => item.value === countryCode)
    : {};
