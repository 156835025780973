import AppBar from '@mui/material/AppBar';
import { get } from 'lodash/fp';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import IconButton from '../IconButton';
import Icon from '../Icon';
import { languageConfig } from '../../utils/languageUtils';
import { LanguageMap, LanguageKeys } from '../../../i18n';
import { handleSetNewLanguage } from './reducer';
import { toggleSidebar } from '../Sidebar/reducer';
import DropDownMenu from '../DropDownMenu';
import DropDownMenuItem from '../DropDownMenuItem';
import Access, { Permissions } from '../Access';
import './Header.scss';
import ClientService from '../../../services/client';

class Header extends Component {
  state = {
    userMenu: null,
    languageMenu: null,
  };

  componentDidMount() {
    const languageFromLocalStorage = localStorage.getItem('i18nextLng');
    this.props.handleSetNewLanguage(languageFromLocalStorage);
  }

  closeMenu = (menu) => {
    this.setState({ [menu]: null });
  };

  toggleMenuClick = (event, menu) => {
    this.setState({ [menu]: event.currentTarget });
  };

  handleLogout = async () => {
    const { auth0Client } = await ClientService.instance.initializedAuth0Client;
    const { location } = window;
    const redirectUrl = `${location.protocol}//${location.hostname}${
      location.port ? `:${location.port}` : ''
    }`;

    await auth0Client.logout({
      returnTo: redirectUrl,
    });
  };

  handleUserMenuClose = () => {
    this.closeMenu('userMenu');
  };

  handleUserMenuClick = (event) => {
    this.toggleMenuClick(event, 'userMenu');
  };

  handleLanguageMenuClick = (event) => {
    this.toggleMenuClick(event, 'languageMenu');
  };

  handleUserMenuItemClick = () => {
    this.closeMenu('userMenu');
  };

  handleLanguageMenuClose = () => {
    this.closeMenu('languageMenu');
  };

  handleLanguageMenuItemClick = (index) => () => {
    this.props.i18n.changeLanguage(languageConfig.allowedLanguages[index]);
    this.closeMenu('languageMenu');
    this.props.handleSetNewLanguage(languageConfig.allowedLanguages[index]);
  };

  renderLanguageMenu = () => {
    const { languageMenu } = this.state;
    const { t } = this.props;

    const translatedLanguages = Object.keys(LanguageMap).map((lang) =>
      t(`${LanguageMap[lang]}`),
    );

    return (
      <>
        <IconButton
          onClick={this.handleLanguageMenuClick}
          aria-label="Menu"
          aria-owns={languageMenu ? 'language-menu' : undefined}
          aria-haspopup="true"
          className={`header__button ${
            Boolean(languageMenu) && 'header__button--active'
          }`}
        >
          <Icon icon="ak-globe" className="header__icon" />
        </IconButton>
        <DropDownMenu
          id="language-menu"
          open={Boolean(languageMenu)}
          onClose={this.handleLanguageMenuClose}
          anchorEl={languageMenu}
        >
          {LanguageKeys.map((code, index) => (
            <DropDownMenuItem
              key={index.toString() + code}
              isSelected={this.props.currentLanguage === code}
              onClick={this.handleLanguageMenuItemClick(index)}
            >
              {translatedLanguages[index]}
            </DropDownMenuItem>
          ))}
        </DropDownMenu>
      </>
    );
  };

  handleToggleSidebar = () => {
    this.props.toggleSidebar(this.props.isSidebarOpen);
  };

  render() {
    const {
      t,
      auth: { email },
    } = this.props;

    const { userMenu } = this.state;

    return (
      <AppBar position="static" className="header">
        <Toolbar className="header__bar">
          <div className="header__toggle">
            <IconButton
              aria-label="Menu"
              onClick={this.handleToggleSidebar}
              className="header__button"
            >
              <Icon icon="ak-menu" className="header__icon" />
            </IconButton>

            <Link to="/" className="header__logo">
              Staff
            </Link>
          </div>
          <ul className="header__nav-list">
            <li className="header__nav-item">{this.renderLanguageMenu()}</li>
            <li className="header__nav-item" title={email}>
              <IconButton
                onClick={this.handleUserMenuClick}
                aria-owns={userMenu ? 'user-menu' : undefined}
                aria-haspopup="true"
                className={`header__button ${
                  Boolean(userMenu) && 'header__button--active'
                }`}
              >
                <Icon icon="ak-person" className="header__icon" />
              </IconButton>
              <Access rule={Permissions.ReadOwnProfile}>
                {(granted) => (
                  <DropDownMenu
                    id="user-menu"
                    open={Boolean(userMenu)}
                    onClose={this.handleUserMenuClose}
                    anchorEl={userMenu}
                  >
                    {granted && (
                      <DropDownMenuItem
                        onClick={this.handleUserMenuItemClick}
                        classes={{ selected: 'selected' }}
                      >
                        <Link to={`/staff/me`} className="header__nav-link">
                          <Icon icon="ak-person" className="header__nav-icon" />
                          {t('myProfile')}
                        </Link>
                      </DropDownMenuItem>
                    )}
                    <DropDownMenuItem
                      onClick={this.handleLogout}
                      classes={{ selected: 'selected' }}
                    >
                      <Icon icon="ak-exit-app" className="header__nav-icon" />
                      {t('logout')}
                    </DropDownMenuItem>
                  </DropDownMenu>
                )}
              </Access>
            </li>
          </ul>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  auth: PropTypes.object,
  toggleSidebar: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string,
  handleSetNewLanguage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object,
  isSidebarOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const isSidebarOpen = get('sidebar.isSidebarOpen')(state);
  return {
    ...state,
    isSidebarOpen,
  };
};

export default connect(mapStateToProps, {
  handleSetNewLanguage,
  toggleSidebar,
})(withTranslation()(Header));
