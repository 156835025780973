import {
  compose,
  get,
  isEqual,
  some,
  map,
  keys,
  every,
  curry,
} from 'lodash/fp';

function isFieldDisabled(fieldState) {
  return !fieldState;
}

function getFieldState({ ruleSetType, isRuleSetApplied }) {
  if (!ruleSetType) {
    throw new Error('invalid rules for dependency fields');
  }

  const defaultFieldState = ruleSetType === 'disabled';
  return isRuleSetApplied ? !defaultFieldState : defaultFieldState;
}

function evaluateCondition(rule, field, formValues) {
  const value = get(field, formValues);
  const isStringRuleField = typeof rule[field] === 'string';
  if (Array.isArray(value) && isStringRuleField) {
    return value.includes(rule[field]);
  }
  return isEqual(rule[field], value);
}

function evaluateRule(rule, form) {
  if (!keys(rule).length) {
    throw new Error('invalid rules for dependency fields');
  }
  return every(
    Boolean,
    map((field) => evaluateCondition(rule, field, form), keys(rule)),
  );
}

function evaluateRuleSet({ ruleSetType = '', fieldRuleSet = [], form = {} }) {
  const isRuleSetApplied = some(
    Boolean,
    map((rule) => evaluateRule(rule, form), fieldRuleSet),
  );
  return {
    ruleSetType,
    isRuleSetApplied,
  };
}

function getFieldRuleSet(dependencies = {}, form = {}, field = '') {
  const ruleSetType = keys(dependencies[field])[0];
  const fieldRuleSet = dependencies[field][ruleSetType];
  return {
    fieldRuleSet,
    ruleSetType,
    form,
  };
}

const curriedIsFieldDisabled = curry((dependencies, form, field) => compose(
  isFieldDisabled,
  getFieldState,
  evaluateRuleSet,
  getFieldRuleSet,
)(dependencies, form, field));

export default curriedIsFieldDisabled;
