import { createActions, handleActions } from 'redux-actions';

const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
const HIDE_SIDEBAR = 'HIDE_SIDEBAR';

const actionCreators = createActions(SHOW_SIDEBAR, HIDE_SIDEBAR);

const { showSidebar, hideSidebar } = actionCreators;

export const toggleSidebar = (isSidebarOpen) => (dispatch) => (isSidebarOpen ? dispatch(hideSidebar()) : dispatch(showSidebar()));

const sidebarReducer = handleActions(
  {
    SHOW_SIDEBAR: (state) => ({
      ...state,
      isSidebarOpen: true,
    }),
    HIDE_SIDEBAR: (state) => ({
      ...state,
      isSidebarOpen: false,
    }),
  },
  {
    isSidebarOpen: false,
  },
);

export default sidebarReducer;
