import React, { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { initialize, change, getFormValues } from 'redux-form';
import { find } from 'lodash/fp';
import { getOrganizationOptions } from '../../../shared/helpers/select';
import { getOrganization, getUnmappedOrganizations } from './helpers';
import { handleNotification } from '../../../shared/components/Notification/reducer';
import {
  handleAddNewItemModalOpen,
  handleAddNewItemModalClose,
  handleGetOrgItemsBelonging,
  handleIsAddNewItemModalFetching,
  handleGetDepartmentItems,
} from '../reducer';

import ClientService from '../../../services/client';

import StaffAppLink from '../../../shared/components/StaffAppLink';
import { FORM_NAME, DEFAULT_ITEM_PROPS } from './constants';
import AddNewItemModal from './reduxForm';

export class AddNewItemModalContainer extends Component {
  componentDidMount() {
    const { editableItem } = this.props;

    if (editableItem !== null) {
      this.props.initialize(FORM_NAME, {
        id: editableItem.id,
        name: editableItem.name,
        rootId: editableItem.rootId
      });
    } else {
      this.props.initialize(FORM_NAME, {
      });
    }
  }

  handleModalSubmit = async (dataToSend) => {
    this.props.handleIsAddNewItemModalFetching(true);

    try {
      const data = {
        ...DEFAULT_ITEM_PROPS,
        ...dataToSend,
      };

      const { name } = await ClientService.instance.post('/department', data);

      this.props.handleNotification({
        message: 'successAddNewDepartmentItem',
        interpolate: {
          name,
        },
        type: 'success',
        isActive: true,
      });

      await this.props.handleGetOrgItemsBelonging();
      await this.props.handleGetDepartmentItems();
      this.props.handleIsAddNewItemModalFetching(false);
      this.props.handleAddNewItemModalClose();
    } catch (error) {
      this.props.handleIsAddNewItemModalFetching(false);
      this.props.handleNotification({
        message: 'errorGeneralApi',
        addon: <StaffAppLink />,
        type: 'error',
        isActive: true,
      });
    }
  };

  handleModalEditSubmit = async (dataToSend, id, _etag) => {
    const processedData = {
      ...DEFAULT_ITEM_PROPS,
      ...dataToSend,
      _etag,
    };

    this.props.handleIsAddNewItemModalFetching(true);

    try {
      const { name } = await ClientService.instance.put(
        `/department/${id}`,
        {
          ...processedData,
        },
      );

      this.props.handleNotification({
        message: 'successEditDepartmentItem',
        interpolate: {
          name,
        },
        type: 'success',
        isActive: true,
      });

      await this.props.handleGetOrgItemsBelonging();
      await this.props.handleGetDepartmentItems();
      this.props.handleIsAddNewItemModalFetching(false);
      this.props.handleAddNewItemModalClose();
    } catch (error) {
      const { name } = dataToSend
      this.props.handleIsAddNewItemModalFetching(false);

      if (error.status === 412) {
        this.props.handleNotification({
          message: 'errorEditOrganizationItemConcurrent',
          interpolate: {
            name,
          },
          addon: <StaffAppLink />,
          type: 'error',
          isActive: true,
        });
      } else {
        this.props.handleNotification({
          message: 'errorEditOrganizationItem',
          addon: <StaffAppLink />,
          type: 'error',
          isActive: true,
        });
      }
    }
  };

  handleModalClose = () => this.props.handleAddNewItemModalClose();

  handleFormatOrganizationValue = (value) =>
    !value ? {} : find({ value })(this.props.organizationOptions);

  render() {
    const {
      allItems,
      modalTitle,
      editableItem,
      callToActionText,
      isModalOpen,
      isModalLoading,
      organizationOptions,
      organizationItems
    } = this.props;

    const selectedOrganization = getOrganization(editableItem, organizationOptions);

    return (
      <AddNewItemModal
        allItems={allItems}
        organizationItems={organizationItems}
        modalTitle={modalTitle}
        editableItem={editableItem}
        callToActionText={callToActionText}
        organizationOptions={organizationOptions}
        isOpen={isModalOpen}
        isModalLoading={isModalLoading}
        onClose={this.handleModalClose}
        onModalSubmit={this.handleModalSubmit}
        onModalEditSubmit={this.handleModalEditSubmit}
        onFormatOrganizationValue={this.handleFormatOrganizationValue}
        selectedOrganization={selectedOrganization}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const {
    items: allItems,
    organizationItems,
    editableItem,
    addNewItemModalTitle,
    addNewItemModalActionButtonText,
    isAddNewItemModalFetching,
  } = state.department;

  let finalOrganizationItems;

  // for new screen filter out the already mapped organization
  if (!editableItem) {
    finalOrganizationItems = getUnmappedOrganizations(organizationItems, allItems);
  } else {
    finalOrganizationItems = organizationItems;
  }
  const organizationOptions = getOrganizationOptions(finalOrganizationItems) || [];  
  
  return {
    allItems,
    modalTitle: addNewItemModalTitle,
    editableItem,
    organizationItems,
    organizationOptions,
    callToActionText: addNewItemModalActionButtonText,
    isModalLoading: isAddNewItemModalFetching
  };
};

export default connect(mapStateToProps, {
  change,
  initialize,
  getFormValues,
  handleNotification,
  handleAddNewItemModalOpen,
  handleAddNewItemModalClose,
  handleGetOrgItemsBelonging,
  handleIsAddNewItemModalFetching,
  handleGetDepartmentItems,
})(AddNewItemModalContainer);

AddNewItemModalContainer.propTypes = {
  change: propTypes.func.isRequired,
  allItems: propTypes.array,
  initialize: propTypes.func.isRequired,
  formValues: propTypes.any,
  modalTitle: propTypes.string.isRequired,
  editableItem: propTypes.object,
  callToActionText: propTypes.string.isRequired,
  organizationOptions: propTypes.array.isRequired,
  organizationItems: propTypes.array.isRequired,
  isModalOpen: propTypes.bool.isRequired,
  isModalLoading: propTypes.bool.isRequired,
  handleAddNewItemModalOpen: propTypes.func.isRequired,
  handleAddNewItemModalClose: propTypes.func.isRequired,
  handleNotification: propTypes.func.isRequired,
  handleGetOrgItemsBelonging: propTypes.func.isRequired,
  handleIsAddNewItemModalFetching: propTypes.func.isRequired,
  handleGetDepartmentItems: propTypes.func.isRequired,
};
