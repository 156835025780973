export const getOrganization = (editableItem, organizations) =>
  editableItem !== null
    ? organizations.find((organization) => organization.value === editableItem.rootId) ||
    null
    : null;

export const isOrganizationAlreadyMapped = (rootId, departments) =>
  departments.filter((department) => department.organization.id === rootId).length;

export const isNotUniqueName = (name, editableId, departments) =>
  departments.filter((department) => department.name.toLowerCase() === name.toLowerCase() && department.id !== editableId).length;

export const getUnmappedOrganizations = (organizationItems, departments) =>
  organizationItems.filter((organization) =>  !departments.map(d => d.organization.id).includes(organization.id));
