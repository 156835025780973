import React from 'react';
import PropTypes from 'prop-types';

const InputComponent = React.forwardRef( (props, ref ) => 
  (<div ref={ref} {...props} className="autocomplete__input" />)
);

InputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};
export default InputComponent;