import React from 'react';
import { get } from 'lodash/fp';
import moment from 'moment';

export const getUserId = (match, authUserId) =>
  match === 'me' ? authUserId : match;

export function formatPayload({ employment, ...values }) {
  return {
    ...values,
    ...employment,
  };
}

export const staffProfileSectionName = {
  it: 'it',
  organization: 'organization',
  personal: 'personal',
  contract: 'contract',
  performanceDevelopment: 'performanceDevelopment',
};

export const getEmployeeTextData = (
  employeeData,
  fieldName,
  translate,
  translationsString,
) => (
  <>
    <div data-label={fieldName}>
      {translate(translationsString || fieldName)}
    </div>
    <div data-value={fieldName}>
      {translate(get(fieldName)(employeeData)) || '-'}
    </div>
  </>
);

export const blackListFields = ['effectiveFromDate', 'panelName'];

export const CHANGE_TYPES = {
  IMMEDIATE_UPDATES: 'IMMEDIATE_UPDATES',
  SCHEDULED_UPDATES: 'SCHEDULED_UPDATES',
};

export function getChangeType(currentDate, effectiveFromDate, changeTypes) {
  return currentDate === effectiveFromDate
    ? changeTypes.IMMEDIATE_UPDATES
    : changeTypes.SCHEDULED_UPDATES;
}

export function getPermittedFields(
  values = {},
  blackList = [],
  renamedFields = {},
) {
  const formattedValues = formatPayload(values);

  const filteredKeys = Object.keys(formattedValues).filter(
    (key) => !blackList.includes(key),
  );

  const filteredValues = filteredKeys.reduce((acc, key) => {
    const changedKey = renamedFields[key];
    if (changedKey) {
      acc[changedKey] = formattedValues[key];
    } else {
      acc[key] = formattedValues[key];
    }
    return acc;
  }, {});

  return filteredValues;
}

export function getDifferenceFromValues(
  previousValues = {},
  currentValues = {},
) {
  const allUniqueKeys = Array.from(
    new Set([...Object.keys(previousValues), ...Object.keys(currentValues)]),
  );

  return allUniqueKeys.reduce((acc, key) => {
    if (currentValues[key] !== previousValues[key]) {
      acc[key] = currentValues[key] ?? previousValues[key];
    }
    return acc;
  }, {});
}

export const getParsedScheduledUpdates = (scheduledUpdates = {}) =>
  Object.keys(scheduledUpdates).reduce((acc, key) => {
    acc[key] = scheduledUpdates[key] === null ? '' : scheduledUpdates[key];
    return acc;
  }, {});

export const getOfficeStreet = (employee = {}) =>
  employee?.location?.street || '-';

 export const getCountry = (employee = {}, countryOptions) => {
  let country = '-'
    if (employee?.location?.countryId) {
        country =  countryOptions.find((e)=> e.value === employee?.location?.countryId ).text
   }
   return country  
 }

export const handlePanelEditModeToggle =
  (props) => (panelName, panelEditModeEnabled) => {
    const { employee } = props;
    const currentDate = moment().format('YYYY-MM-DD');

    if (!panelEditModeEnabled) {
      return props.destroy('staff_profile');
    }
    if (panelName === staffProfileSectionName.organization) {
      props.initialize('staff_profile', {
        costCenter: employee.costCenter,
        businessDivisionId: employee.businessDivisionId,
        isManager: employee.isManager,
        effectiveFromDate: currentDate,
        accountExpiryDate: employee.accountExpiryDate,
        title: employee.title,
        userCategory: employee.userCategory,
        invoiceApprovalRights: employee.invoiceApprovalRights,
        invoiceApprovalLimit: employee.invoiceApprovalLimit,
        companyId: employee.companyId,
        roleRefId: employee.roleRef?.id,
        companyCountry: employee.companyCountry,
        role: employee.roleRef?.name || employee.role,
        category: employee?.roleRef?.category,
        lastName: employee.lastName,
        firstName: employee.firstName,
        onLeave: employee.onLeave,
        preferredLanguage: employee.preferredLanguage,
      });
    }

    return undefined;
  };

export const renderText = (data) => data || '-';
