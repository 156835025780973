import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppInsightsService from '../../../services/logErrorService';

class ErrorBoundary extends Component {
  state = { hasError: false };

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    AppInsightsService.instance.trackException(
      new Error(
        JSON.stringify({
          error: error.message,
          info,
        }),
      ),
    );
  }

  render() {
    const { children } = this.props;
    if (this.state.hasError) {
      return <h1>Something went wrong...</h1>;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ErrorBoundary;
