import { Roles } from '../Access/constants';

const mapClaims = {
  'it-ops': Roles.ItOps,
  'staff-admin': Roles.StaffAdmin,
  'staff-admin-region': Roles.StaffAdminRegion,
  'staff-admin-country': Roles.StaffAdminCountry,
  'app-admin': Roles.AppAdmin,
  'service-desk': Roles.ServiceDesk,
  'staff-organization-manager': Roles.StaffOrganizationManager
};

function mapClaimsToRoles(claims) {
  return Array.isArray(claims) && claims.length > 0
    ? claims.map((claim) => mapClaims[claim])
    : [];
}

export function getUserRoleFromToken(claims) {
  return mapClaimsToRoles(claims);
}
