import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import StaffProfileEmployeeImage from './StaffProfileEmployeeImage';
import {
  getEmployeeImage,
  deleteEmployeeImage,
  createEmployeeImage,
} from './reducer';

import { handleStaffProfile } from '../reducer';

const FORM_NAME = 'media_image';

export default connect(
  (state) => ({
    employeeImageUrl: state.employeeImage.employeeImageUrl,
    employeeImageFilename: state.employeeImage.employeeImageFilename,
    employeeImageUrlIsFetching: state.employeeImage.employeeImageUrlIsFetching,
    employeeImageUrlIsDeleting: state.employeeImage.employeeImageUrlIsDeleting,
    employeeImageUrlIsCreating: state.employeeImage.employeeImageUrlIsCreating,
  }),
  {
    getEmployeeImage,
    deleteEmployeeImage,
    createEmployeeImage,
    handleStaffProfile,
  },
)(
  reduxForm({
    form: FORM_NAME,
    onChange: async (values, _, props) => {
      await props.createEmployeeImage(
        props.employee.email,
        values.employeeImage,
      );
      await props.handleStaffProfile(props.employee.id);
    },
  })(StaffProfileEmployeeImage),
);
