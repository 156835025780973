import React, { Component } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import DepartmentItem from './DepartmentItem';
import {
  handleGetOrgItems,
  handleAddNewItemModalOpen,
  handleGetDepartmentItems
} from '../reducer';
import { showLoading, hideLoading } from '../../../shared/components/Loader';
import { formatOrgItems } from './helpers';

class DepartmentItemContainer extends Component {
  handleItemClick = () => {
    const { item, onClick } = this.props;
    onClick && onClick(item.id);
  };

  render() {
    return (
      <DepartmentItem
        {...this.props}
        onItemClick={this.handleItemClick}
        onEditItemClick={() => this.props.handleEditItemClick(this.props.item)}
        onDeleteItemClick={() => this.props.handleDeleteItemClick(this.props.item)}
      />
    );
  }
}

DepartmentItemContainer.propTypes = {
  item: propTypes.shape({
    id: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    rootId: propTypes.string.isRequired,
  }).isRequired,
  departmentItems: propTypes.array.isRequired,
  onClick: propTypes.func,
  showLoading: propTypes.func,
  hideLoading: propTypes.func,
  handleGetOrgItems: propTypes.func,
  handleGetDepartmentItems: propTypes.func,
  handleAddNewItemModalOpen: propTypes.func,
  handleEditItemClick: propTypes.func.isRequired,
  handleDeleteItemClick: propTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const items = state.department.items || [];

  return {
    departmentItems: formatOrgItems(items),
  };
};

export default connect(mapStateToProps, {
  showLoading,
  hideLoading,
  handleGetOrgItems,
  handleAddNewItemModalOpen,
  handleGetDepartmentItems
})(DepartmentItemContainer);
