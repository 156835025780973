import React from 'react';
import { Field } from 'redux-form';
import propTypes from 'prop-types';
import DatePickerCore from '../DatePickerCore';

export const DatePickerField = ({ name, ...rest }) => (
    <Field
      name={name}
      locale={localStorage?.getItem('i18nextLng')?.split('-')[0] || 'en'}
      component={DatePickerCore}
      {...rest}
    />
  );

DatePickerField.propTypes = {
  name: propTypes.string,
};
