import { Grid } from '@mui/material';
import IconButtonWithToolTip from '../../shared/components/IconButtonWithToolTip';
import { t } from '../../i18n';
import Access, { Permissions } from '../../shared/components/Access';

export const locationTableColumns = () => [
  {
    value: 'region',
    label: 'region',
  }, 
   {
    value: 'country',
    label: 'country',
  },
  {
    value: 'city',
    label: 'city',
  },
  {
    value: 'street',
    label: 'street address',
  },
  {
    value: 'postalCode',
    label: 'zip code',
  },
  {
    value: 'action',
    label: 'action',
  },
];

export function mappedEntities(entities) {
    return entities.map((entity) => (
         {text: entity.name,
          value: entity.id
         }
    ));
}

export function mappedLocations(locationsRows, onEditLocation, onDeleteLocation) {
    return locationsRows.map((row) => ({
      region: {
        value: row.region.name,
        render: row.region.name,
      },
      country: {
        value: row.country.name,
        render: row.country.name,
      },
      city: {
        value: row.city.name,
        render: row.city.name,
      },
      street: {
        value: row.street,
        render: row.street,
      },
      postalCode: {
        value: row.postalCode,
        render: row.postalCode,
      },

      action: {
        value: null,
        render: 
     
        <Grid container spacing={0}>   
            <Access rule={Permissions.UpdateLocation}>
                  {(permitted) => 
                     <IconButtonWithToolTip
                     title='Edit Location'
                     disabled={!permitted}
                     icon="ak-edit"
                     buttonClasses={
                       'organization-item-card__action__btn'
                     }
                     iconClasses={
                       'organization-item-card__action'
                     }
                     onClick={ () => {
                       onEditLocation(row)
                     }}
                   /> 
                  }
            </Access>
             
            <Access rule={Permissions.DeleteLocaton}>
                  {(permitted) => 
                    <IconButtonWithToolTip
                    disabled={!permitted}
                    buttonClasses={ 'organization-item-card__action__btn'
                    }
                    iconClasses={ 'organization-item-card__action'
                    }
                    icon="ak-delete"
                    onClick={ () => {
                      onDeleteLocation(row)
                    }}
                    title='Delete Location'
                  /> 
                  }
            </Access> 
        </Grid> 
      }
    }));
}

export function submitValidation(values) {
  const { cityId,regionId, countryId, street, postalCode} = values;
  const errors = {};

  if (!cityId) {
    errors.cityId = `city is ${t('required')}`;
  }
  if (!countryId) {
    errors.countryId = `country is ${t('required')}`;
  }
  if (!regionId) {
    errors.regionId = `region is ${t('required')}`;
  }
  if (!street) {
    errors.street = `street is ${t('required')}`;
  }
  if (!postalCode) {
    errors.postalCode = `zip code is ${t('required')}`;
  }

  

  return errors;
}
