import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { orderBy } from 'lodash/fp';
import Button from '../../shared/components/Button';
import PageHead from '../../shared/components/PageHead';
import Access, { Permissions } from '../../shared/components/Access';
import OrganizationItem from './OrganizationItem';
import Spinner from '../../shared/components/Spinner';
import { BreadCrumbs } from './BreadCrumbs';
import Icon from '../../shared/components/Icon';
import IconButton from '../../shared/components/IconButton';
import Grid from '../../shared/components/Grid';
import './Organization.scss';
import TeamMemberItem from './TeamMemberItem';

class Organization extends Component {
  render() {
    const {
      t,
      allItems,
      breadCrumbs,
      parentItemId,
      orgItemTypes,
      organizationItems,
      onBackClick,
      onItemClick,
      onAddNewItemModalOpen,
      onHandleInformation,
      teamMembersOfOrganization
    } = this.props;

    const parent = allItems.find((item) => parentItemId === item.id);
    const loading = this.props.itemsAreLoading || this.props.managersLoading;

    return (
      <>
        <PageHead text={t('organizationalStructure')} />
        {breadCrumbs && breadCrumbs.length > 0 && (
          <BreadCrumbs
            items={breadCrumbs}
            onClick={onBackClick}
            rootCrumb="Organization"
          />
        )}
        <div className="organization">
          <Access
            to={{
              organizationId: parent ? parent.id : null,
              organizationTree: allItems,
            }}
            rule={Permissions.CreateOrganizationItem}
          >
            {(granted) => {
              if (!granted || !orgItemTypes.length) {
                return null;
              }

              return (
                <div className="organization__org-actions">
                  <Button
                    onClick={onAddNewItemModalOpen}
                    title={t('newItem')}
                    primary
                    className="organization__add-new-btn"
                  >
                    <Icon icon="ak-plus" color="white" />
                    {t('newItem')}
                  </Button>
                  <IconButton
                    className="organization__info-btn"
                    onClick={onHandleInformation({
                      title: t('newItem'),
                      message: t(`organizationStructureInfo`)
                        .split('\n')
                        .map((line) => <p key={line}>{line}</p>),
                    })}
                  >
                    <Icon icon="ak-help" />
                  </IconButton>
                </div>
              );
            }}
          </Access>
          <Spinner isLoading={loading}>
            <Grid container>
              {orderBy(['name'], ['asc'], organizationItems).map(
                (item, index) => (
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={4}
                    xs={12}
                    key={`${item.id}${index}`}
                  >
                    <OrganizationItem item={item} onClick={onItemClick} />
                  </Grid>
                ),
              )}
            </Grid>
          </Spinner>
        </div>
        <div className="organization-team-member">
        <Spinner isLoading={loading}>
            <Grid container>
              {orderBy(['firstName'], ['asc'], teamMembersOfOrganization).map(
                (item, index) => (
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={3}
                    xs={12}
                    key={`${item.id}${index}`}
                  >
                    <TeamMemberItem manager={item} />
                  </Grid>
                ),
              )}
            </Grid>
          </Spinner>
        </div>
      </>
    );
  }
}

Organization.propTypes = {
  t: PropTypes.func.isRequired,
  allItems: PropTypes.array.isRequired,
  breadCrumbs: PropTypes.arrayOf(PropTypes.object),
  parentItemId: PropTypes.any,
  orgItemTypes: PropTypes.array.isRequired,
  itemsAreLoading: PropTypes.bool,
  managersLoading: PropTypes.bool,
  organizationItems: PropTypes.array.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onAddNewItemModalOpen: PropTypes.func.isRequired,
  onHandleInformation: PropTypes.func.isRequired,
  teamMembersOfOrganization: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default withTranslation()(Organization);
