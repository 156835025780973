function getUpdatedTreeWithOneUpdatedNode(initialTree, nodeId, newNodeData) {
  return initialTree.map((node) => {
    if (node.id === nodeId) {
      return {
        ...node,
        ...newNodeData,
        id: node.id,
      };
    }
    if (node.children.length === 0) {
      return node;
    }
    if (node.children.length > 0) {
      node.children = getUpdatedTreeWithOneUpdatedNode(
        node.children,
        nodeId,
        newNodeData,
      );
      return node;
    }
    return node;
  });
}

export default getUpdatedTreeWithOneUpdatedNode;
