import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Button from '../Button';
import Modal from '../Modal';
import {
  handleInformation as handleInformationReducer,
  initialState,
} from './reducer';
import './InformationModalContainer.scss';

const InformationModalContainer = ({
  title,
  message,
  isOpen,
  handleInformationReducer: handleInformation,
  t,
}) => {
  const onHandleClose = () => handleInformation(initialState);
  return (
    <Modal
      title={title}
      className="information-modal"
      open={isOpen}
      onClose={onHandleClose}
      onSubmit={(e) => e}
      callToActionText={'got it'}
      callToActionCancel={'cancel'}
    >
      {message}
      <div className="information-modal__footer">
        <Button primary onClick={onHandleClose}>
          {t('ok')}
        </Button>
      </div>
    </Modal>
  );
};

InformationModalContainer.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleInformationReducer: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = ({ information }) => ({
    title: information.title,
    message: information.message,
    isOpen: information.isOpen,
  });

const InformationModal = connect(mapStateToProps, { handleInformationReducer })(
  withTranslation()(InformationModalContainer),
);

export { InformationModal };
