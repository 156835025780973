import { sortBy, compose, upperFirst } from 'lodash/fp';

const getCityText = (city) => {
  switch (city) {
    case 'Washington':
      return 'Washington, D.C.';
    default:
      return city;
  }
};

export function getLocationFromJiraOption(location) {
  const parsedLocation = location.value.match(
    /(?<=[A-Z]{2}\s-\s).*?(?=(?:\sOffice|$))/gi,
  );
  let city = parsedLocation ? parsedLocation[0] : null;
  let street = null;
  if (city?.startsWith('Berlin')) {
    [city, street] = city.split(' ');
  }
  return {
    city,
    street,
  };
}

export const getMappedCity = (location) => {
  const { city, street } = getLocationFromJiraOption(location);
  const parsedCity = getCityText(compose(upperFirst)(city));
  const text = street ? `${parsedCity} ${street}` : parsedCity;
  return {
    text,
    value: location.value,
  };
};

export const getOfficeLocationsOptions = (enumerations = []) =>
  sortBy(['text'])(
    enumerations
      .filter((item) => item.fieldName === 'Office location')
      .map(getMappedCity),
  );

export const getApplicationOptions = (enumerations = []) =>
  sortBy(['text'])(
    enumerations
      .filter((item) => item.fieldName === 'Application list')
      .filter((item) => item.value !== 'Other')
      .map((item) => ({ text: item.value, value: item.value })),
  );

export const getDeliveryAddressOptions = (enumerations = []) =>
  sortBy(['text'])(
    enumerations
      .filter((item) => item.fieldName === 'Delivery address')
      .filter((item) => item.value !== 'Other')
      .map((item) => ({ text: item.value, value: item.value })),
  );

export const getOptions = (enumerations = [], key, sort = true) => {
  const options = enumerations
    .filter((item) => item.fieldName === key)
    .map((item) => ({ text: item.value, value: item.value }));

  if (sort) {
    return sortBy(['text'])(options);
  }
  return options;
};
