import React from 'react';
import PropTypes from 'prop-types';
import Tree from '../Tree';
import { useSelectTreeHook } from '../useSelectTreeHook';
import { akeliusBusinessDivisionContext } from '../context';
import TreeToggleButtons from '../TreeToggleButtons';

const AkeliusBusinessDivisionTree = ({
  organizationItems,
  businessDivisionId,
  input,
}) => {
  const {
    treeData,
    handleToggle,
    handleSelect,
    updateAllNodes,
  } = useSelectTreeHook({
    organizationItems,
    businessDivisionId,
  });

  const context = React.useContext(akeliusBusinessDivisionContext);

  React.useEffect(() => {
    context.setSubmitHandler(input.onChange);
  }, [context, input.onChange]);
  return (
    <>
      <TreeToggleButtons updateAllNodes={updateAllNodes} />
      <Tree
        data={treeData}
        handleToggle={handleToggle}
        handleSelect={(_, id) => {
          handleSelect(_, id);
          context.setSelectedId(id);
        }}
      />
    </>
  );
};

AkeliusBusinessDivisionTree.propTypes = {
  organizationItems: PropTypes.array,
  businessDivisionId: PropTypes.string,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }),
};

export default AkeliusBusinessDivisionTree;
