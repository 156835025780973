import React from 'react';
import propTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import {
  handleMergeItemModalClose,
  handleMergeOrganizationItem,
  handleGetOrgItemsBelonging,
} from '../reducer';
import MergeItemModal from './reduxForm';
import { formatOrgItems } from '../OrganizationItem/helpers';
import { akeliusBusinessDivisionContext } from '../../../shared/components/AkeliusBusinessDivision/context';
import { handleNotification } from '../../../shared/components/Notification/reducer';
import StaffAppLink from '../../../shared/components/StaffAppLink';

export const MergeItemModalContainer = ({
  isModalOpen,
  isModalLoading,
  organizationItems,
  // eslint-disable-next-line no-shadow
  handleMergeItemModalClose,
  // eslint-disable-next-line no-shadow
  handleMergeOrganizationItem,
  // eslint-disable-next-line no-shadow
  handleGetOrgItemsBelonging,
  // eslint-disable-next-line no-shadow
  handleNotification,
  targetMergeItem,
  parentItemId,
  t,
}) => {
  const context = React.useContext(akeliusBusinessDivisionContext);

  const handleModalSubmit = async () => {
    if (context.submitHandler) {
      const parentId = context.selectedId;
      context.submitHandler(context.selectedId);
      context.setSelectedId(null);
      context.setSubmitHandler(null);

      const previousParentId = organizationItems.find(
        (item) => item.id === parentItemId,
      );

      const nextParent = organizationItems.find((item) => item.id === parentId);

      try {
        await handleMergeOrganizationItem(targetMergeItem.id, parentId);
        await handleGetOrgItemsBelonging();

        await handleNotification({
          message: 'organizationItemMergeSuccessMessage',
          interpolate: {
            name: targetMergeItem.name,
            root: previousParentId?.name || 'Root',
            destination: nextParent?.name || 'Root',
          },
          type: 'success',
          isActive: true,
        });
      } catch (error) {
        await handleNotification({
          message: 'organizationErrorMessage',
          addon: <StaffAppLink />,
          type: 'error',
          isActive: true,
        });
      }
    }
  };
  const handleModalClose = () => handleMergeItemModalClose();
  return (
    <MergeItemModal
      modalTitle={'Select to which business division you would like to merge this one with.'}
      callToActionText={t('merge')}
      isOpen={isModalOpen}
      isModalLoading={isModalLoading}
      onClose={handleModalClose}
      handleSubmit={handleModalSubmit}
      organizationItems={organizationItems}
    />
  );
};

const mapStateToProps = (state) => ({
  organizationItems: formatOrgItems(state.organization.items),
  isModalLoading: state.organization.isMergeModalFetching,
  targetMergeItem: state.organization.targetMergeItem,
  parentItemId: state.organization.parentItemId,
});

export default connect(mapStateToProps, {
  handleMergeItemModalClose,
  handleMergeOrganizationItem,
  handleGetOrgItemsBelonging,
  handleNotification,
})(withTranslation()(MergeItemModalContainer));

MergeItemModalContainer.propTypes = {
  isModalOpen: propTypes.bool.isRequired,
  isModalLoading: propTypes.bool.isRequired,
  handleMergeItemModalClose: propTypes.func.isRequired,
  handleMergeOrganizationItem: propTypes.func.isRequired,
  handleGetOrgItemsBelonging: propTypes.func.isRequired,
  handleNotification: propTypes.func.isRequired,
  organizationItems: propTypes.array,
  t: propTypes.func.isRequired,
  targetMergeItem: propTypes.object,
  parentItemId: propTypes.string,
};
