import PropTypes from 'prop-types';

export const employeePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  businessDivision: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  companyStreet: PropTypes.string,
  companyCity: PropTypes.string,
  email: PropTypes.string,
 });
