import { reduxForm, SubmissionError } from 'redux-form';
import { Company } from './Company';
import {submitValidation} from './helper';

export const COMPANY_FORM = 'company_form';

const submit = (values, _, { onFormSubmit }) => {
  const {
    id,
    name,
  } = values;

  const errors = submitValidation(values);

  if (Object.keys(errors).length) {
    throw new SubmissionError(errors);
  }

  onFormSubmit({
    id,
     name
  });
};

export default reduxForm({
  form: COMPANY_FORM,
  onSubmit: submit,
})(Company);