import { Grid } from '@mui/material';
import IconButtonWithToolTip from '../../shared/components/IconButtonWithToolTip';
import { t } from '../../i18n';
import Access, { Permissions } from '../../shared/components/Access';

export const roleTableColumns = () => [
  {
    value: 'name',
    label: 'name',
  },
  {
    value: 'category',
    label: 'category',
  },
  {
    value: 'action',
    label: 'action',
  },
];

export function mappedCategories() {
  const RoleCategory = {
    Business: 'Business',
    Construction: 'Construction',
    Heimstaden: 'Heimstaden',
    Technology: 'Technology',
    Finance: 'Finance',
    Operations: 'Operations',
    Special: 'Special',
    Forest: 'Forest',
    'Language Course': 'Language Course',
    'Energy and Sustainability': 'Energy and Sustainability',
    Math: 'Math',
  };

  return Object.values(RoleCategory).map((e) => ({ text: e, value: e }));
}

export function mappedRoles(rolesRows, onEditRole, onDeleteRole) {
  return rolesRows.map((row) => ({
    name: {
      value: row.name,
      render: row.name,
    },
    category: {
      value: row.category,
      render: row.category,
    },

    action: {
      value: null,
      render: (
        <Grid container spacing={0}>
          <Access rule={Permissions.UpdateRole}>
            {(permitted) => (
              <IconButtonWithToolTip
                title="Edit Role"
                disabled={!permitted}
                icon="ak-edit"
                buttonClasses={'organization-item-card__action__btn'}
                iconClasses={'organization-item-card__action'}
                onClick={() => {
                  onEditRole(row);
                }}
              />
            )}
          </Access>

          <Access rule={Permissions.DeleteRole}>
            {(permitted) => (
              <IconButtonWithToolTip
                disabled={!permitted}
                buttonClasses={'organization-item-card__action__btn'}
                iconClasses={'organization-item-card__action'}
                icon="ak-delete"
                onClick={() => {
                  onDeleteRole(row);
                }}
                title="Delete Role"
              />
            )}
          </Access>
        </Grid>
      ),
    },
  }));
}

export function submitValidation(values) {
  const { name, category } = values;
  const errors = {};

  if (!name) {
    errors.name = `role name is ${t('required')}`;
  }
  if (!category) {
    errors.category = `role category is ${t('required')}`;
  }
  return errors;
}
