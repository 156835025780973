import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Card.scss';

React.forwardRef((props, ref) => (
  <input ref={ref} {...props} type="email" className="AppEmailInput" />
));

export const Card = React.forwardRef(({ className, children }, ref) => (
    <div className={classnames(`ak-card ${className}`)} ref={ref}>
      {children}
    </div>
  ));

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
Card.defaultProps = {
  className: '',
};

export default Card;
