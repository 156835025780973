import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import moment from 'moment';
import {
  MODES,
  TODAY,
  DIRECTIONS,
  DEFAULT_YEARS_RANGE,
  DATE_FORMAT,
  YEARS_GRID_SIZE,
} from '../constants';
import {
  getCalendar,
  getYearsRange,
  getPreviousRange,
  getNextRange,
} from '../helpers';

import DatePickerBase from '../DatePickerBase';
import DatePickerInput from '../DatePickerInput';
import DatePickerPanel from '../DatePickerPanel';
import DatePickerControls from '../DatePickerControls';
import DatePickerDates from '../DatePickerDates';
import DatePickerMonths from '../DatePickerMonths';
import DatePickerYears from '../DatePickerYears';

export const DatePickerCore = withTranslation()(
  ({
    label,
    locale,
    yearsRange = DEFAULT_YEARS_RANGE,
    disabled,
    input,
    meta,
    t,
  }) => {
    const {
      name: reduxFormFieldName,
      onFocus: onReduxFormFieldFocus,
      onBlur: onReduxFormFieldBlur,
      onChange: onReduxFormFieldChange,
      value: reduxFormFieldValue,
    } = input;

    const { error: ReduxFormFieldError, invalid: ReduxFormFieldInvalid } = meta;

    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [mode, setMode] = useState(MODES.DATES_MODE);
    const [calendarInputValue, setCalendarInputValue] = useState('');

    const [calendarData, setCalendarData] = useState(null);
    const [yearsRangeValues, setYearsRangeValues] = useState([]);

    const handleCalendarInputButtonClick = () => {
      if (isCalendarOpen) {
        setIsCalendarOpen(false);
        setMode(MODES.DATES_MODE);
      } else {
        setIsCalendarOpen(true);
      }
    };

    const handleDateDisplayButtonClick = () => {
      if (mode === MODES.DATES_MODE) {
        setMode(MODES.YEARS_MODE);
        if (calendarInputValue) {
          setYearsRangeValues(
            getYearsRange(
              yearsRange,
              YEARS_GRID_SIZE,
              parseInt(moment(calendarInputValue).format('YYYY'), 10),
            ),
          );
        } else {
          setYearsRangeValues(
            getYearsRange(
              yearsRange,
              YEARS_GRID_SIZE,
              parseInt(moment(TODAY).format('YYYY'), 10),
            ),
          );
        }
      }
      if (mode === MODES.YEARS_MODE) {
        setMode(MODES.DATES_MODE);
      }
      if (mode === MODES.MONTHS_MODE) {
        setMode(MODES.DATES_MODE);
      }
    };

    const handleCalendarMonthsButtonClick = (event) => {
      setMode(MODES.DATES_MODE);
      const selectedMonth = event.currentTarget.getAttribute('month');
      const currentDate = calendarData.date;
      const updatedDate = moment(currentDate)
        .month(selectedMonth)
        .format(DATE_FORMAT);
      setCalendarData(getCalendar(updatedDate, locale));
    };

    const handleCalendarYearsButtonClick = (event) => {
      setMode(MODES.MONTHS_MODE);
      const selectedYear = event.currentTarget.getAttribute('year');
      const currentDate = calendarData.date;
      const updatedDate = moment(currentDate)
        .year(selectedYear)
        .format(DATE_FORMAT);

      setCalendarData(getCalendar(updatedDate, locale));
      setCalendarData(getCalendar(updatedDate, locale));
    };

    const handleOutsideCalendarClick = () => {
      if (isCalendarOpen) {
        setIsCalendarOpen(false);
        setMode(MODES.DATES_MODE);
      }
    };

    const handleCalendarFocus = () => {
      setIsCalendarOpen(false);
      onReduxFormFieldFocus();
    };

    const handleCalendarBlur = () => {
      const normalizedData = moment(calendarInputValue).format(DATE_FORMAT);

      if (moment(normalizedData, DATE_FORMAT, true).isValid()) {
        setCalendarData(getCalendar(normalizedData, locale));
        setCalendarInputValue(normalizedData);
        onReduxFormFieldBlur(normalizedData);
      }
    };

    const handleCalendarInputChange = (event) => {
      const { value } = event.target;

      const caret = event.target.selectionStart;
      const element = event.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = caret;
        element.selectionEnd = caret;
      });

      onReduxFormFieldChange(value);
    };

    const handleCalendarDatesButtonClick = (fullDate) => () => {
      setIsCalendarOpen(false);
      onReduxFormFieldChange(fullDate);
    };

    const handleCalendarControlsButtonClick = (event) => {
      const direction = event.currentTarget.getAttribute('direction');
      if (direction === DIRECTIONS.LEFT) {
        if (mode === MODES.DATES_MODE) {
          setCalendarData(
            getCalendar(
              moment(calendarData.date)
                .subtract(1, 'month')
                .format(DATE_FORMAT),
              locale,
            ),
          );
        }
        if (mode === MODES.YEARS_MODE) {
          setYearsRangeValues(
            getPreviousRange(yearsRangeValues, yearsRange.START_YEAR),
          );
        }
        if (mode === MODES.MONTHS_MODE) {
          const currentDate = calendarData.date;

          setCalendarData(
            getCalendar(
              moment(currentDate).subtract(1, 'year').format(DATE_FORMAT),
              locale,
            ),
          );
        }
      }
      if (direction === DIRECTIONS.RIGHT) {
        if (mode === MODES.DATES_MODE) {
          setCalendarData(
            getCalendar(
              moment(calendarData.date).add(1, 'month').format(DATE_FORMAT),
              locale,
            ),
          );
        }
        if (mode === MODES.YEARS_MODE) {
          setYearsRangeValues(
            getNextRange(yearsRangeValues, yearsRange.END_YEAR),
          );
        }
        if (mode === MODES.MONTHS_MODE) {
          const currentDate = calendarData.date;
          setCalendarData(
            getCalendar(
              moment(currentDate).add(1, 'year').format(DATE_FORMAT),
              locale,
            ),
          );
        }
      }
    };

    useEffect(() => {
      const handleInitialCalendarValue = (initialData = '') => {
        setCalendarInputValue(initialData);
        if (moment(initialData, DATE_FORMAT, true).isValid()) {
          setCalendarData(getCalendar(initialData, locale));
        } else {
          setCalendarData(getCalendar(TODAY, locale));
        }
      };
      handleInitialCalendarValue(reduxFormFieldValue);
    }, [reduxFormFieldValue, locale]);

    return (
      <DatePickerBase onClickOutside={handleOutsideCalendarClick}>
        <DatePickerInput
          onCalendarInputButtonClick={handleCalendarInputButtonClick}
          isCalendarOpen={isCalendarOpen}
          onCalendarFocus={handleCalendarFocus}
          onCalendarBlur={handleCalendarBlur}
          onCalendarInputChange={handleCalendarInputChange}
          calendarInputValue={calendarInputValue}
          label={label}
          hasError={!!ReduxFormFieldError || ReduxFormFieldInvalid}
          message={t(ReduxFormFieldError)}
          disabled={disabled}
          name={reduxFormFieldName}
        />
        <DatePickerPanel isOpen={isCalendarOpen}>
          <DatePickerControls
            onDateDisplayButtonClick={handleDateDisplayButtonClick}
            mode={mode}
            calendarData={calendarData}
            onCalendarControlsButtonClick={handleCalendarControlsButtonClick}
            yearsRangeValues={yearsRangeValues}
          />
          <DatePickerDates
            isActive={mode === MODES.DATES_MODE}
            calendarData={calendarData}
            onCalendarDatesButtonClick={handleCalendarDatesButtonClick}
          />
          <DatePickerMonths
            isActive={mode === MODES.MONTHS_MODE}
            calendarData={calendarData}
            onCalendarMonthsButtonClick={handleCalendarMonthsButtonClick}
          />
          <DatePickerYears
            isActive={mode === MODES.YEARS_MODE}
            onCalendarYearsButtonClick={handleCalendarYearsButtonClick}
            yearsRangeValues={yearsRangeValues}
            calendarData={calendarData}
          />
        </DatePickerPanel>
      </DatePickerBase>
    );
  },
);

DatePickerCore.propTypes = {
  date: propTypes.string,
  label: propTypes.string,
  locale: propTypes.oneOf(['en', 'de', 'fr', 'sv', 'da']),
  yearsRange: propTypes.shape({
    START_YEAR: propTypes.number,
    END_YEAR: propTypes.number,
  }),
  error: propTypes.string,
  disabled: propTypes.bool,
  input: propTypes.shape({
    name: propTypes.string,
    onChange: propTypes.func,
    value: propTypes.string,
    onFocus: propTypes.func,
    onBlur: propTypes.func,
  }),
  meta: propTypes.shape({
    error: propTypes.string,
    invalid: propTypes.bool,
  }),
};
DatePickerCore.defaultProps = {
  locale: 'en',
  yearsRange: DEFAULT_YEARS_RANGE,
  label: 'select date',
  disabled: false,
};
