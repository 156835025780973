function getUpdatedTreeWithAllUpdatedNodes(initialTree, newNodeData) {
  return initialTree.map((node) => ({
    ...node,
    ...newNodeData,
    id: node.id,
    children: getUpdatedTreeWithAllUpdatedNodes(node.children, newNodeData),
  }));
}

export default getUpdatedTreeWithAllUpdatedNodes;
