import { createAction, handleActions } from 'redux-actions';

const AUTH_HANDLER = 'AUTH_HANDLER';
const authHandler = createAction(AUTH_HANDLER);

export function handleAuthentication(props) {
  return (dispatch) => {
    dispatch(authHandler(props));
  };
}

const initialState = {
  id: '',
  role: '',
  adId: '',
  email: '',
  lastName: '',
  firstName: '',
  isManager: false,
  isAuthenticated: false,
  country: '',
};

export default handleActions(
  {
    [AUTH_HANDLER]: (state, action) => ({
      ...state,
      id: action.payload.id,
      role: action.payload.role,
      adId: action.payload.adId,
      email: action.payload.email,
      country: action.payload.country,
      lastName: action.payload.lastName,
      firstName: action.payload.firstName,
      isManager: action.payload.isManager,
      isAuthenticated: action.payload.isAuthenticated,
    }),
  },
  { ...initialState },
);
