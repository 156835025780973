export const getTextWidth = (text, fontProp) => {
  const tag = document.createElement('div');
  tag.style.position = 'absolute';
  tag.style.left = '-999em';
  tag.style.whiteSpace = 'nowrap';
  tag.style.font = fontProp;
  tag.innerHTML = text;

  document.body.appendChild(tag);

  const result = tag.clientWidth;

  document.body.removeChild(tag);

  return result;
};
