import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import auth from '../components/AuthProvider/reducer';
import loading from '../components/Loader/reducer';
import managers from './managers';
import organization from '../../App/Organization/reducer';
import department from '../../App/Department/reducer';
import notification from '../components/Notification/reducer';
import staffProfile from '../../App/Staff/StaffProfile/reducer';
import currentLanguage from '../components/Header/reducer';
import staffCatalog from '../../App/Staff/StaffCatalogPanel/reducer';
import team from '../../App/Team/reducer';
import sidebar from '../components/Sidebar/reducer';
import applicationConfig from '../components/AppConfigProvider/reducer';
import information from '../components/InformationModal/reducer';
import employeeImage from '../../App/Staff/StaffProfile/StaffProfileHeader/reducer';
import jiraEnumerations from '../components/JiraDataProvider/reducer';
import backEndData from '../components/BackEndDataProvider/reducer';
import location from '../../App/Location/reducer';
import company from '../../App/Company/reducer';
import role from '../../App/Role/reducer';

const reducer = combineReducers({
  auth,
  loading,
  form: formReducer,
  managers,
  notification,
  organization,
  department,
  staffProfile,
  currentLanguage,
  staffCatalog,
  team,
  sidebar,
  applicationConfig,
  information,
  employeeImage,
  jiraEnumerations,
  backEndData,
  location,
  company,
  role,
});

export default reducer;
