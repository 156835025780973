import React, { Component } from 'react';
import Drawer from '@mui/material/Drawer';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import IconButton from '../IconButton';
import Icon from '../Icon';
import links from './links';
import Access from '../Access';
import Dropdown from './SidebarDropdown';
import { toggleSidebar } from './reducer';
import './Sidebar.scss';

class Sidebar extends Component {
  handleToggleSidebar = () => {
    this.props.toggleSidebar(this.props.isSidebarOpen);
  };

  onNavLinkClick = () => {
    this.props.toggleSidebar(this.props.isSidebarOpen);
  };

  isDropdownActive = (link) => {
    const activeChild = link.children.find(
      (childLink) => childLink.url === window.location.pathname,
    );
    return !!activeChild;
  };

  getLink = (link, hasChildren) => {
    if (hasChildren) {
      return (
        <Dropdown
          key={link.text}
          title={this.props.t(link.text)}
          isActive={this.isDropdownActive(link)}
          links={links}
        >
          {this.renderLinks(link.children)}
        </Dropdown>
      );
    }
    return (
      <li key={link.text}>
        <NavLink
          to={`${link.url}`}
          className="sidebar__link"
          onClick={this.onNavLinkClick}
          // className={(navData) => (navData.isActive ? "sidebar__link--activ" : 'none')}
          // activeClassName="sidebar__link--active"
        >
          {this.props.t(link.text)}
        </NavLink>
      </li>
    );
  };

  protectLink = (link) => (
    <Access key={link.text} rule={link.permission}>
      {(permitted) => (permitted ? this.getLink(link) : null)}
    </Access>
  );

  protectOrGet = (link, hasChildren) => {
    if (link.permission) {
      return this.protectLink(link);
    }
    return this.getLink(link, hasChildren);
  };

  renderLinks = (linksData) =>
    linksData.map((link) => {
      if (!link.children) {
        return this.protectOrGet(link, false);
      }
      return this.protectOrGet(link, true);
    });

  render() {
    const { isSidebarOpen } = this.props;

    return (
      <Drawer
        className="sidebar"
        open={isSidebarOpen}
        onClose={this.handleToggleSidebar}
        PaperProps={{
          classes: {
            root: 'sidebar__paper',
          },
        }}
      >
        <div tabIndex={0} role="button">
          <div className="sidebar__close">
            <NavLink
              to={`/`}
              onClick={this.handleToggleSidebar}
              className="sidebar__app-name"
            >
              Staff
            </NavLink>
            <IconButton
              aria-label="Close"
              className="sidebar__close-icon"
              onClick={this.handleToggleSidebar}
            >
              <Icon icon="ak-close" />
            </IconButton>
          </div>
          <ul className="sidebar__list">{this.renderLinks(links)}</ul>
        </div>
      </Drawer>
    );
  }
}

Sidebar.propTypes = {
  userId: PropTypes.string.isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default connect(
  ({ auth, sidebar }) => ({
    userId: auth.id,
    isSidebarOpen: sidebar.isSidebarOpen,
  }),
  { toggleSidebar },
)(withTranslation()(Sidebar));
