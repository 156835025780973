import React from 'react';
import PropTypes from 'prop-types';
import AkeliusToolTip from '../AkeliusToolTip';
import IconButton from '../IconButton';
import Icon from '../Icon';

const IconButtonWithToolTip = ({
  title,
  placement,
  disabled,
  icon,
  isActive,
  onClick,
  buttonClasses,
  iconClasses,
  id,
  value,
}) => disabled ? (
    <IconButton
      disabled={disabled}
      className={buttonClasses}
      onClick={onClick}
      isActive={isActive}
      title={title}
      id={id}
      value={value}
    >
      <Icon icon={icon} className={iconClasses} />
    </IconButton>
  ) : (
    <AkeliusToolTip title={title} placement={placement}>
      <IconButton
        disabled={disabled}
        className={buttonClasses}
        onClick={onClick}
        isActive={isActive}
        title={title}
        id={id}
        value={value}
      >
        <Icon icon={icon} className={iconClasses} />
      </IconButton>
    </AkeliusToolTip>
  );

IconButtonWithToolTip.propTypes = {
  title: PropTypes.string.isRequired,
  placement: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  buttonClasses: PropTypes.string,
  iconClasses: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
};

export default IconButtonWithToolTip;
