import React, { PureComponent } from 'react';
import { Field, fieldPropTypes } from 'redux-form';

import Autocomplete from './Autocomplete';

class WrappedAutocomplete extends PureComponent {
  static propTypes = {
    ...fieldPropTypes,
  };

  handleChange = (value) => {
    const { input, onChange } = this.props;
    const onChangeHandler = input.onChange || onChange;
    if (onChangeHandler) {
      onChangeHandler(value);
    }
  };

  render() {
    const { label, meta, input, classNames, isDisabled } = this.props;
    const error = meta.invalid && meta.error ? meta.error : '';

    return (
      <Autocomplete
        {...this.props}
        {...input}
        label={label}
        error={error}
        onChange={this.handleChange}
        classNames={classNames}
        containerClassName="autocomplete-field"
        isDisabled={isDisabled}
      />
    );
  }
}

const AutocompleteField = (props) => (
  <Field {...props} props={props} component={WrappedAutocomplete} />
);

export default AutocompleteField;
