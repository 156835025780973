import { omit } from 'lodash';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { diffFromPlainObjects } from '../../../shared/helpers/diffFromPlainObjects';
import StaffProfile from './StaffProfile/StaffProfile';
import submitValidation from './submitValidation';

export const FORM_NAME = 'staff_profile';

const submit = (
  values,
  _,
  { onFormSubmit, registeredFields, initialFormValues },
) => {
  const { panelName } = values;

  let errors = {};

  if (typeof submitValidation[panelName] === 'function') {
    errors = submitValidation[panelName](values, registeredFields);
  }

  if (Object.keys(errors).length > 0) {
    throw new SubmissionError(errors);
  }

  const changedDataInForm = diffFromPlainObjects(initialFormValues, values);
  const fieldsToSend = omit(changedDataInForm, ['panelName']);

  return onFormSubmit(fieldsToSend);
};

export default reduxForm({
  form: FORM_NAME,
  onSubmit: submit,
})(
  connect(({ form }) => ({
    registeredFields: form[FORM_NAME]?.registeredFields || {},
  }))(StaffProfile),
);
