import { createAction, handleActions } from 'redux-actions';

const NOTIFICATION = 'NOTIFICATION';
const notifyAction = createAction(NOTIFICATION);

export const handleNotification = (message, type, isActive) => (dispatch) => dispatch(notifyAction(message, type, isActive));

const initialState = {
  message: '',
  type: '',
  isActive: false,
  interpolate: {},
  addon: null,
};

export default handleActions(
  {
    [NOTIFICATION]: (state, action) => ({
      ...state,
      type: action.payload.type,
      message: action.payload.message,
      isActive: action.payload.isActive,
      interpolate: action.payload.interpolate || {},
      addon: action.payload.addon || null,
    }),
  },
  {
    ...initialState,
  },
);
