import { reduxForm } from 'redux-form';
import { debounce } from 'lodash/fp';
import StaffCatalogSearchFilters from './StaffCatalogSearchFilters';
import {
  getSearchQuery,
  DEFAULT_DELAY,
  DEFAULT_OFFSET,
  STAFF_CATALOG_FILTERS_FORM_NAME,
  isSearchModeEnabled,
} from './helpers';

const onChange = debounce(DEFAULT_DELAY, async (values, dispatch, props) => {
  const searchBody = getSearchQuery(values);

  if (isSearchModeEnabled(searchBody)) {
    props.setOffset(
      DEFAULT_OFFSET,
      await props.getSearchedEmployees(searchBody),
    );
  } else {
    props.setOffset(DEFAULT_OFFSET, await props.getCatalogEmployees());
  }
});

export default reduxForm({
  form: STAFF_CATALOG_FILTERS_FORM_NAME,
  onChange,
})(StaffCatalogSearchFilters);
