import { reduxForm, SubmissionError } from 'redux-form';
import { Location } from './Location';
import {submitValidation} from './helper';

export const LOCATION_FORM = 'location_form';

const submit = (values, _, { onFormSubmit }) => {
  const {
    id,
    regionId,
    countryId,
    cityId,
    street,
    postalCode
  } = values;

  const errors = submitValidation(values);

  if (Object.keys(errors).length) {
    throw new SubmissionError(errors);
  }

  onFormSubmit({
    id,
    regionId,
    countryId,
    cityId,
    street,
    postalCode
  });
};

export default reduxForm({
  form: LOCATION_FORM,
  onSubmit: submit,
})(Location);
