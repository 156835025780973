import { useState, useEffect } from 'react';
import ApiError from '../../../services/apiError';
import ClientService from '../../../services/client';
import AppInsightsService from '../../../services/logErrorService';
import { allowedHeaders } from './constants';

import config from '../../config';

const {
  REACT_APP_STAFF_APP_AUTH_AUDIENCE,
  REACT_APP_STAFF_APP_AUTH_CLIENT_ID,
  REACT_APP_STAFF_APP_AUTH_CONNECTION,
  REACT_APP_STAFF_APP_AUTH_DOMAIN,
  REACT_APP_STAFF_APP_AUTH_REDIRECT_URI,
  REACT_APP_STAFF_APP_BACKEND,
  REACT_APP_STAFF_APP_INSTRUMENTATION_KEY,
  NODE_ENV,
  REACT_APP_NODE_CONFIG,
} = config;

export const useApplicationHeaders = (allowedHeadersList) => {
  const [data, setHeaders] = useState(null);
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    if (NODE_ENV === 'development' && REACT_APP_NODE_CONFIG === 'local') {
      const envVars = {
        'x-staff-app-auth-audience': REACT_APP_STAFF_APP_AUTH_AUDIENCE,
        'x-staff-app-auth-client-id': REACT_APP_STAFF_APP_AUTH_CLIENT_ID,
        'x-staff-app-auth-connection': REACT_APP_STAFF_APP_AUTH_CONNECTION,
        'x-staff-app-auth-domain': REACT_APP_STAFF_APP_AUTH_DOMAIN,
        'x-staff-app-auth-redirect-uri': REACT_APP_STAFF_APP_AUTH_REDIRECT_URI,
        'x-staff-app-backend': REACT_APP_STAFF_APP_BACKEND,
        'x-staff-app-instrumentation-key': REACT_APP_STAFF_APP_INSTRUMENTATION_KEY,
      };
      setHeaders(envVars);
      ClientService.init({
        headersConfig: envVars,
        backendUrl: envVars[allowedHeaders.STAFF_APP_BACKEND],
        auth0ClientConfig: {
          domain: envVars[allowedHeaders.STAFF_APP_AUTH_DOMAIN],
          audience: envVars[allowedHeaders.STAFF_APP_AUTH_AUDIENCE],
          client_id: envVars[allowedHeaders.STAFF_APP_AUTH_CLIENT_ID],
          connection: envVars[allowedHeaders.STAFF_APP_AUTH_CONNECTION],
          redirect_uri: envVars[allowedHeaders.STAFF_APP_AUTH_REDIRECT_URI],
        },
      });
      AppInsightsService.init(
        envVars[allowedHeaders.STAFF_APP_INSTRUMENTATION_KEY],
      );
      setIsFetching(false);
    } else {
      fetch(window.document.location)
        .then((res) => {
          const filteredHeaders = [...res.headers.entries()].reduce(
            (acc, [headerKey, headerValue]) => {
              const allowedHeadersListKeys = Object.keys(allowedHeadersList);
              if (
                allowedHeadersListKeys.find(
                  (key) => allowedHeadersList[key] === headerKey,
                )
              ) {
                acc[headerKey] = headerValue;
              }
              return acc;
            },
            {},
          );
          if (Object.keys(filteredHeaders).length) {
            setHeaders(filteredHeaders);
            ClientService.init({
              headersConfig: filteredHeaders,
              backendUrl: filteredHeaders[allowedHeaders.STAFF_APP_BACKEND],
              auth0ClientConfig: {
                domain: filteredHeaders[allowedHeaders.STAFF_APP_AUTH_DOMAIN],
                audience:
                  filteredHeaders[allowedHeaders.STAFF_APP_AUTH_AUDIENCE],
                client_id:
                  filteredHeaders[allowedHeaders.STAFF_APP_AUTH_CLIENT_ID],
                connection:
                  filteredHeaders[allowedHeaders.STAFF_APP_AUTH_CONNECTION],
                redirect_uri:
                  filteredHeaders[allowedHeaders.STAFF_APP_AUTH_REDIRECT_URI],
              },
            });
            AppInsightsService.init(
              filteredHeaders[allowedHeaders.STAFF_APP_INSTRUMENTATION_KEY],
            );
          }
        })
        .catch((err) => {
          setError(err);
          throw new ApiError({
            message: 'Authentication failed',
          });
        })
        .finally(() => setIsFetching(false));
    }
  }, [allowedHeadersList]);

  return {
    isFetching,
    error,
    data,
  };
};
